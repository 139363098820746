import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

export function useGetTeacher(userId) {
    const [queryGetTeacher, { data, loading, error }] = useLazyQuery(GET_TEACHER, {
        fetchPolicy: 'network-only'
    });

    const getTeacher = async  (userId) => {
        const result = await queryGetTeacher({
            variables: {
                userId
            }
        });
        return result.data?.teacher;
    }

    useEffect(() => {
        if (userId) {
            (async () => await getTeacher(userId))();
        }
    }, [userId]);

    return {
        loading,
        error,
        teacher: data?.teacher,
        getTeacher
    }
}

const GET_TEACHER = gql`
    query GetTeacher($userId: Int) {
        teacher(userId: $userId) {
            userId
            cost
            description
            lessonType
            picture
            assistantId
            name
        }
    }
`;