import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { safelyParseJSON } from '../../common/utils/';


export const useGetQuiz = () => {
    const { getChatResponse, error } = useAiChatResponse();

    const getQuizAi = useCallback(
        async (header, domain, level) => {
            const settings = {
                args: {
                    concept: header,
                    domain: domain,
                    level: level
                },
                type: 'quiz',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = safelyParseJSON(response?.data?.requestChatApi);
            if (responseJSON?.choices[0]) {
                const quizObject = safelyParseJSON(responseJSON?.choices[0]?.message.content);
                const options = quizObject?.options?.map((option, i) => ({
                    order: i,
                    text: option.text,
                    isCorrect: JSON.parse(option.isCorrect) || true,
                }))

                let newQuestion = {
                    type: 'SINGLE_SELECT',
                    question: `## ${quizObject.question}`,
                    options: options,
                    explanation:  quizObject.explanation,
                    title: header,
                };
                return newQuestion;
            }
        },
        [getChatResponse]
    );

    return { getQuizAi };
};
