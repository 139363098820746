import './scss/index.scss';

import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';
import classnames from 'classnames';
import { Button, Spin } from 'antd';
import { EventCardContainer } from '../../event/EventCard.container';
import { EventsTableComponent } from '../EventsTable.component';
import { SearchContainer } from '../../search';
import { Loader } from '../../Loader';

export const EventsListComponent = (props) => {
    const {
        isLoading,
        events,
        // viewer,
        user,
        onClick = noop,
        handleParticipate,
        title,
        description,
        currencyStorageCode,
        className = '',
        size,
        onEventsListRendered = noop,
        type = 'card',
        isShowMore,
        onShowMore = noop,
        createCourses,
        asActions: ActionsContainer = null,
        filterData,
        handleEvents,
        ...other
    } = props;

    const { formatMessage } = useIntl();
    const [isSearch, setIsSearch] = useState(false);
    const eventsListClassName = classnames('', {
        ' events-list__cards': type === 'card',
        ' events-list_list': type === 'list',
        'events-list__cards_50': size === 50,
        'events-list__cards_100': size === 100,
    });

    const listId = useMemo(() => {
        const randomNum = Math.round(Math.random() * 1000);
        return !!title ? `${title.split(' ').join('_').toLowerCase()}_${randomNum}_` : `events_list_${randomNum}_`;
    }, [title]);

    useEffect(onEventsListRendered, []);

    if (isLoading && !events?.length) {
        return <Loader />;
    }

    const blockClassNames = classnames('events-list', className, {
        'events-list_column': isSearch,
        'events-list_with-search': createCourses,
    });
    return !!events?.length && (
        <Spin spinning={isLoading}>
            <div className={blockClassNames}>
                {title && <div className="events-list__component__title">
                    <h2 className='event_list__subhead'>{title}</h2>
                    {ActionsContainer && <ActionsContainer />}
                </div>}
                {createCourses && <SearchContainer filter={filterData} onSearch={setIsSearch} />}
                {description && (<p className='wrapper_inner-section'>{description}</p>)}

                {type === 'card' && (
                    <div className={eventsListClassName}>
                        {events &&
                            events.map((event, i) => (
                                !!event ? (
                                    <EventCardContainer
                                        {...other}
                                        key={listId + event?.id + i}
                                        event={event}
                                        id={event?.id}
                                        handleClick={onClick}
                                        handleParticipate={(e) => handleParticipate(event, e)}
                                        size={size ? size : 30}
                                        currencyStorageCode={currencyStorageCode}
                                        type={type}
                                        // if I see my task, then check if it is allow to me to see them)
                                        isNotAllow={event?.creator?.id === user?.id && !createCourses}
                                    />
                                ) : null
                            ))}
                    </div>
                )}

                {type === 'list' &&
                    <div className='events-list_list'>
                        <EventsTableComponent
                            events={events}
                            user={user}
                            isNotAllow={!createCourses}
                            {...other}
                        />
                    </div>}

                {isLoading && (
                    <Loader />
                )}
                {isShowMore && !isLoading && (
                    <div className="events-list__action">
                        <Button type='secondary'
                            onClick={onShowMore}>
                            {formatMessage({ id: "show_more" })}
                        </Button>
                    </div>
                )}
            </div>
        </Spin>
    );
}
