import {useLazyQuery} from "@apollo/client";
import { getResult } from "../graphQL/queries/GetResult";

export function useGetResult(query = getResult) {
    const [getResult, { data, loading, error }] = useLazyQuery(query);
    return {
        result: data?.result,
        loading, error,
        getResult
    };
}