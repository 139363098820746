
import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useVimeoCaptions } from '../../vimeo';
import { VimeoPlayer } from '../../common/VideoIframe';


export const AddVimeoLink = ({
    onAddSource,
    index,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState();
    const [urlName, setUrlName] = useState();
    const { getVimeoCaptions } = useVimeoCaptions();
    const [showError, setShowError] = useState();
    const [url, setUrl] = useState();

    const onChange = async () => {
        await setUrl(null);
        await setShowError(null)
        const val = inputRef.current.input.value;
        const id = val.split('/').find((item) => Number.isInteger(parseInt(item)));
        setUrl(inputRef.current.input.value)

        if (id) {
            try {
                const respCaptions = await getVimeoCaptions({ variables: { id } });
                if (respCaptions) {
                    if (respCaptions?.error) { setShowError(respCaptions?.error.message); }
                    const sourceText = respCaptions?.data?.getVimeoCaptions?.captions;
                    setUrlValue(sourceText?.substring(0, 40000))
                    setUrlName(val)
                }
            } catch (error) {
                setShowError(error?.message || null)
            }
        }
    }
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }
    let object = { key: "vimeo", type: 'VIMEO', source: urlName || '', title: `My Vimeo video`, raw: urlValue || '', icon: "video" }
    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_video_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}
            >
                <span>
                    {formatMessage({ id: "paste_link" })}
                </span>
                <Input
                    onChange={onChange}
                    ref={inputRef}
                    placeholder="https://vimeo.com/123456789"
                    className="inline-el wrapper_inner-section"
                />
                {showError && <p className='error'>Check if captions are enabled, format is https://vimeo.com/123456789 and video accessible via this link</p>}

                {url &&
                    <VimeoPlayer
                        videoSrc={url}
                        {...other}
                    />
                }
            </Modal>
        </>
    );
};
