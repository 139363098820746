import {useEffect} from 'react';

export const useCustomScript = () => {
    useEffect(() => {
        return () => {
            const tag = document.getElementById('custom-script');
            if (!!tag) {
                tag.remove();
            }
        }
    }, []);

    return (scriptData) => {
        const tag = document.getElementById('custom-script');
        if (!!tag) {
            tag.remove();
        }
        const scriptTag = document.createElement('script');
        scriptTag.defer = true;
        scriptTag.setAttribute('id', 'custom-script');
        scriptTag.text = JSON.parse(scriptData);
        document.head.appendChild(scriptTag);
    };
}