import './scss/index.scss';
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Checkbox, Select, Switch } from 'antd';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Popconfirm } from 'antd';

import {
    LoginContainer,
    useIsAllow,
    UploadImgComponent,
    LoaderComponent,
    Svg,
    WysiwygComponent,
    WorkspaceTitleComponent
} from '../../common';
import { SubscriptionButtonComponent } from '../../subscription';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../notification/Notification.component';
import { StripeConnectedAccountContainer, StripeWorkspaceSubscriptionContainer } from '../../stripe';
import { ResendContainer } from '../../resend';
import { termsContent } from '../../footer/DefaultTerms.component';
import { IntegrationsContainer } from '../../integrations';
import { useIsPermit } from '../../roles/hook/useCheckPermission';

import CURRENCIES from '../../../constants/stripe-currencies.json';
const ARECORD = '51.12.92.210'
const TXT = '4FD4776CBF52C1383853612F4C289AC1C31F78BDF708E179478E490A6C876231'
const CNAME = 'front-unschooler.greenwave-a7b7af58.swedencentral.azurecontainerapps.io';

export const WorkspaceCreateComponent = (props) => {
    const {
        user,
        workspace,
        emptyWorkspace,
        setEditmode,
        form,
        handleCreateWorkspace,
        isDomain,
        hostError,
        inProgress,
        handleDeleteWorkspace,
        ...other
    } = props;

    const { TextArea } = Input;
    const { formatMessage } = useIntl();
    const [showLogin, setShowLogin] = useState();
    const [showUpload, setShowUpload] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [titleData, setTitleData] = useState();
    const [customDomain, setCustomDomain] = useState(workspace?.customDomain);
    const workspaceSource = workspace || emptyWorkspace;
    const { checkPermit } = useIsPermit()
    const editSchool = checkPermit(workspace, 'editSchool')
    const setUpPayments = checkPermit(workspace, 'setUpPayments')

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PRO") ;

    const handleLoginUser = () => {
        setShowLogin(true);
    };

    const getTabClassNames = (index) => classnames('profile-workspace__tab', {
        'profile-workspace__tab_active': activeTab === index
    });

    const getTabContentClassNames = (index) => classnames('profile-workspace__content', {
        'profile-workspace__content_active': activeTab === index
    });

    const onFormError = (err) => {
        if (err) {
            const isTitleError = !!err.errorFields.find((item) => item.name.includes('title'));
            const isHostError = !!err.errorFields.find((item) => item.name.includes('host'));
            const tabNumber = isTitleError || isHostError ? 0 : activeTab;
            setActiveTab(tabNumber);
        }
    };

    useEffect(() => {
        if (titleData) {
            setTimeout(() => {
                setShowUpload(true);
            }, [200]);
        }
    }, [form, titleData]);

    useEffect(() => {
        if (workspaceSource) {
            setTitleData(workspaceSource);
        }
    }, [workspaceSource, setTitleData])

    if (!titleData) {
        return <LoaderComponent type="overlay" />
    }

    return (
        <>
            {/*<div className="error-message" style={{height: "unset"}}>*/}
            {/*    We are updating the functionality for creating and editing schools, this may take some time. <br/>*/}
            {/*    Please do not change your school's settings or create a new school while the update is in progress - your changes may be lost during the update.*/}
            {/*</div>*/}
            <WorkspaceTitleComponent workspace={titleData} />
            <Form onFinish={handleCreateWorkspace}
                onFinishFailed={onFormError}
                form={form}
                layout="vertical"
                className="page ai-component__form"
                name='create-workspace'
                initialValues={
                    {
                        logo: workspaceSource?.logo,
                        title: workspaceSource?.title,
                        hideTitle: !!workspaceSource?.hideTitle,
                        cover: workspaceSource?.cover,
                        description: workspaceSource?.description,
                        host: workspaceSource?.host,
                        customDomain: workspaceSource?.customDomain,
                        loginOnly: workspaceSource?.loginOnly || false,
                        inviteOnly: workspaceSource?.inviteOnly || false,
                        currencyCode: workspaceSource?.currencyCode || 'USD',
                        stripeProductId: workspaceSource?.stripeProductId,
                        copyright: workspaceSource?.copyright || workspaceSource?.title,
                        contactEmail: workspaceSource?.contactEmail || 'info@unschooler.me',
                        address: workspaceSource?.address,
                        customPolicy: workspaceSource?.customPolicy,
                        customTerms: workspaceSource?.terms || termsContent,
                        customScript: workspaceSource?.customScript ? JSON.parse(workspaceSource?.customScript) : ''
                    }
                }
            >
                <h2>{formatMessage({ id: "setup_your_school" })}</h2>
                <div className=' flex-container edit-workspace'>
                    <div className="flex-right-column flex-right-column_workspace">
                        <div className="profile-block profile-workspace__body">
                            <div className={getTabClassNames(0)}
                                onClick={() => setActiveTab(0)}>
                                {formatMessage({ id: "workspace_title_name" })}
                            </div>

                            {/* <div className={getTabClassNames(1)}
                                onClick={() => setActiveTab(1)}>
                                {formatMessage({ id: "workspace_title_content" })}
                            </div> */}
                            {isAllow && (
                                <div className={getTabClassNames(2)}
                                    onClick={() => setActiveTab(2)}>
                                    {formatMessage({ id: "workspace_title_payments" })}
                                </div>
                            )}
                            <div className={getTabClassNames(3)}
                                onClick={() => setActiveTab(3)}>
                                {formatMessage({ id: 'form_workspace_custom_domain' })}
                            </div>
                            <div className={getTabClassNames(4)}
                                onClick={() => setActiveTab(4)}>
                                {formatMessage({ id: 'access_and_display' })}
                            </div>

                            <div className={getTabClassNames(5)}
                                onClick={() => setActiveTab(5)}>
                                {formatMessage({ id: 'footer_and_terms' })}
                            </div>
                            <div className={getTabClassNames(6)}
                                onClick={() => setActiveTab(6)}>
                                {formatMessage({ id: 'script_section' })}
                            </div>
                            <div className={getTabClassNames(7)}
                                onClick={() => setActiveTab(7)}>
                                {formatMessage({ id: 'integrations' })}
                            </div>
                        </div>
                    </div>
                    <div className='flex-left-column'>
                        <div className='profile-block profile-wrapper profile-workspace__body'>
                            <div className={getTabContentClassNames(0)}>
                                <Form.Item
                                    name="host"
                                    label={formatMessage({ id: 'Subdomain_insert_title' })}
                                    rules={[
                                        { required: true, message: formatMessage({ id: 'workspace_host_required' }) },
                                        () => ({
                                            validator(_, value) {
                                                const m = value && value.match(/[^a-z0-9]/g);
                                                const isValid = !m ? true : !m.filter((l) => l !== '-').length;
                                                const isValidStart = value?.[0] && value?.[0] !== '-';
                                                if (!!value && !isValid || !isValidStart) {
                                                    return Promise.reject(new Error(formatMessage({ id: 'workspace_host_is_not_valid' })));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    extra={formatMessage({ id: 'workspace_host_extra' })}
                                >
                                    <div>
                                        <Input
                                            className=" wrapper_inner-section input-inline"
                                            defaultValue={workspace?.host}
                                        />
                                        <span>.unschooler.me</span>
                                        {hostError && (
                                            <div role="alert" className="ant-form-item-explain-error">
                                                {formatMessage({ id: 'workspace_host_exist' })}
                                            </div>
                                        )}
                                    </div>
                                </Form.Item>
                                <hr />
                                <Form.Item
                                    name="title"
                                    label={formatMessage(
                                        { id: 'form_workspace_placeholder' })}
                                    rules={[{ required: true, message: 'Name of your school is requred' }]}
                                // extra="It will show in the browser tab"
                                >
                                    <Input
                                        placeholder={formatMessage({ id: "form_workspace_placeholder" })}
                                        className="inline-el wrapper_inner-section"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="hideTitle"
                                    valuePropName="checked"
                                >
                                    <Checkbox>{formatMessage({ id: 'hide_title_checkbox' })}</Checkbox>
                                </Form.Item>
                                <hr />
                                <Form.Item
                                    name="description"
                                    label={formatMessage(
                                        { id: 'form_workspace_description' })}
                                >
                                    <Input
                                        placeholder={formatMessage({ id: "form_workspace_description_placeholder" })}
                                        // disabled={!user?.id ? true : false}
                                        className="inline-el wrapper_inner-section"
                                    />
                                </Form.Item>
                                <hr />
                                <Form.Item
                                    label={formatMessage({ id: 'form_workspace_logo' })}
                                    name="logo"
                                    className="inline-el"
                                >
                                    <Input type="hidden" />
                                    {showUpload && (
                                        <UploadImgComponent limitImages="1"
                                            savedImages={workspaceSource?.logo ? [{ url: workspaceSource.logo }] : []}
                                            onRemove={() => {
                                                form.setFieldsValue({ logo: '' });
                                            }}
                                            onSetImages={(list) => {
                                                const url = list?.[0]?.response?.url;
                                                form.setFieldsValue({ logo: url });
                                                setTitleData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.logo = url;
                                                    return newData;
                                                });
                                            }}
                                            title='152x152 png, jpg,'
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label={formatMessage(
                                        { id: 'form_workspace_cover' })}
                                    name="cover"
                                    className="inline-el"
                                >
                                    <Input type="hidden" />
                                    {showUpload && (
                                        <UploadImgComponent limitImages="1"
                                            savedImages={workspaceSource?.cover ? [{
                                                url: workspaceSource.cover
                                            }] : []}
                                            onRemove={() => {
                                                form.setFieldsValue({ cover: '' });
                                            }}
                                            onSetImages={(list) => {
                                                const url = list?.[0]?.response?.url;
                                                form.setFieldsValue({ cover: url });
                                                setTitleData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.cover = url;
                                                    return newData;
                                                });
                                            }}
                                            title='1800x640 png, jpg,'
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={formatMessage({ id: 'form_workspace_favicon' })}
                                    name="favicon"
                                    className="inline-el"
                                >
                                    <Input type="hidden" />
                                    {showUpload && (
                                        <UploadImgComponent limitImages="1"
                                            savedImages={workspaceSource?.favicon ? [{ url: workspaceSource.favicon }] : []}
                                            onRemove={() => { form.setFieldsValue({ favicon: '' }); }}
                                            onSetImages={(list) => {
                                                const url = list?.[0]?.response?.url;
                                                form.setFieldsValue({ favicon: url });
                                                setTitleData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.favicon = url;
                                                    return newData;
                                                });
                                            }}
                                            title='32x32 png,'

                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={formatMessage({ id: 'sharing' })}
                                    name="sharing"
                                    className="inline-el"
                                >
                                    <Input type="hidden" />
                                    {showUpload && (
                                        <UploadImgComponent limitImages="1"
                                            savedImages={workspaceSource?.sharing ? [{ url: workspaceSource.sharing }] : []}
                                            onRemove={() => { form.setFieldsValue({ sharing: '' }); }}
                                            onSetImages={(list) => {
                                                const url = list?.[0]?.response?.url;
                                                form.setFieldsValue({ sharing: url });
                                                setTitleData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.sharing = url;
                                                    return newData;
                                                });
                                            }}
                                            title='1200 x 630 png, jpg'
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className={getTabContentClassNames(2)}>
                                <StripeConnectedAccountContainer isDisabled={!workspace?.host} />
                                <hr />
                                <Form.Item name="currencyCode"
                                    label={formatMessage({ id: 'currency' })}
                                >
                                    <Select options={CURRENCIES}
                                        style={{
                                            width: 'auto',
                                            minWidth: '200px'
                                        }} />
                                </Form.Item>
                                <StripeWorkspaceSubscriptionContainer workspace={workspace} />
                            </div>
                            <div className={getTabContentClassNames(3)}>
                                <h2>1. {formatMessage({ id: 'form_workspace_custom_domain' })}</h2>
                                <Form.Item
                                    name="customDomain"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                const m = !value ? null : !value?.match(/^http/)?.length ? value?.match(/^http/) : value?.match(/[^a-z0-9]/g);
                                                const isValid = !m ? true : !m?.filter((l) => l !== '-' && l !== '.')?.length;
                                                const isValidStart = !value || (value[0] !== '-' && value[0] !== '.');
                                                if (!!value && !isValid || !isValidStart) {
                                                    return Promise.reject(new Error(formatMessage({ id: 'workspace_domain_is_not_valid' })));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    extra={`${formatMessage({ id: 'workspace_domain_is_not_extra' })} devschool.org, dev-school.org, dev.school.org`}
                                >
                                    {isDomain ?
                                        <h3>{workspace?.customDomain}</h3> :
                                        <Input
                                            // placeholder={formatMessage({id: "form_workspace_placeholder"})}
                                            // disabled={!user?.id ? true : false}
                                            className="inline-el wrapper_inner-section"
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setCustomDomain(val);
                                            }}
                                        />
                                    }
                                </Form.Item>

                                {!!customDomain &&
                                    <div className='wrapper_inner-section'>
                                        <h3>
                                            {formatMessage({ id: 'form_workspace_add_records' })}
                                        </h3>
                                        <p>1. {formatMessage({ id: 'workspace_cname_message' })}</p>
                                        <div className='wrapper_inner-section'>
                                            <p><span className='text_secondary'>Type:</span> A </p>
                                            <p><span className='text_secondary'>Name:</span> @ </p>
                                            <p><span className='text_secondary'>Value:</span><CopyToClipboard
                                                text={ARECORD}
                                                onCopy={() => {
                                                    openNotification('topLeft', {
                                                        title: formatMessage({
                                                            id: 'notif_copy_link_title',
                                                        }),
                                                        // description: formatMessage({
                                                        //     id: 'notif_copy_event_link_workspace',
                                                        // }),
                                                        icon: '💬',
                                                        duration: 3,
                                                    });
                                                }}
                                            >
                                                <Button type='text'
                                                    size="small"
                                                    className='btn-record btn-left'
                                                >
                                                    {ARECORD}
                                                </Button>
                                            </CopyToClipboard></p>

                                        </div>
                                        <small className='text_secondary'>or</small>
                                        <div className='record wrapper_inner-section'>
                                            <p> <span className='text_secondary'>Type:</span> CNAME</p>
                                            <p><span className='text_secondary'>Name:</span>{customDomain}</p>
                                            <p><span className='text_secondary'>Value:</span>

                                                <CopyToClipboard
                                                    text={CNAME}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            // description: formatMessage({
                                                            //     id: 'notif_copy_event_link_workspace',
                                                            // }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='text' size="small"
                                                        className='btn-record btn-left'>
                                                        {CNAME}
                                                    </Button>
                                                </CopyToClipboard>
                                            </p>
                                        </div>
                                        <p>2. {formatMessage({ id: 'workspace_asuid_message' })}</p>
                                        <div className='record wrapper_inner-section'>
                                            <p><span className='text_secondary'>Type:</span>TXT </p>
                                            <p><span className='text_secondary'>Name:</span>asuid.{customDomain} </p>
                                            <p>
                                                <span className='text_secondary'>Value:</span>
                                                <CopyToClipboard
                                                    text={TXT}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            // description: formatMessage({
                                                            //     id: 'notif_copy_event_link_workspace',
                                                            // }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='text'
                                                        size="small"
                                                        className='btn-record btn-left'
                                                    >
                                                        {TXT}
                                                        {/* <Svg
                                                        name=':icon/share'
                                                        className='icon_32'
                                                    />  */}
                                                    </Button>
                                                </CopyToClipboard>
                                            </p>
                                        </div>
                                        <hr />
                                        <small
                                            className='wrapper_inner-section'>
                                            {formatMessage({ id: 'workspace_domain_status' })}: {' '}
                                            {workspace?.status ? '✅' : `❌ ${formatMessage({ id: 'workspace_domain_updates' })}`}</small>
                                    </div>
                                }
                                <ResendContainer/>
                            </div>
                            <div className={getTabContentClassNames(4)}>
                                <h2>{formatMessage({ id: 'access_and_display' })}</h2>
                                <Form.Item
                                    name='loginOnly'
                                    label={formatMessage({ id: 'login_only' })}
                                    className='edit-task-component__is-free'
                                    valuePropName="checked"

                                >

                                    <Switch
                                        // name='loginOnly'
                                        defaultChecked={!!workspaceSource?.loginOnly || false}
                                        onChange={(flag) => {
                                            form.setFieldsValue({ loginOnly: flag });
                                        }}
                                    />

                                </Form.Item>
                                <p className='text_secondary'>
                                    The school access will be restricted to logged-in users only. Before logging in, users will see the invitation page.
                                </p>
                                <Form.Item
                                    name='inviteOnly'
                                    label={formatMessage({ id: 'invite_only' })}
                                    className='edit-task-component__is-free'
                                    valuePropName="checked"
                                >
                                    <Switch
                                        // name='inviteOnly'
                                        defaultChecked={!!workspaceSource?.inviteOnly || false}
                                        onChange={(flag) => { form.setFieldsValue({ inviteOnly: flag }); }}
                                    />
                                </Form.Item>
                                <p className='text_secondary'>
                                    Access to the school is restricted to invited users only. Only those invited by the owner or admin can see the content. Others will see the invitation page even after logging in.                                </p>
                                <hr />
                                {workspaceSource?.id &&
                                    <Popconfirm
                                        title={formatMessage({ id: 'confirm_delete' })}
                                        onConfirm={handleDeleteWorkspace}
                                        okText='OK'
                                        cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                        icon={null}
                                    >
                                        <Button
                                            type='link'
                                            loading={inProgress}
                                            className=' wrapper_content-section'
                                        >
                                            <Svg name=':icon/delete' className='icon_16' />
                                            {formatMessage({ id: 'delete_workspace' })}
                                        </Button>
                                    </Popconfirm>
                                }

                            </div>
                            <div className={getTabContentClassNames(5)}>
                                <Form.Item
                                    name='copyright'
                                    label={formatMessage({ id: "workspace_create_footer_copyright" })}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name='contactEmail'
                                    label={formatMessage({ id: "email_title" })}>
                                    <Input type="email" />
                                </Form.Item>
                                <Form.Item
                                    name='address'
                                    label={formatMessage({ id: "workspace_create_address" })}>
                                    <TextArea />
                                </Form.Item>
                                <hr />
                                <h3>{formatMessage({ id: "workspace_create_custom_privacy_policy" })}</h3>
                                <Form.Item
                                    name='customPolicy'>
                                    <WysiwygComponent
                                        onChangeContent={(content) => {
                                            form.setFieldsValue({ customPolicy: content });
                                        }}
                                        initialState={form.getFieldValue('customPolicy')}
                                        placeholder={formatMessage({ id: "workspace_create_privacy_policy_placeholder" })}
                                    />
                                </Form.Item>
                                <hr />
                                <h3>{formatMessage({ id: "workspace_create_custom_terms" })}</h3>
                                <small className='text_secondary wrapper_content-section'>
                                    {formatMessage({ id: "workspace_create_custom_terms_info" })}
                                </small>

                                <Form.Item
                                    name='customTerms'>
                                    <WysiwygComponent
                                        onChangeContent={(content) => {
                                            form.setFieldsValue({ customTerms: content });
                                        }}

                                        initialState={form.getFieldValue('customTerms')}
                                        placeholder={formatMessage({ id: "workspace_create_terms_placeholder" })}
                                    />
                                </Form.Item>
                            </div>
                            <div className={getTabContentClassNames(6)}>
                                <h2>{formatMessage({ id: 'script_section' })}</h2>
                                <Form.Item
                                    name='customScript'
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                const includesScript = value.includes('<script');
                                                return includesScript
                                                    ? Promise.reject(new Error('The field cannot contain the tag "script"'))
                                                    : Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <TextArea style={{ minHeight: '300px' }} />
                                </Form.Item>
                                { /* TODO: Need translation*/}
                                <p>Use only valid JavaScript code without tag &lt;script&gt;</p>
                                <p>Debugging custom scripts is beyond our support scope, so thoroughly test your scripts before use in a <a href="https://www.site24x7.com/tools/javascript-validator.html" target="_blank" rel="noreferrer">JavaScript validator</a>, and be aware of potential conflicts with third-party scripts.</p>

                                <hr />
                                <p>Example (you can use this example for testing):</p>
                                <pre>
                                    var userName = prompt('What is your name?');
                                    var host = window.location.host;
                                    alert('Hello, ' + userName + '! Welcome to ' +  host + '!');
                                </pre>
                            </div>
                            <div className={getTabContentClassNames(7)}>
                                <IntegrationsContainer />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper_next-step">
                        <div> </div>
                        <div className="next-step_btns">
                            {isAllow
                                ?
                                <div>
                                    <Button
                                        type={'secondary'}
                                        htmlType='submit'
                                        disabled={inProgress}
                                        onClick={() => setEditmode(false)}
                                    >
                                        {formatMessage({ id: 'payment_request_modal_cancel' })}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        htmlType='submit'
                                        disabled={inProgress}
                                    >
                                        {formatMessage({ id: 'form_workspace_create' })}
                                    </Button>
                                </div>

                                :
                                <SubscriptionButtonComponent
                                    type='primary'
                                    title={formatMessage({ id: 'payment_button' })}
                                />
                            }
                        </div>
                    </div>

                    <LoginContainer
                        showLogin={showLogin}
                        setCancelLogin={setShowLogin}
                        onLoginUser={handleLoginUser}
                    />
                </div>
            </Form>
            {inProgress && <LoaderComponent type="overlay" />}
        </>
    );
}
