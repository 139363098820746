/* global YT */
import './scss/index.scss';
import React, { useEffect, useState, } from 'react';
import { useIntl } from 'react-intl';
import { Input, Button } from 'antd';

import { VideoSet, VideoPlayer, VideoAnyPlayer, VimeoPlayer, VideoGeneratorButton } from './';
import { useReducedCaptions, useGetSummaryLongForm } from '../../ai/hook';
import { useEditTask } from '../../task/hooks/useEditTask';
import { useIsAllow, useGetCaptions } from '../../common';

const TEXT_URL = 'https://text.unschooler.me/?url=';
const MAX_INPUT_LENGTH = 30000;

export const VideoGenerator = ({
    videoSrc,
    task,
    user,
    generateFromVideo,
    videoSet,
    handleRegenerate,
    setGenerateFromVideo,
    setInProgress,
    cover = task.cover?.type,
    workspace,
    handleChangeVideo,
    findVideosToCover,
    sayMeCaptions,
    userIsAllowGenerateContent,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const { getSummaryLongForm } = useGetSummaryLongForm();
    const getReducedCaptions = useReducedCaptions();
    const { editTask } = useEditTask();
    const [coverType, setCoverType] = useState(task?.cover?.type ? task?.cover?.type : cover);
    const { captions, captionsLoading, getCaptions } = useGetCaptions();

    const [summary, setSummary] = useState(task?.cover?.summary);
    const [showSummary, setShowSummary] = useState(false);
    const isVideo = coverType === 'VIDEO' && !!videoSrc;

    const handleGenerateTutorial = async (subtitles) => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'editTaskLoading'
        })

        if (!!subtitles) {
            const summary = await handleGenerateSummary(subtitles);
            if (summary) {
                handleRegenerate(summary);
                task?.id && saveSummaryToTask(subtitles, summary)
            }
        }
        setInProgress({
            key: 'editTaskLoading'
        })
    }
    const saveSummaryToTask = async (subtitles, summary) => {
        const params = {
            variables: {
                taskId: task?.id,
                taskData: {
                    cover: {
                        id: task?.cover?.id,
                        raw: subtitles?.substring(0, 10000),
                        source: task?.cover?.source,
                        type: coverType,
                        summary: summary,
                    },
                    source: summary,
                    ...(task?.tags && {
                        tagIds: task?.tags?.map((tag) => tag?.id),
                    })
                },
            }
        };
        await editTask(params);
    }
    const handleGenerateSummary = async (subtitles) => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'editTaskLoading'
        })

        if (!!subtitles) {
            const title = task?.title?.substring(0, 70);

            if (subtitles.length >= MAX_INPUT_LENGTH) {
                //make it shorter
                const summary = await getReducedCaptions(subtitles, title);
                if (task?.id) {
                    task?.id && saveSummaryToTask(subtitles, summary)
                }
                setSummary(summary);
                setInProgress({
                    key: 'editTaskLoading'
                })
                return summary;
            } else {
                const summary = await getSummaryLongForm(subtitles, title);
                if (task?.id) {
                    task?.id && saveSummaryToTask(subtitles, summary)
                }
                setSummary(summary);
                setInProgress({
                    key: 'editTaskLoading'
                })
                return summary;

            }

        }
    };
    const onGenerateSubtitles = async () => {
        setInProgress({
            message: formatMessage({ id: 'progress_subtitle_generation_message' }),
            key: 'generateSubtitles'
        })

        try {
            const myHeaders = new Headers();
            myHeaders.append("Origin", "https://text.unschooler.me");

            const resp = await fetch(`${TEXT_URL}${videoSrc}`, {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
            });
            const jsonData = await resp.json();
            if (jsonData?.text) {
                handleGenerateTutorial(jsonData?.text)
            }
        } catch (error) {
            console.error(error)
        }
        setInProgress({
            key: 'generateSubtitles'
        })
    }

    const handleSummaryClick = async () => {
        if (!summary) {
            handleGenerateSummary(captions);
        }
        setShowSummary(prev => !prev);
    }
    useEffect(() => {
        if (task?.id && generateFromVideo && !videoSet) {
            setGenerateFromVideo(true);

            (async () => {
                let text = task?.cover?.raw || task?.cover?.summary || captions || await getCaptions(coverType, videoSrc, task?.cover) || '';
                if (text) {
                    handleGenerateTutorial(text);
                } else {
                    coverType === "YOUTUBE" ? onGenerateSubtitles() : handleRegenerate();
                }
                setGenerateFromVideo(false);
            })();
        }
    }, [task?.cover?.source, generateFromVideo])

    useEffect(() => {
        if (videoSrc && !captionsLoading && !captions) {
            (async () => {
                await getCaptions(coverType, videoSrc, task?.cover);
                setCoverType(task?.cover?.type);
            })();
        }
    }, [task?.cover?.source]);

    useEffect(() => {
        if (cover) {
            setCoverType(cover);
        }
    }, [cover])
    sayMeCaptions = { captions }
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("PREMIUM", workspace, task?.creator?.id);
    const creatorOrAdmin = user?.id === task.creator.id || (user && user?.role?.id === 1);

    const handleVideoClick = (index) => {
        if (index > 0) {
            setCoverType("YOUTUBE")
        }
        handleChangeVideo(index);
    }
    return (
        <>
            {userIsAllowGenerateContent && (
                <>
                    <div className='video_header wrapper_inner-section'>
                        {/* {!!videoSet?.length && (
                            <Search
                                disabled={!isAllow}
                                placeholder={formatMessage({ id: 'search_string' })}
                                onSearch={(e) => findVideosToCover(e)}
                                enterButton={false}
                                loading={truncate}
                            />
                        )} */}
                        {(!videoSet?.length && creatorOrAdmin) && (
                            <Button
                                type="link"
                                size='small'
                                onClick={() => findVideosToCover()}
                            >
                                {formatMessage({ id: 'regenerate_video_button' })}
                            </Button>
                        )}
                    </div>

                    <VideoSet videoSet={videoSet} isAllow={userIsAllowGenerateContent} handleChangeVideo={handleVideoClick} {...other} />
                </>
            )}
            {coverType === "YOUTUBE" && (
                <VideoPlayer
                    {...other}
                    timestampsList={task?.cover?.timestamps}
                    task={task}
                    user={user}
                    videoSrc={videoSrc}
                    videoSet={videoSet}
                    showCaptions={true}
                    handleGenerateSummary={handleGenerateSummary}
                    captions={captions}
                    summary={summary}
                    handleSummaryClick={handleSummaryClick}
                    showSummary={showSummary}
                    handleChangeVideo={handleVideoClick}
                    {...other}
                />
            )}
            {coverType === "VIMEO" && (
                <VimeoPlayer
                    {...other}
                    videoSrc={videoSrc}
                />
            )}
            {(coverType === "VIDEO" && !!task?.cover?.source?.length) &&
                <>
                    {isVideo && (
                        <video width="100%" controls>
                            <source src={videoSrc} />
                        </video>
                    )}
                    <VideoAnyPlayer
                        {...other}
                        timestampsList={task?.cover?.timestamps}
                        task={task}
                        user={user}
                        showCaptions={true}
                        video={{ source: '' }}
                        captions={captions}
                    />
                </>
            }
            {(!task?.cover?.source?.length || !task?.cover) &&
                <VideoGeneratorButton
                    task={task}
                    user={user}
                    setInProgress={setInProgress}
                    workspace={workspace}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    {...other}
                />
            }
        </>
    );
};
