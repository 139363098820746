import { gql, useMutation } from "@apollo/client";
import { useGetUser } from "..";

export function useEditUser() {
    const { getUser } = useGetUser();
    const [mutationEditUser, { data, loading, error }] = useMutation(EDIT_USER);

    async function editUser(userData) {
        const result = await mutationEditUser({
            variables: { userData }
        });
    }

    return {
        loading,
        error,
        editUser
    }
}

const EDIT_USER = gql`
    mutation EditUser($userData: EditUserInput!) {
        editUser(userData: $userData) {
            id
        }
    }
`;