/* eslint-disable jsx-a11y/anchor-has-content */
import './scss/index.scss';
import React, { useContext, useState } from 'react';
import { Button, Spin, Form } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { TasksListComponent } from '../../task';
import { useLocalePrefix } from '../../lang';
import {
    LoaderOnboardingComponent,
    TagComponent,
    useTrackClick,
    useIsAllow,
    useTrackCourses
} from '../../common';
import { useCreateEvent } from '../hooks/useCreateEvent';
import { useCreateTasksForEvent } from '../../common/hooks/useCreateTasksForEvent';
import { TaskButtonDnD } from '../../task/TaskDnD/TaskButtonDnD';

export const EventCurriculumItemComponent = (props) => {
    const {
        event,
        user,
        setEventLoading,
        eventLoading,
        small,
        tags,
        tagData,
        channelId,
        setEvent,
        workspace,
        hideInterface,
        isAutogenerated,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    const { loadingCourse } = useContext(FirebaseContext);
    const { trackCourses, isLimitReached } = useTrackCourses(user);
    const { title: tagTitle, subject, color } = tagData || {};
    const tagColor = subject?.color || color;
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const history = useHistory();
    const { createEvent, loading: loadingCreateEvent } = useCreateEvent();
    const getTasksForEvent = useCreateTasksForEvent();
    const userIsAllowd = useIsAllow();
    // const isAllow = userIsAllowd("PREMIUM", workspace);
    // const canCreateEvent = isAllow;
    const canCreateEvent = user?.id;
    
    const buttonLabel = canCreateEvent
        ? formatMessage({ id: 'payment_button_create' })
        : formatMessage({ id: 'payment_button_course' });
    const [error, setError] = useState();

    const handleCreateEvent = async (e) => {
        trackClick(
            'click-course',
            event?.title,
            `https://unschooler.me${localePrefix}/online-courses/${event.alias}-${event.id}`
        );

        let tagIdsArray = [];
        if (!tags?.length) {
            tagIdsArray = [1524];
        } else {
            tags?.forEach((tag) => tagIdsArray.push(tag.id));
        }

        if (canCreateEvent) {
            if (!user?.id) {
                history.push(`/login`);
            } else {
                setEventLoading(true);
                let taskIds = await getTasksForEvent(event, null, workspace?.id, isAutogenerated);
                const newData = {
                    cost: undefined,
                    description: '',
                    equipment: undefined,
                    title: event?.title,
                    issue: event.issue,
                    galleriesInEvent: event.galleriesInEvent,
                    isDraft: true,
                    listTimeData: [],
                    mentorAmount: undefined,
                    price: undefined,
                    priceSubscription: undefined,
                    program: event.program || '',
                    result: '',
                    studentsLimit: null,
                    tagIds: tagIdsArray,
                    taskIds: taskIds,
                    type: 'ASYNC',
                    userCost: undefined,
                    ...(!!workspace?.id && { workspaceId: workspace.id })
                };

                try {
                    const createdEventData = await createEvent({
                        variables: { eventData: newData, },
                    });

                    await trackCourses();
                    const createdEvent = createdEventData.data?.event;

                    setEventLoading(false);
                    history.push(`${localePrefix}/online-courses/${createdEvent.id}`)
                } catch (e) {
                    console.warn(e);
                    setEventLoading(false);
                }
            }
        } else {
            history.push(`/payments`);
        }
    };

    return (
        <Spin spinning={!!eventLoading} wrapperClassName='spiner-stiky'>
            {loadingCourse &&
                <LoaderOnboardingComponent
                    type="overlay"
                    label={formatMessage({ id: 'ai_generation_tasks' })}
                />
            }

            <div
                className='wrapper_event-cur-item'
                style={{ backgroundColor: tagColor }}
            >
                {!event?.id ? (
                    <div className='event-cur-content'>
                        <small className='wrapper_inner-section'>{formatMessage({ id: 'source_course_desc' })}:</small>
                        <div className='event-cur-item'>
                            <div className='event-card-component__body'>
                                {event?.title && (
                                    <Form
                                        onFinish={handleCreateEvent}
                                        // form={form}
                                        layout='vertical'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                    >

                                        <h2 className='event-cur-item__title'>
                                            {event?.title}
                                        </h2>
                                        <div className='wrapper_content-section'>
                                            <TaskButtonDnD
                                                {...other}
                                                tasks={event?.tasks}
                                                actionHidden={true}
                                                setEvent={setEvent}
                                                event={event}
                                            />

                                        </div>
                                        {error && <small className='slides_empty_message'>{error}<Link to={'/payments'}> Upgrade your plan</Link></small>}
                                        <Button
                                            type={canCreateEvent ? 'tertiary' : 'primary'}
                                            block="true"
                                            htmlType='submit'
                                            disabled={
                                                !!eventLoading || !event?.tasks?.length || isLimitReached
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <span>{buttonLabel}</span>
                                        </Button>
                                        {isLimitReached && (
                                            <div className="error">
                                                You have reached your course limit
                                            </div>
                                        )}
                                    </Form>
                                )}
                            </div>
                        </div>

                    </div>
                ) : (
                    <>
                        <Link
                            className='event-cur-item'
                            onClick={() =>
                                trackClick(
                                    'click-course',
                                    event?.id,
                                    `https://unschooler.me${localePrefix}/online-courses/${event?.alias}-${event?.id}`
                                )
                            }
                            to={`/online-courses/${event.id}`}
                        >
                            <div className='event-card-component__body'>
                                <h2 className='event-cur-item__title'>
                                    {event?.title}
                                </h2>
                                {(event.tags || event.subjects)?.map((tag, i) => (
                                    <TagComponent
                                        key={i}
                                        tag={tag}
                                        colorful={true}
                                        link={false}
                                    />
                                ))}
                            </div>
                        </Link>

                        <TasksListComponent
                            {...other}
                            tasks={event.tasks}
                            actionHidden={true}
                        />
                    </>
                )}
            </div>
        </Spin>
    );
};
