import './scss/editor.scss';
import React, {useState, useMemo} from 'react';
import {useEditor, EditorContent, BubbleMenu, FloatingMenu} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import Placeholder from '@tiptap/extension-placeholder';
import {Iframe} from './Iframe';
import {Video} from './video'

import Turndown from 'turndown';
import * as Showdown from 'showdown'
import {UploadOutlined} from '@ant-design/icons';
import {ToolbarComponent} from './Toolbar.component';
import {ExtentionComponent} from './Extention.component'
import {Button, Upload} from 'antd';
import {addImageHelper} from './helpers';

const turndownService = new Turndown();
turndownService.keep(['iframe', 'video']);

const converter = new Showdown.Converter();

export const WysiwygComponent = (props) => {
    const {editorClassNames, initialState = '', fileList, setFileList, onChangeContent, placeholder} = props;
    const [error, setError] = useState();

    const initialHtml = useMemo(() => (
        converter.makeHtml(initialState)?.replace(/<p> *(<img.+\/*>) *<\/p>/g, '$1')
        + '<p class="empty-line-placeholder"></p>' // Add an empty paragraph at the end
    ), [initialState, converter]);

    const editor = useEditor({
        extensions: [
            Link.configure({
                openOnClick: false,
            }),
            Image.configure({
                inline: true,
            }),
            Youtube,
            StarterKit,
            ExtentionComponent,
            Iframe,
            Video,
            Placeholder.configure({
                emptyNodeClass: 'is-empty',
                emptyEditorClass: 'is-editor-empty',
                placeholder: placeholder || "Type '/' for commands...",
            })
        ],
        content: initialHtml,
        onUpdate: ({editor}) => {
            onChangeContent(turndownService.turndown(editor?.getHTML() || ''))
        },
    });

    const onUploadFile = async (data) => {
        let file = await addImageHelper(data, editor, setError)
        if (file) {
            let fileClone = {...file}
            fileClone.title = data.file.name
            fileClone.name = data.file.name
            setFileList([fileClone])
        }
    }

    return (
        <div className={editorClassNames}>
            <div className="editor-wrapper">
                <div className='menu'>
                    {editor && ToolbarComponent && <FloatingMenu editor={editor} tippyOptions={{duration: 100}}>
                        <ToolbarComponent editor={editor} setError={setError} className="floating-component"/>
                    </FloatingMenu>}
                </div>

                <div className='flex-container flex-container_between'>
                    {setFileList &&
                    <Upload accept="image/*,application/pdf,text/plain,application/msword"
                            customRequest={(data) => onUploadFile(data)}
                            showUploadList={{
                                showDownloadIcon: true,
                                showRemoveIcon: false,
                                showPreviewIcon: true,
                                downloadIcon: 'Download',
                            }}
                            fileList={fileList}
                    >
                        {!fileList?.length &&
                        <Button
                            icon={<UploadOutlined/>}
                            type="text"
                            // size='small'
                        >
                            { /* TODO: Need translation */ }
                            Upload
                        </Button>
                        }
                    </Upload>
                    }
                </div>
                {/* correct editor */}
                {editor && editor.state.selection && (
                    <BubbleMenu editor={editor} tippyOptions={{duration: 100}}>
                        <ToolbarComponent editor={editor} setError={setError}/>
                    </BubbleMenu>
                )}

                <EditorContent editor={editor} placeholder={placeholder}/>
            </div>
            {error && (
                <div className="error-message">
                    The size of image is too large, optimize it on tinyjpg.com </div>
            )}
        </div>
    )
}
