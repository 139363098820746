import "./scss/index.scss";

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { noop } from "lodash";
import { Modal, Button } from "antd";
import { LangSwitcherContainer } from "../../lang/LangSwitcher.container/LangSwitcher.container";
import { useLocalePrefix } from "../../lang";

import {
  AuthButtonComponent,
  AuthFormSignUpComponent,
  useTrackClick,
  TermsInfoComponent,
  AuthFormComponent,
} from "../../common";
import YoutubeImage from "../../../images/developed-with-youtube.svg";

export const LoginComponent = ({
  onSetLogin = noop,
  onCancelLogin = noop,
  invites = [],
  showLogin,
  onLoginUser = noop,
  isSighUp = false,
  lang,
}) => {
  const { formatMessage } = useIntl();
  const trackClick = useTrackClick();
  const [isSighUpMode, setIsSighUpMode] = useState(isSighUp);

  useEffect(() => {
    trackClick("show-login", "show-login", ``);
  }, []);

  return (
    <Modal
      visible={showLogin}
      footer={null}
      onCancel={() => onCancelLogin(false)}
    >
      <div className="auth-form">
        <div className="auth-form__section">
          <h2 className="auth-form__title">
            {isSighUpMode ? formatMessage({ id: "auth_form_title_signup" }): formatMessage({ id: "auth_form_title" })}
          </h2>
        </div>
        {isSighUpMode &&
          <>
            <div className=" auth-form__section auth-form__section_btn">
              <LangSwitcherContainer
                lang={lang}
                type="bottom"
                optionLabel="title"
              />
            </div>
            <hr />
          </>

        }
        <div className='login_form_wrapper'>
          <div className='login_form'>
            {/*<AuthButtonComponent*/}
            {/*  onCancelLogin={onCancelLogin}*/}
            {/*  onLoginUser={onLoginUser}*/}
            {/*  lang={lang}*/}
            {/*/>*/}

            {/* <hr />*/}
            <div className='wrapper_outline'>
              {isSighUpMode ?
                <AuthFormSignUpComponent
                  type='compact'
                  invites={invites}
                  lang={lang}
                  onLoginUser={onLoginUser}
                  onCancelLogin={onCancelLogin}
                  signIn={true}
                />
                :
                <AuthFormComponent
                  type='compact'
                  invites={invites}
                  lang={lang}
                  onLoginUser={onLoginUser}
                  onCancelLogin={onCancelLogin}
                  signIn={true}
                />
              }
            </div>
          </div>
        </div>

        {isSighUpMode &&
          <div>
            <hr />
            <div className="api-button-component__note ">
              <TermsInfoComponent />
              <hr />
            </div>
          </div>
        }

        {isSighUpMode ?
          <>
            <Button type="link" size="small"  onClick={() => setIsSighUpMode(false)}>{formatMessage({ id: "login" })}</Button>
            <span className="span-with-btn"> {formatMessage({ id: "login_login_acc_description" }) } </span>
          </>
          :
          <>
            <Button type="link" size="small"  onClick={() => setIsSighUpMode(true)}>{formatMessage({ id: "signup" })}</Button>
            <span> {formatMessage({ id: "login_create_acc_description" }) }</span>
          </>
        }
      </div>
    </Modal>
  );
};
