import React, { useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { useIntl } from 'react-intl'

import { DragDropContext } from 'react-beautiful-dnd';
import { useEditTask } from "../hooks/useEditTask";
import { useCreateTask } from '../hooks/useCreateTask';
import { TaskDnD } from './TaskDnD';
import { LoaderComponent, useIsAllow } from '../../common';
import { getSimpleTagIds } from '../../common/hooks/useGetTags';

export const TaskButtonDnD = (props) => {
    const {
        tasks,
        showTaskView,
        setEvent,
        onUpdateEvent,
        event,
        isNotAllowedAddTask,
        workspace,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    const { editTask, loading } = useEditTask();
    const inputRef = useRef()
    const [inputValue, setInputValue] = useState(''); // Add this line to track input value
    const [showLessonInput, setShowLessonInput] = useState(false);

    const useIsAllowd = useIsAllow();
    const isAllowEdit = useIsAllowd("PREMIUM", workspace, event?.creator?.id ) ;

    const {
        createTask,
        loading: loadingCreateTask,
        task: taskCreated,
    } = useCreateTask();

    const renewEvent = (newTasks) => {
        setEvent(p => {
            return ({ ...p, tasks: newTasks })
        });
    }
    const saveTask = async (taskData) => {
        const data = await editTask({
            variables: {
                taskId: taskData?.id,
                taskData: {
                    questions: taskData?.questions?.map((q, i) => {
                        return {
                            ...q,
                            order: i
                        };
                    }),
                    tagIds: taskData?.tags?.map((tag) => tag.id),
                },
                isOwner: isAllowEdit,
            },
        });
        onUpdateEvent();
        // let newTasks = [...tasks];
        // let taskIndex = newTasks.findIndex((t) => taskData?.id === t.id);
        // newTasks[taskIndex] = data.data.editTask;
        // renewEvent(newTasks);
        return data;
    }
    const moveQuestion = async (questionId, toModuleId, destinationIndex) => {
        let newTasks = [...tasks];
        let question;
        let task1index;

        // Find and remove the question from its current task
        newTasks = newTasks.map((task) => {
            const questionIndex = task.questions?.findIndex(l => {
                if (l.id) {
                    return l.id === Number(questionId)
                } else {
                    return l.title === questionId
                }
            })

            if (questionIndex !== -1) {
                const newQuestions = [...task.questions] || [];
                question = newQuestions.splice(questionIndex, 1)[0];
                // task1index = task?.id;
                task1index = task?.title;
                return {
                    ...task,
                    questions: newQuestions
                };
            }
            return task;
        });

        // Add the question to the target task
        // const targetModuleIndex = newTasks.findIndex(m => m.id === Number(toModuleId));
        const targetModuleIndex = newTasks.findIndex(m => m.title === toModuleId);
        if (targetModuleIndex !== -1 && question) {
            let targetModuleQuestions = [...newTasks[targetModuleIndex].questions] || [];
            targetModuleQuestions.splice(destinationIndex, 0, question);
            const questions = targetModuleQuestions.map((q, i) => {
                return {
                    ...q,
                    order: i
                };
            });

            //change the task2 content
            newTasks[targetModuleIndex] = {
                ...newTasks[targetModuleIndex],
                questions: questions
            };
            renewEvent(newTasks);

            //save content
            // const task1 = newTasks.find((t) => t.id === Number(task1index));
            // const task2 = newTasks.find((t) => t.id === Number(toModuleId));
            const task1 = newTasks.find((t) => t.title === task1index);
            const task2 = newTasks.find((t) => t.title === toModuleId);

            if (task1?.id !== task2?.id) {
                //this is different tasks
                task2.questions[destinationIndex] = { ...question, id: null };
                if (event?.id) {
                    let data = await saveTask(task2);
                    //replace the task2  with data.data.editTask  in the newTasks
                    // let task2Index = newTasks.findIndex((t) => t.id === Number(toModuleId));
                    let task2Index = newTasks.findIndex((t) => t.title === toModuleId);
                    newTasks[task2Index] = data.data.editTask;
                }
                renewEvent(newTasks);
            }
            if (event?.id) {
                await saveTask(task1);
            }
            renewEvent(newTasks);
        }
    };
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;
        moveQuestion(result.draggableId, destination.droppableId, destination.index);
    };
    const handleMoveUpTask = async (task) => {
        let newTasks = [...tasks];
        let index = newTasks.findIndex((t) => t.title === task.title && t.id === task.id);
        if (index > 0) {
            [newTasks[index], newTasks[index - 1]] = [newTasks[index - 1], newTasks[index]];
            renewEvent(newTasks);
        }
    }
    const handleAddTask = async (input) => {
        let newTask = {
            title: input,
        }
        if (event?.id) {
            const convertedTagIds = getSimpleTagIds(event.tags);
            newTask = {
                title: input,
                tagIds: convertedTagIds,
                taskInEvents: [
                    {
                        eventId: event?.id,
                    },
                ]
            };
            const data = await createTask({
                variables: {
                    taskData: newTask
                },
            });
            renewEvent([...tasks, newTask]);
            onUpdateEvent();

        } else {
            renewEvent([...tasks, newTask]);
        }

    }
    const handleAddQuestion = async (task, input) => {

        let index = task.questions?.length || 0;
        let newQuestion = {
            type: "TEXT",
            question: '',
            title: input || "New step",
            order: index,
        };

        let convertedQuestions = task.questions ? [...task.questions, newQuestion] : [newQuestion];

        if (task?.id) {
            const data = await editTask({
                variables: {
                    taskId: task?.id,
                    taskData: {
                        questions: convertedQuestions,
                        tagIds: task.tags.map((tag) => tag.id),
                    },
                    isOwner: isAllowEdit,

                },
            });
            onUpdateEvent();

        } else {
            task.questions = convertedQuestions;
            let newTasks = [...tasks];
            let taskIndex = newTasks.findIndex(t => t.title === task.title);
            newTasks[taskIndex] = task;
            renewEvent(newTasks);

        }
    }

    const handleDeleteQuestion = async (task, index) => {
        let updatedQuestions = task.questions.filter((_, questionIndex) => questionIndex !== index);
        if (task?.id) {
            // Update the task with the new list of questions
            const data = await editTask({
                variables: {
                    taskId: task?.id,
                    taskData: {
                        questions: updatedQuestions,
                        tagIds: task.tags.map((tag) => tag.id),
                    },
                    isOwner: isAllowEdit,
                },
            });
            onUpdateEvent();
        } else {
            // Update the local state if the task is not saved yet
            task.questions = updatedQuestions;
            let newTasks = [...tasks];
            let taskIndex = newTasks.findIndex(t => t.title === task.title);
            newTasks[taskIndex] = task;
            renewEvent(newTasks);
        }
    }

    return (
        <>
            {loading && <LoaderComponent type="overlay" />}
            <DragDropContext onDragEnd={onDragEnd}>
                {tasks?.map((task, index) => (
                    <TaskDnD
                        {...other}
                        key={index}
                        index={index}
                        task={task}
                        tasks={tasks}
                        showTaskView={showTaskView}
                        handleAddQuestion={handleAddQuestion}
                        handleDeleteQuestion={handleDeleteQuestion}
                        handleMoveUpTask={handleMoveUpTask}
                        setEvent={setEvent}
                        onUpdateEvent={onUpdateEvent}
                        event={event}
                    />
                ))}
            </DragDropContext>

            {showLessonInput ? (
                <>
                    <Input
                        ref={inputRef}
                        value={inputValue} 
                        onChange={(e) => setInputValue(e.target.value)} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleAddTask(inputValue); 
                                setInputValue(''); 
                                setShowLessonInput(false);
                            }
                        }}
                    />
                    <Button
                        type='text'
                        size='small'
                        className="btn-left link-btn_light"
                        onClick={() => {
                            handleAddTask(inputValue);
                            setInputValue(''); 
                            setShowLessonInput(false)
                        }}
                    >
                        {formatMessage({ id: 'save_button' })}
                    </Button>

                </>
            ) :
                <Button type='text'
                    size='small'
                    className="btn-left "
                    onClick={() => setShowLessonInput(true)}
                    disabled={isNotAllowedAddTask}
                    >

                    + {formatMessage({ id: 'form_task_create_title' })}
                </Button>}
        </>
    )
}
