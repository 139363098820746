import React, { useState } from 'react';
import { Button, Form, Input, Select, Switch, Tabs, InputNumber, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { StripeProductDataContainer } from '../../stripe';
import { CloneEventContainer } from '../CloneEvent.container';
import { useIntl } from 'react-intl';

import { useConvertedPrice, getMentorCost, Svg } from '../../common';
import { WorkspaceSelectContainer } from '../../workspace/WorkspaceSelect.container';
const { TabPane } = Tabs;

export const PublishingTabComponent = ({
    eventState,
    onRemove,
    user,
    form,
    getFieldValue,
    setFieldsValue,
    setCostLocal,
    setUserCostLocal,
    userCostLocal,
    costLocal,

    currencyCode,
    isPaymentDisabled,
    setActivePaymentTab,
    activePaymentTab,
    setStripeProductId,
    stripeProductId,
    clearStripeData,
    stripeData,
    batchEdit,
    formValues,
    ...other
}) => {
    const PLANCOMISSION = { TIER_1: 3, TIER_2: 1, TIER_3: 0, PRO: 0, CREATOR: 1, UNIVERSITY: 0 }
    const subjectsList = [{ value: 245, label: 'Featured' }];
    const { getCurrency } = useConvertedPrice();
    const isPublicContent = eventState?.galleriesInEvent?.some(gInE => gInE?.gallery?.type === 'GIF');
    const [isPaid, setIsPaid] = useState(eventState?.price?.forStudent);
    const { formatMessage } = useIntl();

    const handleChangeCost = async (courseUserCost) => {
        const studentsLimit = getFieldValue('studentsLimit');
        const courseCost = await getMentorCost(courseUserCost, studentsLimit, 0);
        setFieldsValue({ cost: courseCost || 0 });
        setCostLocal(courseCost || 0);
        setUserCostLocal(courseUserCost || 0);
    };
    
    const handleIsFreeChange = () => {
        let state = !isPaid;
        setIsPaid(state);
        if (!state ) {
            handleChangeCost(0);
        }else {
            handleChangeCost(getFieldValue('userCost'));
        }
    };

    const getFormItemClass = (name) => {
        return formValues && formValues[name] == null ? 'input_transparent' : '';
    };

    return (
        <div>
            {/* {user?.role?.id === 1 &&
                <Form.Item
                    name='subjectIds'
                    label={'Subject'}
                    extra={formatMessage({ id: 'create_event_subject_extra' })}
                    rules={[
                        {
                            required: false,
                            message: formatMessage({ id: 'create_event_subject_message' })
                        },
                    ]}
                    initialValue={eventState?.subjects?.map(s => s.id)}
                    className={getFormItemClass('subjectIds')}
                >
                    <Select
                        style={{ width: '100%' }}
                        autoClearSearchValue='true'
                        allowClear='true'
                        mode='multiple'
                        className='input_medium'
                        options={subjectsList}
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} />
                </Form.Item>
            } */}

            <div>
                {!batchEdit &&
                    <>
                        <div className='flex-container'>
                            <CloneEventContainer eventId={eventState?.id} />

                            <Popconfirm
                                title={formatMessage({ id: 'confirm_delete' })}
                                onConfirm={onRemove}
                                okText='OK'
                                cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                icon={null}
                                okButtonProps={{ danger: true, className: 'button_confirm' }}

                            >
                                <Button
                                    className='edit-task-component__remove block-el'
                                    type='link'
                                >
                                    <Svg name=':icon/delete' className='icon_16' />
                                    {formatMessage({ id: 'create_event_remove_event' })}
                                </Button>
                            </Popconfirm>

                        </div>
                        <hr />
                    </>
                }
                <Form.Item name='isDraft'
                    label={formatMessage({ id: 'form_is_draft', })}
                    className={`edit-task-component__is-free ${getFormItemClass('isDraft')}`}
                    valuePropName='checked'
                >
                    <Switch />
                </Form.Item>
                <hr />
                <Form.Item
                    name='isHideAi'
                    label={formatMessage({ id: 'form_is_hide_ai', })}
                    className={`edit-task-component__is-free ${getFormItemClass('isHideAi')}`}
                    valuePropName='checked'
                >
                    <Switch />
                </Form.Item>
            </div>

            <hr className="wrapper_content-section" />
            {!isPublicContent ?
                <div className='booking-form__cost'>
                    <Form.Item
                        label={formatMessage({ id: 'course_selling', })}
                        className={`edit-task-component__is-free ${getFormItemClass('isPaid')}`}
                        valuePropName='checked'
                        initialValue={isPaid}
                        name='isPaid'
                    >
                        <Switch checked={isPaid} onChange={handleIsFreeChange} />
                    </Form.Item>
                    {isPaid && <Tabs defaultActiveKey="0" onChange={(activeKey) => setActivePaymentTab(parseInt(activeKey))}>
                        <TabPane tab="One-time payment" key="0">
                            <p>
                                <Link to="/profile">{formatMessage({ id: 'stripe_profile_link_firstpart' })}</Link>
                                {' '}{formatMessage({ id: 'stripe_profile_link_secondtpart' })}
                            </p>
                            <p>{formatMessage({ id: 'create_event_cost_label' })}
                                ({PLANCOMISSION[user?.subscriptionUser?.type]}% + {PLANCOMISSION[user?.subscriptionUser?.type] + 3}%
                                {formatMessage({ id: 'stripe_profile_link_title' })})
                            </p>
                            <Form.Item
                                name='userCost'
                                className={`inline-el ${getFormItemClass('userCost')}`}
                                initialValue={eventState?.price?.forStudent || null}
                            >
                                <InputNumber
                                    min={2}
                                    style={{ width: '88px' }}
                                    onChange={handleChangeCost}
                                    className='item_right'
                                    placeholder={'min $2'}
                                />
                            </Form.Item>
                            <Form.Item
                                className={`inline-el ${getFormItemClass('cost')}`}
                                name='cost'
                                initialValue={eventState?.price?.forTeacher || null}
                            >
                                <Input
                                    readOnly={true}
                                    style={{ width: '88px', border: 'none' }}
                                    className='item_right'
                                    disabled={true}
                                />
                            </Form.Item>
                            <span className="currency-symbol">{getCurrency()}</span>

                            {currencyCode !== 'USD' && userCostLocal && (
                                <div className='booking-form__cost__value'>
                                </div>
                            )}
                            {currencyCode !== 'USD' && costLocal && (
                                <div className='booking-form__cost__value'>
                                </div>
                            )}
                            {isPaymentDisabled && (
                                <p><Link to="/profile">{formatMessage({ id: 'connection_link_stripe_part1' })}</Link>{formatMessage({ id: 'connection_link_stripe_part2' })}</p>
                            )}

                            <Form.Item
                                name='isAllowPromoCodes'
                                label={formatMessage({ id: 'allow_promo_codes' })}
                                className={`edit-task-component__is-free ${getFormItemClass('isAllowPromoCodes')}`}
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                            <p><a href="https://dashboard.stripe.com/coupons" target="_blank" rel="noopener noreferrer">Add promo code in Stripe</a> {formatMessage({ id: 'add_and_apply' })}</p>
                        </TabPane>

                        {!batchEdit && <TabPane tab="Subscription" key="1">
                            <Form.Item
                                name='stripeProductId'
                                label="Stripe Product ID"
                                extra={<p>
                                    <a href="https://dashboard.stripe.com/products" target="_blank" rel="noopener noreferrer">
                                        {formatMessage({ id: 'add_product_firstpart' })}
                                    </a>{formatMessage({ id: 'add_product_secondpart' })}
                                </p>}
                                onChange={(e) => setStripeProductId(e.target.value)}
                                className={getFormItemClass('stripeProductId')}
                            >
                                <Input />
                            </Form.Item>
                            <StripeProductDataContainer eventId={eventState?.id}
                                clearStripeData={clearStripeData}
                                stripeProductId={stripeProductId}
                                stripeAccountData={stripeData}
                                stripeProduct={eventState?.stripeProduct?.[0]} />
                        </TabPane>
                        }
                    </Tabs>}
                </div>
                :
                <p>
                    This course uses public YouTube videos as a source, so it can only be published for free. If you change the video, and request moderation for this course via info@unschooler.me.
                </p>
            }
            <hr />
            <WorkspaceSelectContainer
                getFormItemClass={getFormItemClass}
                {...other}
            />
        </div>
    );
};