import React, { useRef, useMemo } from 'react';
import { Button, Modal, Input, Image } from 'antd';
import { LoaderComponent } from '../../../common';

import IMG from '../source/ppi-min.png';

const Textarea = Input.TextArea;

export const PartneroComponent = (props) => {
    const {
        itemCssClass = 'integration-item',
        integration,
        loading,
        loadingIntegration,
        isOpen,
        error,
        onSetOpen,
        onCancel,
        onOk
    } = props || {};

    const programIdRef = useRef();
    const apiKeyRef = useRef();
    const { partneroProgramId = '', partneroApiKey = '' } = integration || {};
    const isActive = useMemo(() => !!partneroProgramId && !!partneroApiKey, [partneroProgramId, partneroApiKey])

    // TODO: Need translations
    return (
        <div className={itemCssClass}>
            <div className='integration-item__header'>

                <h3>
                    <img className='margin_right'
                        src="https://app.partnero.com/images/integrations/partnero.png" alt="Partnero Icon" width="30px" />
                    Partnero Universal
                </h3>
                <Button type="secondary"
                    size='small'
                    onClick={onSetOpen}>
                    Instructions
                </Button>
            </div>
            {isActive && (
                <div >
                    <hr/>
                    ✅ Integration is active</div>
            )}
            <Modal title="Partnero integration"
                visible={isOpen}
                onOk={() => onOk(
                    programIdRef?.current?.input?.value,
                    apiKeyRef?.current?.resizableTextArea?.textArea?.value
                )}
                destroyOnClose={true}
                onCancel={onCancel}>
                <div className="ant-form-item">
                    <div className="ant-col ant-form-item-label">
                        <label htmlFor="partnero-program-id"
                            className="ant-form-item-required">
                            Partnero program ID
                        </label>
                    </div>
                    <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content">
                                <Input id="partnero-program-id"
                                    ref={programIdRef}
                                    defaultValue={partneroProgramId} />
                            </div>
                        </div>
                        <div className="ant-form-item-extra">
                            You can find the ID of your program in the Partnero initial script
                        </div>
                        <Image width={80} src={IMG} />
                    </div>
                </div>
                <div className="ant-form-item">
                    <div className="ant-col ant-form-item-label">
                        <label htmlFor="partnero-api-key"
                            className="ant-form-item-required">
                            Partnero API key
                        </label>
                    </div>
                    <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content">
                                <Textarea id="partnero-api-key"
                                    ref={apiKeyRef}
                                    defaultValue={partneroApiKey} />
                            </div>
                        </div>
                        <div className="ant-form-item-extra">
                            You need to create an API key in the Partnero program settings.
                        </div>
                    </div>
                </div>
                {error && (
                    <div className="error">{error}</div>
                )}
                {loading && <LoaderComponent type="absolute" />}
            </Modal>
            {loadingIntegration && <LoaderComponent type="absolute" />}
        </div>
    )
}