import './../scss/index.scss';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { noop } from "lodash";
import { useHistory } from 'react-router-dom';

import { Svg, PaidComponent } from '../';
import { openNotification } from '../../notification/Notification.component';
import { useIsAllow, } from '../hooks';
import { AudioPlayerButton } from '../../audio/AudioPlayerButton/AudioPlayerButton';
import { VideoCustomPlayerContent } from './VideoCustomPlayerContent';
import { SPEC_SUBJECTSID } from "../../constant";
import { PuppeteerContainer } from '../../puppeteer';
// const URLPLAYER = "http://localhost:3000/embed/video-preview/"
const URLPLAYER = "https://unschooler.me/embed/video-preview/"

export const VideoCustomPlayer = ({
    currentSlideIndexProp,
    setCurrentSlideIndexProp,
    chunks,
    setSpec = noop,
    setIsEditMode,
    isEditMode,
    userIsAllowGenerateContent,
    id,
    workspace,
    task,
    ...other
}) => {

    const { formatMessage } = useIntl();
    const audioRef = useRef(null);
    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const [isPlay, setIsPlay] = useState(false);
    const [light, setLight] = useState(false);
    const [isDiagram, setIsDiagram] = useState(false);
    const [errors, setErrors] = useState()
    const [currentSlideIndex, setCurrentSlideIndex] = useState(currentSlideIndexProp);
    const currentSlide = chunks?.length && chunks[currentSlideIndex];
    const [currentVisual, setCurrentVisual] = useState(null);
    const [visualIndex, setVisualIndex] = useState(0);
    const [mode, setMode] = useState('black');
    const [imageError, setImageError] = useState(false);
    const [isMobile, setIsMobile] = useState(queryParams.get('mobile') || '');

    const handleModeSelect = (mode, index) => {
        setMode(mode);
        setSpec(SPEC_SUBJECTSID[index]);
    }

    const visualOptions = useMemo(() => {
        let options = [];
        if (currentSlide?.ill) {
            options.push("illustration");
        }
        if (currentSlide?.diagram && !errors) {
            options.push("diagram");
        }

        if (currentSlide?.images?.length && currentSlide?.images?.[0]?.url) {
            if (currentSlide?.images?.[0]?.url.endsWith('.mp4')) {
                options.push("video");
            } else {
                options.push("image");
            }
        }
        if (currentSlide?.example) {
            options.push("text");
        }

        return options;
    }, [currentSlideIndex, errors, chunks]);

    useEffect(() => {
        if (!errors) {
            setVisualIndex(0);
        }
    }, [currentSlideIndex, errors]);


    useEffect(() => {
        setCurrentVisual(visualOptions[visualIndex]);
    }, [visualIndex, visualOptions]);

    const blockClasses = classnames('slides-right-panel slides-right-panel_imgs', {
        'slides-right-panel_img-centered': currentVisual === "images"
    });
    const videoClasses = classnames('videoContainerStyle video_preview', {
        light: light === true,
        dark: !light,
    });
    const leftColumnClasses = classnames('slides-content', {
        "slides-left-panel_wide": currentVisual === 'diagram',
    });

    useEffect(() => {
        if (mode === "simple" || mode === "tufte") {
            setLight(true);
        }
        if (mode === "black") {
            setLight(false);
        }

    }, [mode])
    const handlePlayPause = (audioRef) => {
        if (isPlay) {
            audioRef.current?.pause();
            setIsPlay(false);

        } else {
            const track = audioRef.current?.textTracks[0];
            audioRef.current?.play();
            setIsPlay(true);

            if (track) {
                track.mode = 'hidden';
                const updateSubtitles = () => {
                    const activeCues = track.activeCues;

                    const subtitleDisplay = document.getElementById('subtitle-display');
                    if (activeCues.length > 0) {
                        subtitleDisplay.innerText = activeCues[0].text;
                    } else {
                        subtitleDisplay.innerText = '';
                    }
                };
                track.addEventListener('cuechange', updateSubtitles);
                return () => {
                    track.removeEventListener('cuechange', updateSubtitles);
                };
            }
        }
    };
    useEffect(() => {
        // if (audioRef.current) {
        // handlePlayPause(audioRef);
        // }
        setCurrentSlideIndexProp(currentSlideIndex);
        setErrors(null);
        setImageError(null)
    }, [currentSlideIndex, isPlay]);

    useEffect(() => {
        if (currentSlideIndexProp !== currentSlideIndex) {
            setCurrentSlideIndex(currentSlideIndexProp)
        }
    }, [currentSlideIndexProp])

    useEffect(() => {
        (async () => {
            if (currentVisual === "diagram") {
                await setIsDiagram(false);
                setIsDiagram(true);
            }

        })();
    }, [currentVisual, currentSlideIndex])

    const modeClasses = classnames('', {
        simple: mode === "simple",
        tufte: mode === "tufte",
    });

    const sizeClasses = classnames('', {
        mobile: isMobile === true || isMobile === "true",
    });

    const mainIdeaNormalised = (main_idea) => {
        if (Array.isArray(main_idea)) {
            return main_idea;
        } else if (typeof main_idea === 'string') {
            return main_idea.split(',');
        }
    }

    const userIsAllowd = useIsAllow();
    const isAllowCreator = userIsAllowd("CREATOR", workspace, task?.creator?.id);
    let wtmrk = workspace ? workspace?.logo : ''
    // let wtmrk = workspace ? workspace?.logo : !isAllowCreator ? 'https://cdn.unschooler.me/web_app/tech_pictures/logowt.png' : ''
    let url = `${URLPLAYER}${id}?hideButtons=true&mode=${mode}&mobile=${isMobile}&wmrk=${wtmrk}`;
    let width = isMobile ? 780 : 1920;
    let height = isMobile ? 1680 : 1080;

    const onNextSlide = (delay = 0) => {
        if (!isEditMode) {
            setTimeout(() => {
                if (currentSlideIndex === chunks.length - 1) {
                    setIsPlay(false);
                } else {
                    setCurrentSlideIndex(currentSlideIndex + 1);
                }
            }, delay);
        }
    }

    return (
        <div className={sizeClasses}>
            {isEditMode &&
                <div className='video_header wrapper_inner-section'>
                    <div>
                        <Button type="text" size="small" className="margin_right" disabled={mode === 'black'} onClick={() => handleModeSelect('black', 3)} >{formatMessage({ id: 'animated_button' })}</Button>
                        <Button type="text" size="small" className="margin_right" disabled={mode === 'simple'} onClick={() => handleModeSelect('simple', 0)} >{formatMessage({ id: 'white_button' })}</Button>
                        <Button type="text" size="small" className="margin_right" disabled={mode === 'tufte'} onClick={() => handleModeSelect('tufte', 4)} >{formatMessage({ id: 'clean_button' })}</Button>
                    </div>
                    <div>
                        <Button type="text" className="margin_right" size="small" onClick={() => setIsMobile((prev) => !prev)} >
                            <span className="mobile_icon" />
                            {isMobile ? "Desktop" : formatMessage({ id: 'mobile_button' })}
                        </Button>

                        <CopyToClipboard
                            text={url}
                            onCopy={() => {
                                openNotification('topLeft', {
                                    title: formatMessage({
                                        id: 'notif_copy_link_title',
                                    }),
                                    description: formatMessage({ id: 'notif_copy_event_link_workspace', }),
                                    icon: '💬',
                                    duration: 3,
                                });
                            }}
                        >
                            <Button type='text'>
                                <Svg name=':icon/share' width={24} />
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
            }

            <div className={modeClasses}>
                <div className={videoClasses} >
                    {(userIsAllowGenerateContent && !isEditMode) &&
                        <div className='edit_video'>
                            <Button
                                size='small'
                                type='text'
                                onClick={() => setIsEditMode(true)}
                            >
                                <Svg name=':icon/edit' width={24} />
                                <span>
                                    {formatMessage({ id: 'edit' })}
                                </span>

                            </Button>
                        </div>
                    }
                    {!isPlay ?
                        <button onClick={() => handlePlayPause(audioRef)} className="play-button">
                            <Svg name=':icon/play' width={40} />
                        </button>
                        :
                        <button onClick={() => handlePlayPause(audioRef)} className="pause-button">
                            <Svg name=':icon/pause' width={30} />
                        </button>
                    }

                    <div className='sliderContainerStyle'>
                        <VideoCustomPlayerContent
                            currentSlide={currentSlide}
                            currentVisual={currentVisual}
                            isDiagram={isDiagram}
                            errors={errors}
                            setErrors={setErrors}
                            imageError={imageError}
                            setImageError={setImageError}
                            currentSlideIndex={currentSlideIndex}
                            leftColumnClasses={leftColumnClasses}
                            blockClasses={blockClasses}
                            mainIdeaNormalised={mainIdeaNormalised}
                            wmrk={wtmrk}
                            {...other}
                        />

                        <div className=' slides-toolbar'>
                            {currentSlide?.audio && (
                                <AudioPlayerButton
                                    audioRef={audioRef}
                                    isPlay={isPlay}
                                    currentSlide={currentSlide}
                                    onNextSlide={onNextSlide}
                                    setIsPlay={setIsPlay}
                                    formatMessage={formatMessage}
                                    handlePlayPause={handlePlayPause}
                                />
                            )}
                            {!currentSlide?.audio && <>
                                {onNextSlide(3000)}
                            </>}
                            <div className="slider-navigation">
                                {chunks?.map((item, index) => (
                                    <Button
                                        key={`button_${index}`}
                                        size="small"
                                        type="text"
                                        className={index === currentSlideIndex ? 'slider-navigation_btn slider-navigation_active' : 'slider-navigation_btn '}
                                        onClick={() => setCurrentSlideIndex(index)}
                                    >
                                        <hr />
                                    </Button>
                                ))}
                            </div>
                            <div>
                                <Button type="text" disabled={currentSlideIndex === 0} onClick={() => setCurrentSlideIndex(currentSlideIndex === 0 ? 0 : currentSlideIndex - 1)}>  <Svg name=':icon/arrow-left' fill="#FFFFFF" width={24} /></Button>
                                <Button type="text" disabled={currentSlideIndex === chunks.length - 1} onClick={() => setCurrentSlideIndex(currentSlideIndex === chunks.length - 1 ? 0 : currentSlideIndex + 1)}>  <Svg name=':icon/arrow-right' fill="#FFFFFF" width={24} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(isEditMode && isAllowCreator) &&
                <div>
                    <hr />
                    <PuppeteerContainer
                        taskId={id}
                        url={url}
                        chunksData={chunks}
                        width={width}
                        height={height}
                        mode={mode}
                        mobile={isMobile}
                        workspace={workspace}
                        isAllowCreator={isAllowCreator}
                        {...other}
                    />
                </div>
            }

        </div>

    );
};