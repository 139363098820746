
import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Input, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useGetVideoCaptions } from '../../youtube';
import { youtubeParser, LoaderComponent } from '../../common';

export const AddYouTubeLink = ({
    onAddSource,
    index,
    setInProgress,
    inProgress,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState('');
    const [urlName, setUrlName] = useState('');
    const { getVideoCaptions } = useGetVideoCaptions();

    const onChange = async () => {
        setInProgress(true);
        const val = inputRef.current.input.value;
        const resp = await getVideoCaptions({
            variables: {
                id: val,
            },
        });

        let respCaptions = resp?.data?.getVideoCaptions?.captions;
        if (respCaptions) {
            const sourceText = respCaptions?.map((el) => el?.text)?.join(' ');
            setUrlValue(sourceText?.substring(0, 40000))
            setUrlName(youtubeParser(val))
        }
        setInProgress(false);
    }
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }

    let object = { key: "youtube", type: 'YOUTUBE', source: urlName || '', title: `My YouTube video`, raw: urlValue || '', icon: "video" }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'form_video_placeholder' })}
                onCancel={() => onSave()}
                onOk={() => onSave()}
                okButtonProps={{ disabled: !urlValue }}
                confirmLoading={inProgress} // This will disable the OK button when inProgress is true
            >
                {inProgress && <LoaderComponent type="absolute" />}
                <span>
                    {formatMessage({ id: "paste_link" })}
                </span>
                <Input
                    onChange={onChange}
                    ref={inputRef}
                    placeholder="https://www.youtube.com/watch?v=FCqkUi1-7S0"
                    className="inline-el wrapper_inner-section"
                />

                {/* {inputRef.current.input.value && (
                    <VideoPlayer
                        inProgress={inProgress}
                        user={user}
                        showCaptions={false}
                        videoSrc={youtubeParser(value)}
                        sayMeCaptions={setCaptions}
                        {...other}
                    />
                )} */}
            </Modal>
        </>
    );
};
