import React, { useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Input, Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoaderComponent } from '../../common';
import { openNotification } from '../../notification/Notification.component';

export const ResendComponent = (props) => {
    const { resend = {}, isDisabled, customDomain, loading, error, verifyLoading, onAddDomain, onVerifyDomain } = props;
    const { formatMessage } = useIntl();
    const { domain: domainData, email } = resend || {};
    const { id, name, status, records: recordsJSON } = domainData || {};
    const {showDomainButton, records} = useMemo(() => ({
        showDomainButton: !recordsJSON,
        ...(!!recordsJSON && {records: JSON.parse(recordsJSON)})
    }), [recordsJSON]);
    const isVerified = useMemo(() => status === 'verified', [status]);

    const domainRef = useRef();
    const emailRef = useRef();
    const [domain, setDomain] = useState(name);

    const { firstLevelDomain, domainBase, emailDomainBase, emailExample, isWrongDomain } = useMemo(() => {
        const source = domain || customDomain;

        if (!source) {
            return {
                domainBase: '',
                emailDomainBase: '',
                emailExample: '',
                isWrongDomain: false
            }
        }

        let domainBase;
        let isWrongDomain = false;
        const arr = source.split('.');
        const count = arr.length;
        if (count === 2) {
            domainBase = source;
        } else {
            domainBase = `${arr[count - 2]}.${arr[count - 1]}`;
        }

        if (domain) {
            isWrongDomain = !domain.match(/^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.){1,2}[a-zA-Z]{2,}$/g);
        }

        return {
            firstLevelDomain: domainBase,
            domainBase: source,
            emailDomainBase: '@' + source,
            emailExample: 'info@' + source,
            isWrongDomain
        }
    }, [domain, customDomain]);

    const [emailState, setEmailState] = useState(emailExample);
    const isDisabledButton = useMemo(() => {
        if (isDisabled || loading) {
            return true;
        }

        const rx = /\S+@\S+\.\S+/;
        const val = emailRef?.current?.input?.value;
        return !(rx.test(emailState) && emailState.endsWith(emailDomainBase));
    }, [emailState, emailDomainBase, isDisabled, loading])

    const onDomainAdd = () => onAddDomain(domainRef.current.input.value.trim(), emailState);

    const handleChangeEmail = () => {
        setEmailState(emailRef.current.input.value);
    };

    const handleChangeDomain = () => {
        setDomain(domainRef.current.input.value);
    };

    const getStatus = (status) => {
        let icon;

        switch (status) {
            case 'not_started': {
                icon = '🔁';
                break;
            }
            case 'pending': {
                icon = ' ⌛';
                break;
            }
            case 'verified': {
                icon = ' ✅';
                break;
            }
            case 'failed': {
                icon = ' ❌';
                break;
            }
            default: {
                icon = '🔁';
            }
        }
        return icon;
    }
    // TODO: Need translaions
    return (
        <div className="resend-component">
            <hr />
            <h2>{formatMessage({ id: 'email_settings_title' })}</h2>
            <p className='wrapper_inner-section'>{formatMessage({ id: 'email_settings_subtitle' })}</p>
            {!isDisabled && (
                <div className="">
                    <div className="wrapper_content-section">
                        <h3>
                            {formatMessage({ id: 'domain_title' })}
                        </h3>
                        <p className='ant-form-item-extra'>
                            Specify your domain or subdomain for email sending</p>
                        <Input ref={domainRef}

                            type="text"
                            defaultValue={domainBase}
                            readOnly={!!domainData}
                            disabled={isDisabled}
                            onChange={handleChangeDomain}
                            style={{
                                width: '270px'
                            }}
                        />
                    </div>
                    <div className='wrapper_content-section'>
                        <div className="add-subaccount-component__field">
                            <h3>Email</h3>
                            <ul className="ant-form-item-extra">
                                <li>Domain: <strong>example.com</strong> - Email: <strong>info@example.com</strong></li>
                                <li>Subdomain: <strong>academy.example.com</strong> - Email: <strong>info@academy.example.com</strong></li>
                            </ul>
                            <Input
                                ref={emailRef}
                                type="email"
                                defaultValue={email || emailExample}
                                placeholder={emailExample}
                                readOnly={!!email}
                                disabled={isDisabled}
                                onChange={handleChangeEmail}
                                style={{
                                    width: '270px'
                                }}
                            />
                        </div>
                    </div>
                    {showDomainButton && (
                        <Button
                            type="tertiary"
                            htmlType="button"
                            onClick={onDomainAdd}>
                            <span>
                                {loading && <LoaderComponent type="inline" />}
                                Set domain
                            </span>
                        </Button>

                    )}
                    {records && (
                        <div>
                            <hr />
                            <h3>DNS records</h3>
                            {!isVerified && (
                                <p className='ant-form-item-extra'>Add DNS records for your domain to send emails from it.</p>
                            )}
                            <table>
                                <thead>
                                    <tr>
                                        <th>{formatMessage({ id: 'type_column' })}</th>
                                        <th>{formatMessage({ id: 'name_column' })}</th>
                                        <th>{formatMessage({ id: 'value_column' })} / content</th>
                                        <th>Priority</th>
                                        <th>{formatMessage({ id: 'verification_column' })}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records?.map((rec, i) => (
                                        <tr key={`${rec?.record}_${i}`}>
                                            <td>
                                                {rec?.type}
                                            </td>
                                            <td>
                                                <CopyToClipboard
                                                    text={rec?.name}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='secondary' size="small" className='btn-record btn-left'>
                                                        {rec?.name}
                                                    </Button>
                                                </CopyToClipboard>
                                            </td>
                                            <td>
                                                <CopyToClipboard
                                                    text={rec?.value}
                                                    onCopy={() => {
                                                        openNotification('topLeft', {
                                                            title: formatMessage({
                                                                id: 'notif_copy_link_title',
                                                            }),
                                                            icon: '💬',
                                                            duration: 3,
                                                        });
                                                    }}
                                                >
                                                    <Button type='secondary'
                                                        size="small"
                                                        className='btn-record btn-left btn-overflow-text'>
                                                        {rec?.value}
                                                    </Button>
                                                </CopyToClipboard>
                                            </td>
                                            <td align="center">
                                                {rec?.priority || '-'}
                                            </td>
                                            <td align="center">
                                                {getStatus(rec?.status)}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            TXT
                                        </td>
                                        <td>
                                            <CopyToClipboard
                                                text="_dmarc"
                                                onCopy={() => {
                                                    openNotification('topLeft', {
                                                        title: formatMessage({
                                                            id: 'notif_copy_link_title',
                                                        }),
                                                        icon: '💬',
                                                        duration: 3,
                                                    });
                                                }}
                                            >
                                                <Button type='secondary'
                                                    size="small"
                                                    className='btn-record btn-left'>
                                                    _dmarc
                                                </Button>
                                            </CopyToClipboard>
                                        </td>
                                        <td>
                                            <CopyToClipboard
                                                text="v=DMARC1; p=none;"
                                                onCopy={() => {
                                                    openNotification('topLeft', {
                                                        title: formatMessage({
                                                            id: 'notif_copy_link_title',
                                                        }),
                                                        icon: '💬',
                                                        duration: 3,
                                                    });
                                                }}
                                            >
                                                <Button type='secondary'
                                                    size="small"
                                                    className='btn-record btn-left'>
                                                    v=DMARC1; p=none;
                                                </Button>
                                            </CopyToClipboard>
                                        </td>
                                        <td align="center">
                                            -
                                        </td>
                                        <td align="center">
                                            <a href="https://resend.com/docs/dashboard/domains/dmarc" target="_blank"
                                                rel="noreferrer">
                                                DMARC
                                            </a>, Optional
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {!isVerified && (
                                <div>
                                    <hr />
                                    <Button type="secondary"
                                        className="mb-m-l"
                                        onClick={() => onVerifyDomain(id)}
                                        disabled={verifyLoading}>
                                        <span>
                                            {verifyLoading && <LoaderComponent type="inline" />}
                                            {status === 'not_started' ?
                                                "Repeat verification"
                                                :
                                                formatMessage({ id: 'verify_button' })
                                            }
                                        </span>
                                    </Button>
                                    <p>Updating and verifying DNS records may take from 5 minutes to 24 hours.</p>
                                </div>
                            )}
                            <hr />
                            Email domain status: {getStatus(status)}
                        </div>
                    )}

                    {isWrongDomain && (
                        <div className="error">
                            {formatMessage({ id: 'domain_error' })}
                        </div>
                    )}
                    {error && (
                        <div className="error">
                            Something went wrong. Please try again.
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}