import './scss/index.scss'
import React from 'react';
import { Button, Checkbox, Spin } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useLocalePrefix } from '../../lang';
import { EventListItemComponent } from '../../event/EventListItem.component/EventListItem.component';

export const MoveCoursesToWorkspaceComponent = (props) => {
    const {
        workspace,
        user,
        history,
        selectedEvents,
        getEventList,
        toggleEventSelection,
        events,
        loading,
        handleAddAll,
        setEvents,
        fetchMoreEvents,

        ...other } = props;
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();

    const getlink = (event) => {
        return `https://unschooler.me${localePrefix}/online-courses/${event.alias}-${event.id}`;
    }

    return (
        <div className='event-sector'>
            <Spin spinning={loading}>
                {!!selectedEvents?.length ?
                    <>

                        <Button
                            type="primary"
                            className='sticked_btn'
                            block={true}
                            onClick={() => { handleAddAll() }}
                        > {formatMessage({ id: 'add_courses_btn' })}
                        </Button>
                    </>
                    :
                    <h2>{formatMessage({ id: 'add_courses_container' })}</h2>
                }

                <div className='events-list_list '>
                    {!events?.length &&
                        <Button
                            type="secondary"
                            block={true}
                            onClick={() => {
                                getEventList()}}
                        >
                            {formatMessage({ id: 'add_courses_btn' })}
                        </Button>
                    }
                    {!!events?.length &&
                        events.map((event, i) => (
                            !!event ? (
                                <div key={event?.id} className='event-with-checkbox' >
                                    <Checkbox
                                        onChange={() => toggleEventSelection(event.id)}
                                        checked={selectedEvents.includes(event.id)}
                                        className={selectedEvents.includes(event.id) ? 'selected-event-checkbox' : 'event-checkbox'}
                                    />
                                    <EventListItemComponent event={event} toggleEventSelection={toggleEventSelection} />
                                    <a href={getlink(event)} target='_blank' rel="noreferrer">
                                        <ExportOutlined className='btn_link' />
                                    </a>
                                </div>
                            ) : null
                        ))}
                    {!!events?.length &&
                        <Button
                            type="text"
                            block={true}
                            onClick={() => { fetchMoreEvents() }}
                        >
                            {formatMessage({ id: 'show_more' })}
                        </Button>
                    }
                </div>
            </Spin>
        </div>
    )
};

