import "./scss/index.scss";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, Input, Space, Select, Switch, Radio } from 'antd';
import { AddMp4 } from '../../ai/AICourseObjectives.component/AddMp4';

import {
    VideoIframe,
    VimeoPlayer,
} from '../../common';
import { VideoGeneratorButton } from '../../common/VideoIframe/VideoGeneratorButton';
const { TextArea } = Input;

export const VideoFormItem = ({
    formTask,
    coverType,
    nameVideo,
    user,
    onChange,
    form,
    ...other
}) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <div className="video_link" >
                <Form.Item name={nameVideo} >
                    <Input
                        autoComplete='off'
                        placeholder={formatMessage({ id: 'form_video_placeholder' })}

                    />
                </Form.Item>
                {/* <Button type="secondary" className="inline_el" onClick={() => sethowMp4(true)}> {formatMessage({ id: 'click_upload' })}</Button> */}
            </div>
        

            {formTask?.video && (
                <>
                    {coverType === 'YOUTUBE' &&
                        <VideoIframe
                            videoSrc={formTask?.video}
                            timestampsList={
                                formTask?.timestamps
                                    ? formTask?.timestamps
                                    : null
                            }
                        />
                    }
                    {coverType === 'VIMEO' &&
                        <VimeoPlayer
                            {...other}
                            videoSrc={formTask?.video}
                            form={form}
                        />
                    }
                    {coverType === 'VIDEO' &&
                        <video width="100%" controls>
                            <source src={formTask?.video} />
                        </video>
                    }

                    <Form.Item name='coverSummary'>
                        <TextArea
                            autoComplete='off'
                            placeholder={formatMessage({ id: 'form_text_videosum_placeholder' })}
                            disabled
                            className='visually-hidden'
                            autoSize={{
                                minRows: 1,
                                maxRows: 8,
                            }}
                        />
                    </Form.Item>

                    {/* <Form.List
                        name='timestamps'
                        rules={[
                            { required: false, },
                        ]}
                    >
                        {(
                            timestamps,
                            { add, remove }
                        ) => (
                            <div className='timestamps-container'>
                                {timestamps.map(
                                    (field, i) => (
                                        <Space
                                            key={field.key + i}
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                            }}
                                            align='baseline'
                                        >
                                            <Form.Item
                                                {...field}
                                                name={[
                                                    field.name,
                                                    'timestamp',
                                                ]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'timestamp',
                                                ]}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message:
                                                            formatMessage(
                                                                {
                                                                    id: 'form_field_is_required',
                                                                }
                                                            ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    autoFocus={
                                                        true
                                                    }
                                                    maxLength='8'
                                                    placeholder='0:56'
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[
                                                    field.name,
                                                    'title',
                                                ]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'title',
                                                ]}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message:
                                                            formatMessage(
                                                                {
                                                                    id: 'form_field_is_required',
                                                                }
                                                            ),
                                                    },
                                                ]}
                                                initialValue=''
                                            >
                                                <Input
                                                    autoComplete='off'
                                                    placeholder='1-3 words title'
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                        onChange();
                                                    }}
                                                />
                                            </Form.Item>
                                        </Space>
                                    )
                                )}
                                <Form.Item>
                                    <Button
                                        onClick={() =>
                                            add()
                                        }
                                        type='secondary'
                                        block
                                    icon={
                                        <PlusOutlined />
                                    }
                                    >
                                        {formatMessage({
                                            id: 'form_add_timestamp',
                                        })}
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List> */}
                </>
            )
            }
            {(coverType === 'VIDEO' && !formTask?.video?.length) &&
                <VideoGeneratorButton
                    task={formTask}
                    user={user}
                    form={form}
                    userIsAllowGenerateContent={true}
                    // setInProgress={setInProgress}
                    {...other}
                />
            }

        </>

    );
};
