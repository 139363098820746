import './scss/index.scss';
import React from "react";
import { Form, Input, Button} from 'antd';
import { useIntl } from 'react-intl';

import { SignInComponent } from '../../common';
import { SourceSelector } from './SourceSelector';
const { TextArea } = Input;

export const AICourseObjectivesComponent = ({
    form,
    height,
    handleChange,
    handleFocus,
    handleGetCurriculum,
    value,
    placeholder,
    event,
    sourceList,
    workspace,
    user,
    ...other
}) => {
    const { formatMessage } = useIntl();
    return (
        <Form onFinish={value && handleGetCurriculum} form={form} layout="vertical">
            <h4 className='wrapper_inner-section'>{formatMessage({ id: "form_text_course_title" })}</h4>

            <Form.Item
                name="ask"
                onChange={handleChange}
                onKeyDown={handleFocus}
                rules={[{ required: true, message: "Please, enter the topic or learning goal" }]}>
                <div className="ai-component__form">
                    <div className="ai-bot-component__wrapper">
                        <TextArea
                            placeholder={
                                placeholder ||
                                formatMessage({ id: "ask_placeholder_def" })
                            }
                            className="wrapper_inner-section"
                            value={value}
                            style={height && { minHeight: height }}
                            autoSize={{
                                minRows: 6,
                                maxRows: 16,
                            }}
                        />
                    </div>
                </div>
            </Form.Item>

            <div className='wrapper_content-section'>
                <SourceSelector
                    sourceList={sourceList}
                    user={user}
                    workspace={workspace}
                    {...other}
                />
            </div>

            {user?.id ?
                <Button
                    htmlType="submit"
                    type={event?.title ? "secondary" : "tertiary"}
                    block
                // disabled={!user?.id ? true : false}
                >
                    {event?.title
                        ? formatMessage({
                            id: "curriculim_regenerate",
                        })
                        : formatMessage(
                            { id: "curriculim_generate" },
                            formatMessage({ id: "course" })
                        )}
                </Button> :
                <SignInComponent
                    type='tertiary'
                    block='true'
                    title=" Try for free"
                    isSighUp={true}
                />
            }
        </Form>
    );
};
