// import './scss/index.scss';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MinusCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { ROLES } from '../../constant';
import { openNotification } from '../../notification/Notification.component';
import { Svg, EmailPreviewComponent } from '../../common';
import { useSendEmail, useHtmlEmail } from '../../resend';
import { useLocalePrefix } from '../../lang';
import { useInvitesToCourse } from '../hooks';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { EventsListSelectComponent } from '../../events/EventsListSelect.component/EventsListSelect.component';

import { noop } from 'lodash';
const { Option } = Select;
const { TextArea } = Input;

export const InvitesFormCourseContainer = (props) => {
    const {
        event,
        setEvent = noop,
        emails,
        onGetEvent = noop,
        ...other
    } = props;

    const { user, workspace, lang } = useContext(FirebaseContext);
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invites, setInvites] = useState([]); //this is complex object
    const [inProgress, setInProgress] = useState();
    const [errors, setErrors] = useState();
    const [selectedEvent, setSelectedEvent] = useState(event || null);
    const [messageContent, setMessageContent] = useState('');

    useEffect(() => {
        const initialMemberList = emails?.map(email => ({ email, permission: 'STUDENT' })) || [{ email: '', permission: 'STUDENT' }];
        form.setFieldsValue({ memberList: initialMemberList });
        setInvites(initialMemberList);
    }, [emails]);

    const { createInvitesToCourse, loading } = useInvitesToCourse();
    const [error, setError] = useState();
    const { getHtmlInviteToCourseEmail } = useHtmlEmail();
    const { sendEmail } = useSendEmail();
    const [localePrefix] = useLocalePrefix();
    const typeInUrl = selectedEvent?.type === 'BUNDLE' ? 'bundles' : 'online-courses'

    const { id: workspaceId, title, host, customDomain, status } = workspace || {};
    const inviteUrl = customDomain && status
        ? `https://${customDomain}${localePrefix}/${typeInUrl}/${selectedEvent?.id}`
        : `https://${host ? host + "." : ''}unschooler.me${localePrefix}/${typeInUrl}/${selectedEvent?.id}`

    const emailHtml = getHtmlInviteToCourseEmail({
        ownerName: selectedEvent?.creator?.name || 'Mentor',
        schoolTitle: title,
        eventTitle: selectedEvent?.title,
        inviteUrl,
        content: messageContent
    });

    const handleSendInvites = async (data) => {
        const inviteErrors = [];
        data?.memberList?.forEach((item, index) => {
            const test = /^\S+@\S+\.\S+$/.test(item.email);
            inviteErrors.push(!test);
        });
        console.log('inviteErrors', inviteErrors);
        await setErrors(inviteErrors);
        if (inviteErrors.find((error) => error)) {
            return;
        }
        const invitedEmails = data?.memberList?.map((item) => item.email);
        setInProgress('Invites are sending')
        if (invitedEmails.length) {
            const invitesForCourse = data?.memberList
                .filter(member => member?.email)
                .map((member) => ({
                    invitedEmail: member.email,
                    role: member.permission,
                    type: 'FULL',
                }));

            const resp = await createInvitesToCourse({
                variables: {
                    input: {
                        eventId: selectedEvent?.id,
                        langId: lang?.id,
                        invites: invitesForCourse,
                        ...(workspaceId && { workspaceId })
                    }
                }
            });

            const newSubscriptions = resp?.data?.createInviteSubscriptions;
            if (newSubscriptions?.length) {
                const newInvites = workspace?.invites ? [...workspace?.invites] : [];
                newSubscriptions.forEach((subscription) => {
                    if (!newInvites?.find((invite) => invite?.email === subscription?.invitedEmail)) {
                        newInvites.push({ email: subscription?.invitedEmail, permission: 'User', name: '' })
                    }
                });

                // setEvent((prev) => ({
                //     ...prev,
                //     userSubscriptions: [
                //         ...prev?.userSubscriptions,
                //         ...newSubscriptions,
                //     ],
                //     countUserSubscriptions: prev.countUserSubscriptions + newSubscriptions?.length,
                //     isSubscribe: true,
                // }));

                const newInvitesEmails = newSubscriptions?.map((subscription) => subscription?.invitedEmail);
                const respond = await sendEmail(newInvitesEmails, emailHtml, `${formatMessage({ id: 'email_invitecourse_title' })}: ${selectedEvent.title}`);
                setInProgress();

                if (respond?.data) {
                    openNotification('topLeft', {
                        title: formatMessage({ id: 'notif_invite' }, newInvitesEmails.join(', ')),
                        description: `${formatMessage({ id: 'email_invitecourse_notif_desc' })} `,
                        icon: '💬',
                        duration: 10,
                    });
                    setInProgress('Invites are sending')
                    setInvites([])
                } else {
                    setError(respond?.errors?.[0]?.message || respond?.error?.message, 'respond.errors[0].message')
                }
            }
        }
        setInProgress();
        setIsModalVisible(false);
        onGetEvent();
    }

    const updateInvites = () => {
        let membersForm = form.getFieldsValue('memberList');
        if (!!membersForm.memberList?.length) {
            let invites = [];
            membersForm?.memberList?.forEach(member => {
                invites.push({ email: member.email, permission: member.permission });
            });
            setInvites(invites);
        }
    };
    const handleEventSelect = (event) => {
        setSelectedEvent(event);
    }
    const handleInputChange = (e, index) => {
        form.setFieldsValue({
            memberList: [
                ...form.getFieldValue('memberList').slice(0, index),
                {
                    ...form.getFieldValue('memberList')[index],
                    email: e.target.value
                },
                ...form.getFieldValue('memberList').slice(index + 1)
            ]
        });
        updateInvites();
    };

    const handleSelectChange = (value, index) => {
        form.setFieldsValue({
            memberList: [
                ...form.getFieldValue('memberList').slice(0, index),
                {
                    ...form.getFieldValue('memberList')[index],
                    permission: value
                },
                ...form.getFieldValue('memberList').slice(index + 1)
            ]
        });
        updateInvites();
    };

    console.log('errors', errors);

    return (
        <>
            <Button
                className='inline-el margin-right'
                type='secondary'
                size="small"
                onClick={() => {
                    setIsModalVisible(true);
                }}
            >
                <FontAwesomeIcon icon={faUserPlus} className='margin_right' />
                {formatMessage({ id: 'assign_btn' })}
            </Button>
            <Modal
                title={formatMessage({ id: 'assign_btn' })}
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setSelectedEvent(selectedEvent || null);
                }}
                footer={null}
            >
                <div className='wrapper_inner-section'>
                    {!selectedEvent &&
                        <EventsListSelectComponent
                            user={user}
                            workspace={workspace}
                            singleSelect={true}
                            onSelect={handleEventSelect}
                            {...other}
                        />
                    }
                    {selectedEvent ? (
                        <h3>
                            <Link to={`/online-courses/${selectedEvent.id}`}>
                                {selectedEvent.title}
                            </Link>
                        </h3>
                    ) :
                        <span className='error'>
                            {formatMessage({ id: 'no_course_selected' })}
                        </span>
                    }
                </div>

                <Form
                    onFinish={handleSendInvites}
                    form={form}
                    initialValues={{ memberList: invites }}
                >
                    <Form.List name="memberList">
                        {(fields, { add, remove }) => (
                            <div className='events-list-edit-component__list'>
                                {fields.map((field, index) => (
                                    <>
                                        <div
                                            key={index}
                                            className='edit-task-component__question__option__body'
                                        >
                                            <Form.Item
                                                key={field.key}
                                                validateTrigger={['onChange', 'onBlur']}
                                                className='grow_input'
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: formatMessage({ id: 'enter_title_warning' }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={formatMessage({ id: 'title_input_text' })}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={invites[index] ? invites[index].email : ''}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name={[index, "permission"]}
                                                valuePropName="checked"
                                                initialValue="STUDENT"
                                            >
                                                <Select
                                                    defaultValue="STUDENT"
                                                    onChange={(value) => handleSelectChange(value, index)}
                                                >
                                                    <Option value="STUDENT">{ROLES.find(role => role.permission === 'User')?.label}</Option>
                                                    <Option value="INSTRUCTOR">{ROLES.find(role => role.permission === 'Instructor')?.label}</Option>
                                                </Select>
                                            </Form.Item>

                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name);
                                                        setInvites(prevInvites => prevInvites.filter((_, i) => i !== field.name));
                                                    }}
                                                />
                                            ) : null}
                                        </div>

                                        {errors?.[index] && (
                                            <div className="error">{formatMessage({ id: 'valid_email_warning' })}</div>
                                        )}
                                    </>

                                ))}
                                {error && (
                                    <div className="error">
                                        error
                                    </div>
                                )}
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={() => add()}
                                        size="small"
                                    >
                                        <Svg
                                            fill='#ff5f49'
                                            name=':icon/add'
                                            className='btn-icon icon_16'
                                        />
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                    {!!invites?.length && invites[0]?.email &&
                        <>
                            <Form.Item
                                name="messageContent"
                                rules={[{ required: false, message: formatMessage({ id: 'enter_title_warning' }) }]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={formatMessage({ id: 'custom_message_placeholder' })}
                                    onChange={(e) => setMessageContent(e.target.value)}
                                />
                            </Form.Item>
                            <EmailPreviewComponent
                                list={invites?.map(invite => invite.email).join(', ')}
                                emailHtml={emailHtml}
                                workspace={workspace}
                                formatMessage={formatMessage}
                            />
                        </>
                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={!selectedEvent}>
                            {formatMessage({ id: 'assign_btn' })}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};
