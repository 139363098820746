/* eslint-disable react-hooks/exhaustive-deps */
import './scss/index.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, omitBy, noop } from 'lodash';
import { Button, Form, Input, Switch, Alert, Popconfirm } from 'antd';
import classnames from 'classnames';
import { SourceSelector } from '../../ai/AICourseObjectives.component/SourceSelector';

import {
    Svg,
    youtubeParser,
    TagSelectContainer,
    useGetTags,
    checkObjectIsEqual
} from '../../common';

import { InfoMessagesComponent } from '../../notification/InfoMessages.component';
import { Loader } from '../../Loader';
import { SortQuestionsComponent } from '../SortQuestions.component';
import { useEditTask } from '../hooks/useEditTask';
import { useDeleteTask } from '../hooks/useDeleteTask';
import { EditTaskDescription } from './EditTaskDescription';
import { EditTaskQuestion } from './EditTaskQuestion';
import { useReducedCaptions } from '../../ai/hook';
import { UploadAttachmentComponent } from '../UploadAttachment.component';

import { ITEMS_SOURCE_TASK } from '../../constant';

const { TextArea } = Input;

export const EditTaskLight = (props) => {
    const { formatMessage } = useIntl();
    const {
        user,
        activeQuestionId,
        activeTask,
        handleGetEvent,
        setActiveTask,
        event,
        setEvent,
        activeid,
        handleTaskView,
        onSaveProgress = noop,
        isChanged,
        setIsChanged,
        onUpdateEvent,
        isOwner,
        ...other
    } = props;
    const getTags = useGetTags();
    const { id: uId, role } = user || {};
    const { editTask } = useEditTask();
    const { deleteTask } = useDeleteTask();
    const isEditMode = useMemo(() => !!activeTask?.id, [activeTask?.id]);
    const [form] = Form.useForm();

    const [formTask, setFormTask] = useState(activeTask);
    const [question, setQuestion] = useState();
    const [coverType, setCoverType] = useState();
    const [coverImage, setCoverImage] = useState();
    const [questionsErrors, setQuestionsErrors] = useState();
    const [sortMode, setSortMode] = useState();
    const [isForceUpdate, setIsForceUpdate] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [sourceList, setSourceList] = useState([]);
    const [galleries, setGalleries] = useState();
    const formInitialValue = useMemo(() => formTask ? formTask : { isFree: false }, [formTask]);
    const getReducedCaptions = useReducedCaptions();
    const MAX = 30000;

    const getCoverType = (video) => {
        if (video) {
            if (video.includes('vimeo')) {
                return 'VIMEO';
            }
            if (video.includes('you')) {
                return 'YOUTUBE';
            } else {
                return 'VIDEO';
            }
        } else {
            return 'PHOTO';
        }

    }
    const getCover = useCallback(
        (formData) => {
            const { video, coverImage, timestamps: formTimestamps, coverSummary } = formData;
            let coverTypeM = getCoverType(video)

            const source = coverTypeM === 'YOUTUBE'
                ? youtubeParser(video)
                : coverTypeM === 'VIDEO' ? video
                    : coverTypeM === 'VIMEO' ? video
                        : coverImage;
            const fallBack = isEditMode ? { cover: null } : {};
            const timestampFormsData =
                !!formTimestamps &&
                formTimestamps.filter(
                    (item) => !!item?.title && !!item?.timestamp
                );
            const timestampsData =
                timestampFormsData && timestampFormsData.length
                    ? { timestamps: timestampFormsData }
                    : {};

            return source
                ? {
                    cover: {
                        type: coverTypeM,
                        id: formTask?.cover?.id || null,
                        summary: coverSummary || null,
                        source,
                        ...timestampsData,
                    },
                }
                : fallBack;
        },
        [coverType]
    );

    //change functions
    const handleSortQuestions = (data) => {
        form.setFieldsValue({ questions: data });
        setFormTask((prev) => ({ ...prev, questions: data }));
        setSortMode(false);
    };
    const onRemoveQuestion = async () => {
        const questions = form.getFieldValue('questions').map((q, i) => {
            q.order = i;
            return q;
        });

        handleSortQuestions(questions);
        await handleFormSubmitOnBlur();
        await onUpdateEvent();
    };
    const isValidQuestions = (questions) => {
        if (!questions?.length) {
            setQuestionsErrors(null);
            return true;
        }

        let isValid = true;
        const errors = {};

        questions?.forEach((q, i) => {
            if (q.type === 'DEFAULT') {
                return;
            }
            if (q.type !== 'TEXT' && !q.options) {
                errors[i] = formatMessage({ id: 'form_question_is_required' });
                isValid = false;
                return;
            }

            if (q.type === 'SINGLE_SELECT') {
                if (!q.options.find((q) => q?.isCorrect)) {
                    errors[i] = formatMessage({ id: 'form_one_is_right', });
                    isValid = false;
                }
            }

            if (q.type === 'MULTI_SELECT') {
                if (!q.options.find((q) => !!q?.isCorrect)) {
                    errors[i] = formatMessage({ id: 'form_min_one_is_right' });
                    isValid = false;
                }
            }
        });

        if (errors) {
            setQuestionsErrors(errors);
        }
        return isValid;
    };
    // const handleSetCoverImage = (data) => {
    //     const { response } = data?.[0] || {};
    //     if (response?.url) {
    //         form.setFieldsValue({ coverImage: response?.url });
    //         setCoverType('PHOTO');
    //     }
    //
    //     if (!data) {
    //         form.setFieldsValue({ coverImage: null });
    //         setCoverType(getCoverType(formTask?.video))
    //     }
    // };
    // const getCoverByLink = (video) => {
    //     const coverTypeQ = getCoverType(video);
    //     const source = coverTypeQ === 'YOUTUBE'
    //         ? youtubeParser(video)
    //         : coverTypeQ === 'VIDEO' ? video
    //             : coverTypeQ === 'VIMEO' ? video
    //                 : coverImage;
    //     const fallBack = isEditMode ? { cover: null } : {};
    //
    //     return source ? { cover: { type: coverTypeQ, source, timestamps: [], id: question.id }, } : fallBack;
    // };
    const handleRemoveImage = () => {
        form.setFieldsValue({ coverImage: null });
        setCoverImage(null);
    }
    //edit functions
    const handleFormSubmitOnBlur = async () => {
        const {
            questions,
            tagIds,
            isDraft,
            isFree,
            isAutogenerated,
            source,
            summary,
            coverSummary,
            video,
            ...taskData
        } = form.getFieldsValue();

        onSaveProgress(true);
        //save source

        if (sourceList?.length) {
            const promises = [];
            const summarisedSource = []
            const rawSource = []
            let ytpublic = null;
            sourceList?.forEach((el) => {
                if (el.key === 'youtube' || el.key === 'vimeo' || el.key === 'video') {
                    const videoPromise = new Promise(async (resolve, reject) => {
                        try {
                            const shortenedSummary = await getReducedCaptions(el.raw, '', MAX)
                            resolve({ ...el, summary: shortenedSummary });
                        } catch (error) {
                            reject(error)
                        }
                    });
                    promises.push(videoPromise);
                } else if (el.type === 'TEXT') {
                    const textPromise = new Promise(async (resolve, reject) => {
                        try {
                            const shortenedSummary = await getReducedCaptions(el.raw, '', MAX)
                            resolve({ ...el, summary: shortenedSummary });
                        } catch (error) {
                            reject(error)
                        }
                    });
                    promises.push(textPromise);

                } else if (el.key === 'yt_public') {
                    ytpublic = true
                }
            });
            const finalPromise = Promise.allSettled(promises);
            finalPromise.then(async (results) => {
                results?.forEach(res => {
                    summarisedSource.push(res?.value?.summary);
                    rawSource.push(res.value.raw);
                });

                //autosave
                if (activeTask && form.getFieldsValue()) {
                    if (!isValidQuestions(questions)) {
                        onSaveProgress(false);
                        return;
                    }
                    let coverData;
                    if ((!!video && video !== formTask.video) || (!video && !!formTask.video)) {
                        coverData = getCover(form.getFieldsValue());
                    }

                    const convertedTagIds = await getTags(tagIds || formTask.tags.map((tag) => tag?.id.toString()));
                    const convertedQuestions = {
                        questions: !questions?.length
                            ? []
                            : questions.map((q) => ({
                                ...q,
                                // video: undefined,
                                // ...getCoverByLink(q.video),
                                options:
                                    q.options &&
                                    q.options.map((o) => ({
                                        ...o,
                                        isCorrect: !!o.isCorrect,
                                    })),
                            })),
                    };
                    if (activeTask?.id) {
                        let taskFromForm = {
                            ...omitBy(taskData, isEmpty),
                            ...(coverData && { cover: coverData.cover }),
                            coverImage: undefined,
                            video: undefined,
                            timestamps: undefined,
                            isFree: !!isFree,
                            isDraft,
                            // TODO: add youtube
                            isAutogenerated: ytpublic ? true : isAutogenerated,
                            source: ((source || '') + rawSource?.join('\n'))?.substring(0, 30000),
                            summary: ((summary || '') + summarisedSource?.join('\n'))?.substring(0, 30000),
                            description: !!taskData.description
                                ? taskData.description
                                : null,
                            ...convertedQuestions,
                            tagIds: convertedTagIds,
                        }

                        let editedTask = await editTask({
                            variables: {
                                taskId: activeTask?.id,
                                taskData: taskFromForm,
                                isOwner: isOwner
                            },
                        });

                        if (editedTask) {
                            const eventCopy = { ...event };
                            const eventTasks = [...event.tasks];
                            const taskIndex = eventTasks.findIndex((t) => t.id === activeTask?.id);
                            eventTasks[taskIndex] = editedTask.data.editTask;
                            eventCopy.tasks = eventTasks;
                            setActiveTask(editedTask.data.editTask);
                        }
                    }

                }
                onSaveProgress(false);
                setIsChanged(false);
                // onUpdateEvent();
                setSourceList([])
            })

        } else {
            // let isEqualed = checkObjectIsEqual(form.getFieldsValue(), formTask);
            if (activeTask && form.getFieldsValue()) {
                // if (!isEqualed) {
                if (!isValidQuestions(questions)) {
                    onSaveProgress(false);
                    return;
                }
                let coverData;

                if ((!!video && video !== formTask.video) || (!video && !!formTask.video)) {
                    coverData = getCover(form.getFieldsValue());
                }
                const convertedTagIds = await getTags(tagIds || formTask.tags.map((tag) => tag?.id.toString()));
                const convertedQuestions = {
                    questions: !questions?.length
                        ? []
                        : questions.map((q) => ({
                            ...q,
                            // video: undefined,
                            // ...getCoverByLink(q.video),
                            options:
                                q.options &&
                                q.options.map((o) => ({
                                    ...o,
                                    isCorrect: !!o.isCorrect,
                                })),
                        })),
                };

                if (activeTask?.id) {
                    let taskFromForm = {
                        ...omitBy(taskData, isEmpty),
                        ...(coverData && { cover: coverData.cover }),

                        coverImage: undefined,
                        video: undefined,
                        timestamps: undefined,
                        isFree: !!isFree,
                        isDraft,
                        isAutogenerated: isAutogenerated,
                        source: source,
                        summary: summary,
                        description: !!taskData.description
                            ? taskData.description
                            : null,
                        ...convertedQuestions,
                        tagIds: convertedTagIds,
                        ...(galleries && { galleries })
                    }

                    let editedTask = await editTask({
                        variables: {
                            taskId: activeTask?.id,
                            taskData: taskFromForm,
                            isOwner: isOwner
                        },
                    });

                    if (editedTask) {
                        const eventCopy = { ...event };
                        const eventTasks = [...event.tasks];
                        const taskIndex = eventTasks.findIndex((t) => t.id === activeTask?.id);
                        eventTasks[taskIndex] = editedTask.data.editTask;
                        eventCopy.tasks = eventTasks;

                        // setEvent(eventCopy);
                        setActiveTask(editedTask.data.editTask);
                    }
                }
                // }
            }
            onSaveProgress(false);
            setIsChanged(false);
            onUpdateEvent();
        };
    }
    const handleChange = () => {
        setIsChanged(true);
    }
    const onChange = async () => {
        let questionsClone = [];
        let valuesNew = (values) => {
            values?.questions?.forEach((q) => {
                if (q.type === 'SINGLE_SELECT') {
                    if (!q.options?.length) {
                        q.options.push({
                            isCorrect: true,
                            order: 0,
                            text: "Next",
                        })
                    }
                }
                if (q.type === 'MULTI_SELECT') {
                    if (!q.options?.length) {
                        q.options.push({
                            isCorrect: true,
                            order: 0,
                            text: "New option",
                        })
                    }
                }
                questionsClone.push(q)
            });
            form.setFieldsValue({ questions: questionsClone });
            setCoverType(getCoverType(values.video));
            handleChange();
            return values;
        };

        // await handleFormSubmitOnBlur();
        setFormTask({ ...activeTask, ...valuesNew(form.getFieldsValue()), questions: questionsClone });
        handleChange();
    };
    const handleDelete = async () => {
        try {
            await deleteTask({ variables: { taskId: activeTask?.id } });
            await onUpdateEvent();
            const eventCopy = { ...event };
            const eventTasks = [...event.tasks];
            const taskIndex = eventTasks.findIndex((t) => t.id === activeTask?.id);
            eventTasks.splice(taskIndex, 1);
            eventCopy.tasks = eventTasks;
            // setEvent(eventCopy);
            setActiveTask(eventTasks[taskIndex + 1]);
        } catch (e) {
            console.warn(e);
        }
    };

    const handleLoadFile = (fileData) => {
        setGalleries((prev) => {
            const gal = !!prev ? [...prev] : [];
            const { url } = fileData || {};

            if (url) {
                const title = url.split('/').pop().slice(4).split('.').shift();
                gal.push({
                    title,
                    source: url,
                    type: 'FILE'
                });
            }
            return gal;
        });
    };

    const handleDeleteFile = (i) => {
        setGalleries((prev) => {
            const clone = [...prev];
            delete clone[i];
            handleChange();
            return clone.filter((c) => !!c.source);
        });
    };

    useEffect(() => {
        setQuestion(activeQuestionId ? activeTask?.questions?.find(q => q.id === activeQuestionId) : null)
        setFormTask(activeTask);
    }, [activeQuestionId, activeTask, activeTask?.id])

    useEffect(() => {
        if (activeTask) {
            // const {id: roleId } = role;
            const { id: roleId = 2 } = role || {};
            const { creatorId } = activeTask;
            if (creatorId === uId || roleId === 1) {
                (async () => {
                    const tagIds = activeTask?.tags?.map((tag) => tag.id.toString());
                    const { cover } = activeTask;
                    const taskCoverType = cover?.type;
                    setCoverType(taskCoverType);
                    const video =
                        cover && taskCoverType === 'YOUTUBE'
                            ? {
                                video: youtubeParser(cover?.source),
                                timestamps: cover?.timestamps,
                            }
                            : {
                                video: cover?.source,
                            };
                    if (cover && taskCoverType === "PHOTO") {
                        form.setFieldsValue({ coverImage: cover?.source, });
                        setCoverImage([
                            {
                                url: cover?.source,
                                thumbUrl: cover?.source,
                            },
                        ]);
                    }
                    if (cover?.summary) {
                        video.coverSummary = cover?.summary;
                    }

                    setFormTask({ ...activeTask, tagIds, ...video });
                })();
            }
        }
        setGalleries(activeTask?.galleries);
    }, [activeTask]);
    useEffect(() => {
        if (formInitialValue) {
            form.setFieldsValue(formInitialValue);
        }
    }, [formInitialValue, form]);

    useEffect(() => {
        if (formInitialValue?.id) {
            (async () => {
                await setIsForceUpdate(true);
                setIsForceUpdate(false);
            })();
        }
    }, [formInitialValue?.id, formInitialValue]);

    useEffect(() => {
        const videoObj = sourceList.find(obj => obj.type === "VIDEO");
        if (videoObj) {
            form.setFieldsValue({ video: videoObj.source });
        }
    }, [sourceList]);

    const getTabContentClassNames = (id) =>
        classnames('edit-task-component__tab-content', {
            'edit-task-component__tab-content_active': id === activeid,
        });
    const getClassNamesTabContent = (index) => classnames('edit-task-form-tab__content', {
        'edit-task-form-tab__content_active': activeTab === index
    });
    const getTabClassNames = (index) => classnames('edit-task-form-tab__tab', {
        'edit-task-form-tab__tab_active': activeTab === index
    });

    if (isForceUpdate || (isEditMode && activeTask && !formTask)) return <Loader />;
    return (
        <div className='edit-task-component'>
            {questionsErrors && Object.keys(questionsErrors).map((key) => {
                return (
                    <Alert
                        message={questionsErrors[key]}
                        key={key}
                        type="error"
                        showIcon
                        action={
                            <Button onClick={() =>
                                handleTaskView(activeTask, formTask?.questions?.[Number(key)]?.id)
                            } size="small" type="text">
                                Fix
                            </Button>
                        }
                    />
                )
            }
            )}

            <Form
                initialValues={formInitialValue}
                form={form}
                layout='vertical'
                name='basic'
                // onBlur={handleFormSubmitOnBlur}
                onInput={handleChange}
                requiredMark='hidden'
                scrollToFirstError={true}
            >
                <div className="edit-task-form__tabs">
                    <div className={getTabClassNames(0)}
                        onClick={() => setActiveTab(0)}>
                        {formatMessage({ id: "form_content_tab" })}
                    </div>
                    <div className={getTabClassNames(1)}
                        onClick={() => setActiveTab(1)}>
                        {formatMessage({ id: "form_display_tab" })}
                    </div>
                </div>

                <div className={getClassNamesTabContent(0)}>
                    <div className="edit-task-component__wrapper">
                        <div className={getTabContentClassNames(!question?.id ? activeTask?.id : null)}>
                            <h3>{formatMessage({ id: 'form_title_prompt' })}</h3>
                            <SourceSelector
                                setSourceList={setSourceList}
                                sourceList={sourceList}
                                itemsProp={ITEMS_SOURCE_TASK}
                                user={user}
                                isDisable={(!formTask?.source || formTask?.source?.length < 30000) ? false : true}
                                {...other}
                            />

                            <Form.Item name='source'>
                                <TextArea
                                    autoComplete='off'
                                    maxLength={30000}
                                    showCount
                                    placeholder={formatMessage({ id: 'form_text_data_placeholder' })}
                                />
                            </Form.Item>
                            {formTask?.summary &&
                                <div>
                                    <Form.Item name='summary' label={formatMessage({ id: 'form_text_learning', })}>
                                        <TextArea
                                            autoComplete='off'
                                            placeholder={formatMessage({ id: 'form_text_learning_placeholder' })}
                                            autoSize={{
                                                minRows: 2,
                                                maxRows: 3,
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            }

                            <hr />
                            <h3>{formatMessage({ id: 'form_workspace_content' })}</h3>
                            <EditTaskDescription
                                user={user}
                                // handleSetCoverImage={handleSetCoverImage}
                                handleRemoveImage={handleRemoveImage}
                                coverImage={coverImage}
                                formTask={formTask}
                                coverType={coverType}
                                onChange={onChange}
                                onChangeContent={handleChange}
                                form={form}
                                getCoverType={getCoverType}
                                formInitialValue={formInitialValue}
                                {...other}
                            />
                            <UploadAttachmentComponent galleries={galleries}
                                onLoadFile={handleLoadFile}
                                onDeleteFile={handleDeleteFile} />
                        </div>

                        <div className={getTabContentClassNames(question?.id)}>
                            <EditTaskQuestion
                                activeQuestion={question}
                                onRemoveQuestion={onRemoveQuestion}
                                questionsErrors={questionsErrors}
                                setSortMode={setSortMode}
                                user={user}
                                activeTask={activeTask}
                                formTask={formTask}
                                form={form}
                                onChange={onChange}
                                onChangeContent={handleChange}
                                remove={onRemoveQuestion}
                            />
                        </div>
                    </div>
                </div>

                <div className={getClassNamesTabContent(1)}>
                    <TagSelectContainer
                        initialTagsProp={activeTask?.tags}
                        title={activeTask?.title}
                        onChange={handleChange}
                        form={form}
                        label={formatMessage({ id: 'form_task_edit_add_tag_placeholder' })}
                    />

                    <div>
                    </div>
                    <Form.Item
                        name='isFree'
                        label={formatMessage({
                            id: 'form_is_free_label',
                        })}
                        className='edit-task-component__is-free'
                        valuePropName='checked'
                    >
                        <InfoMessagesComponent type='isFree' />
                        <Switch defaultChecked={formInitialValue?.isFree}
                            onChange={(flag) => {
                                form.setFieldsValue({ isFree: flag });
                                handleChange();
                            }} />
                    </Form.Item>
                    <Form.Item
                        name='isDraft'
                        label={formatMessage({
                            id: 'form_is_draft',
                        })}
                        className='edit-task-component__is-free'
                        valuePropName='checked'
                    >
                        <InfoMessagesComponent type='isDraft' />
                        <Switch defaultChecked={formInitialValue?.isDraft}
                            onChange={(flag) => {
                                form.setFieldsValue({ isDraft: flag });
                                handleChange();
                            }} />
                    </Form.Item>
                    <div className='wrapper_content-section'>
                        <Button
                            className=' block-el'
                            // size='small'
                            type='text'
                            onClick={() => {
                                const taskIndex = event.tasks.findIndex(t => t.id === formTask?.id);
                                if (taskIndex !== -1) {
                                    setEvent(p => ({
                                        ...p,
                                        tasks: [
                                            ...p.tasks.slice(0, taskIndex),
                                            ...p.tasks.slice(taskIndex + 1)
                                        ]
                                    }));

                                }
                                setActiveTask(event.tasks[taskIndex - 1]);
                            }}>
                            {formatMessage({ id: 'form_unbundle_task' })}
                        </Button>
                        <hr />
                        {formTask && (
                            <Popconfirm
                                title={formatMessage({ id: 'confirm_delete' })}
                                onConfirm={() => handleDelete(formTask?.id)}
                                okText='OK'
                                cancelText={formatMessage({ id: 'payment_request_modal_cancel' })}
                                icon={null}
                                okButtonProps={{ danger: true, className: 'button_confirm' }}

                            >
                                <Button
                                    className='edit-task-component__remove block-el'
                                    type='link'
                                >
                                    <Svg name=':icon/delete' className='icon_16' />
                                    {formatMessage({ id: 'form_remove_task' })}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </div>
            </Form>


            {form.getFieldValue('questions') && (
                <SortQuestionsComponent
                    list={form.getFieldValue('questions')}
                    showModal={sortMode}
                    onSort={handleSortQuestions}
                    onCloseModal={setSortMode}
                />
            )}
            {isChanged && (
                <div className="edit-task-component__stiky">
                    <Button type="primary"
                        onClick={handleFormSubmitOnBlur}>
                        {formatMessage({ id: 'save_changes_button' })}
                    </Button>
                </div>
            )}
        </div>
    );
};
