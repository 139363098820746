import "./scss/index.scss";
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useIntl } from "react-intl";
import { useAiChatResponse } from "../graphQL/useAiChatResponse";

import { useGetVideo, useGetAssessmentAi, useGetCommonAi, useGetQuiz, useGetContentQuiz, } from "../hook";
import { useGetImagesByString } from "../../search";
import { useIsAllow, getTitle, getType } from '../../common';

const stepsLimit = 10;
export const AddNewContentContainer = (props) => {
    const {
        task,
        handleEditQuestion,
        handleReplaceQuestion,
        handleDeleteQuestion,
        question,
        user,
        inProgressNextStep,
        inProgress,
        setInProgress,
        setInProgressNextStep,
        handleDuplicateQuestion,
        notAutoGenerate,
        event,
        userIsAllowGenerateContent,
    } = props;

    const source = (task.summary || task.source);
    const [header, setHeader] = useState();
    const goal = task?.title || "";
    const { getContentQuizAi } = useGetContentQuiz();
    const { getVideo } = useGetVideo();
    const { getCommonAi } = useGetCommonAi();
    const { getImagesList } = useGetImagesByString();
    const { getAssessmentAi } = useGetAssessmentAi();
    const { getChatResponse } = useAiChatResponse();

    const { getQuizAi } = useGetQuiz();
    const { formatMessage } = useIntl();

    let indexCurrent = task?.questions?.findIndex((el) => el.id === question?.id);
    const lessThan20Tasks = event?.tasks?.length < 20;
    const userIsAllowGenerate =lessThan20Tasks && task?.questions?.length <= stepsLimit && userIsAllowGenerateContent;
    const userIsAllowCreateStep = userIsAllowGenerate || user?.id === task?.creator?.id;

    const getSimplifiedGoal = async (questionProps) => {
        return await getCommonAi(questionProps.title?.substring(0, 100) + task.title + event.title.substring(0, 50,), "searchRequest");
    }
    const stepsSummary = task?.questions?.map(q => q?.title?.split('\n')[0])?.join(', ');
    // const append = `\nDo not generate anything of the previous and next steps: ${stepsSummary}, focus only on the current task.`

    //actions
    const handleNextStepAi = async (questionProps, autoLoad) => {
        setInProgress(
            {
                message: formatMessage({ id: "loader_nextstepai_async" }),
                key: `description`
            }
        );

        const steptitle = questionProps?.title?.substring(0, 30000);

        const args = {
            ...(goal && { goal }),
            ...({ stepTitle: steptitle }),
            ...(source && { source }),
            curriculum: stepsSummary,
        };

        const resp = await getChatResponse({
            variables: {
                input: {
                    type: 'nextStepJson',
                    args: { ...args },
                }
            }
        });
        const { requestChatApi } = resp?.data || {};
        const respJSON = requestChatApi ? JSON.parse(requestChatApi) : {};
        const content = respJSON?.choices?.[0]?.message?.content;
        if (!autoLoad) {
            await handleEditQuestion(content, questionProps.id, "📖");
        }
        setInProgress({
            key: `description`
        });
        return content;
    };
    const getMostRelevantVideo = async (questionProps, autoload) => {
        setInProgress({
            message: formatMessage({ id: "loader_video" }),
            key: `video_${questionProps.id}`
        });
        let queryString = await getSimplifiedGoal(questionProps);
        try {
            let iframeHtml = await getVideo(
                queryString,
                goal
            );
            if (iframeHtml) {
                if (!autoload) {
                    await handleEditQuestion(
                        `## ${getTitle(questionProps?.title)} \n\n${iframeHtml}`,
                        questionProps.id, "📺"
                    );
                    setInProgress({ key: `video_${questionProps.id}` });
                } else {
                    setInProgress({ key: `video_${questionProps.id}` });
                    return iframeHtml;
                }
            } else {
                setInProgress({ key: `video_${questionProps.id}` });
            }
        }
        catch (err) {
            setInProgress({ key: `video_${questionProps.id}` });
        }

    };
    const getAssessment = async (questionProps, autoload) => {
        setInProgress(
            {
                message: formatMessage({ id: "loader_assess" }),
                key: `assessment`
            }
        );
        const steptitle = questionProps?.title?.substring(0, 2000);

        return new Promise((resolve, reject) => {
            try {
                // const apiType = !userIsPaid ? 'createTutorialAssessment' : 'createTutorialAssessmentPremium';
                const apiType = 'createTutorialAssessment';
                const assesmentPromise = new Promise((resolve) => {
                    const html = getAssessmentAi(steptitle, apiType, source);
                    resolve(html);
                });

                assesmentPromise.then((resp) => {
                    if (!autoload) {
                        const newQuestionPromise = new Promise((resolve, reject) => {
                            try {
                                const newQuestion = {
                                    answer: `${resp}`,
                                    // answer: `${getTranslatedAssessment(resp)}`,
                                    type: 'TEXT',
                                    question: `## ${getTitle(questionProps?.title)}`,
                                }

                                const respN = handleReplaceQuestion(newQuestion, questionProps.id, "🏋️");
                                setInProgress({ key: `assessment` });
                                resolve(respN);
                            } catch (error) {
                                setInProgress({ key: `assessment` });
                                reject(error);
                            }
                        });
                        newQuestionPromise.then((resp) => {
                            resolve(resp);
                        })
                        setInProgress({ key: `assessment` });
                    } else {
                        setInProgress({ key: `assessment` });
                        resolve(resp);
                    }
                })


            } catch (error) {
                setInProgress({ key: `assessment` });
                reject(error);
            }
        });
    };
    const getMostRelevantImage = async (questionProps, autoload) => {
        setInProgress({
            message: `Searching images…`,
            key: `image`
        });

        let string = `${header?.substring(0, 80)} AND infograpics`

        let imageJSON = await getImagesList({
            variables: {
                q: string,

            },
        });
        let imageArray = imageJSON?.data?.getSearch?.items;

        let html = '';
        imageArray?.forEach((el) => {
            let newHtml = `\n<div class='image-snippet'>
                       <img src='${el.link}' alt='${el.title}' >
                            <p><a href='${el.image.contextLink}'>${el.displayLink}</a></p></div>`
            html = html + newHtml;

        });
        setInProgress({
            key: `image`
        });

        if (html) {
            if (!autoload) {
                handleEditQuestion(
                    `## ${getTitle(questionProps?.title)}` + html,
                    questionProps.id, "🖼️"
                );
            }
            return html
        }

    };
    const getQuiz = async (questionProps) => {
        setInProgressNextStep(formatMessage({ id: "loader_step_quiz" }));
        let newQuestion = await getQuizAi(questionProps.title || header, goal + " " + event?.title?.substring(0, 50).trim(), 'senior');
        if (newQuestion) {
            await handleReplaceQuestion(newQuestion, questionProps.id, "✅");
        }
        setInProgressNextStep('');
    };
    const contentQuiz = async (questionProps) => {
        setInProgressNextStep(formatMessage({ id: "loader_step_contentquiz" }));
        const newQuestion = await getContentQuizAi(questionProps, task, goal);
        if (newQuestion) {
            await handleReplaceQuestion(newQuestion, questionProps.id, "⛳️");
        }
        setInProgressNextStep('');
    }

    //loaders
    const autoReLoad = async (questionProps) => {
        setInProgress(
            {
                message: formatMessage({ id: "loader_nextstepai_async" }),
                key: `${questionProps.id}_description`
            }
        );
        const newAnswer = getAssessment(questionProps, true) || "";
        const description = handleNextStepAi(questionProps, true) || '';
        const videoHtml = task?.isAutogenerated ? getMostRelevantVideo(questionProps, true) : '';

        const awaitedResults = Promise.allSettled([newAnswer, videoHtml, description]);

        awaitedResults.then(async (resp) => {
            const results = resp.map((promise) => promise?.value);
            const finalResult = {
                newAnswer: results[0] || '',
                videoHtml: results[1] || '',
            }

            let newQuestion = {
                answer: `${finalResult.newAnswer} `,
                type: 'TEXT',
                question: `## ${getTitle(questionProps?.title)}\n\n${results[2]}\n\n${finalResult.videoHtml}\n\n`,
            }

            await handleReplaceQuestion(newQuestion, questionProps.id, "🏋️");
            setInProgress({ key: `${questionProps.id}_description` });
            setInProgress({ end: true });
        });
    }

    useEffect(() => {
        const head = question?.title || getTitle(question?.question)
        setHeader(head);

        if ((!inProgressNextStep || !inProgress) && userIsAllowGenerate && !notAutoGenerate) {
            if (!!task?.questions?.length) {
                if (!question?.question && question.type !== "DEFAULT") {
                    (async () => await autoReLoad(question))();
                }
            }
        }
    }, [task.questions, question, question?.title, question?.question, indexCurrent])

    return question?.id ? (
        <>
            {question.type === "DEFAULT" &&
                <div className="wrapper__content_buttons">
                    {(userIsAllowCreateStep) ?
                        <>
                            <div className='content_buttons'>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => handleNextStepAi(question)}>

                                    {getType('DEFAULT')}
                                    {formatMessage({ id: "learning_text" })}
                                </Button>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => getAssessment(question)}>
                                    {getType('TEXT')}
                                    {formatMessage({ id: "learning_project" })}
                                </Button>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => getMostRelevantVideo(question)}>
                                    📺 {formatMessage({ id: "learning_video" })}
                                </Button>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => contentQuiz(question)}>
                                    {getType('MULTI_SELECT')}
                                    {formatMessage({ id: "learning_check" })}

                                </Button>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => getMostRelevantImage(question)}>
                                    🖼️ {formatMessage({ id: "learning_info" })}
                                </Button>
                                <Button className="btn-left "
                                    disabled={inProgress || inProgressNextStep || !userIsAllowGenerate}
                                    type="tertiary"
                                    onClick={() => getQuiz(question)}>

                                       {getType('SINGLE_SELECT')}
                                    {formatMessage({ id: "learning_quiz" })}
                                </Button>
                            </div>
                        </> : null

                    }
                </div>
            }
            {(question?.question || !question?.id) &&
                (userIsAllowCreateStep) &&
                <div className='question-actions wrapper_content-section'>
                    <div className='next-step_btns'>
                        <Button
                            className="btn-left  link-btn_light"
                            type="text"
                            size="small"
                            onClick={() => handleDuplicateQuestion(question.id, header, false, true)}
                            disabled={inProgressNextStep}
                        >
                            <span> {formatMessage({ id: "question_add" })} </span>
                        </Button>
                        <Button
                            className="btn-left link-btn_light"
                            type="text"
                            size="small"
                            onClick={() => handleDeleteQuestion(question.id)}
                            disabled={inProgressNextStep}
                        >
                            <span>{formatMessage({ id: "delete_this_step" })}
                            </span>
                        </Button>
                    </div>
                </div>
            }

        </>
    ) : null
}
