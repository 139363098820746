import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import { CreateEventComponent } from '../CreateEvent.component';
import { getUserCost, LoaderComponent, useTrackCourses } from '../../common';
import { useDeleteEvent } from '../hooks/useDeleteEvent';
import { useEditEvent } from '../hooks/useEditEvent';
import { useGetEventFull } from '../hooks/useGetEventFull';
import { useGetTags } from '../../common/hooks';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { useGetConnectedAccount } from '../../stripe';
import { useIsPermit } from '../../roles/hook/useCheckPermission';
import { getPreparedPriceForm } from '../util/getPreparedPriceForm';
const SUBS_COEF = 4.3;

export const CreateEventContainer = (props) => {
    const { as: Component = CreateEventComponent, workspace, ...other } = props;
    const { user, loadingUser } = useContext(FirebaseContext);
    const history = useHistory();
    const { id } = useParams();
    const { state = {} } = useLocation();
    const { data: stripeData } = useGetConnectedAccount();
    const { trackDeleteEvent } = useTrackCourses();

    const getTags = useGetTags();
    const { getEvent, loading: loadingGetEvent } = useGetEventFull();
    const { editEvent } = useEditEvent();
    const { deleteEvent } = useDeleteEvent();
    const [inProgress, setInProgress] = useState(false);
    const { eventId, taskProp, questionPropId, tabProp } = state;
    const [event, setEvent] = useState(null);
    const [relatedEvents, setRelatedEvents] = useState(event?.relatedEvents);
    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')

    const isPaymentDisabled = useMemo(() => {
        const { stripeAccountId, stripeAccountIsActivated } = stripeData?.getConnectedAccount || {};
        return !!stripeAccountId ? !stripeAccountIsActivated : false;
    }, [stripeData]);

    const handleCreate = async (formData, exit) => {
        setInProgress(true);
        const { cost, userCost, listTimeData, studentsLimit } = formData;
        const counter = listTimeData.filter((item) => item?.type === 'WEEKLY').length;
        const subscriptionCost = parseFloat((cost * counter * SUBS_COEF).toFixed(2));
        const convertedTagIds = await getTags(formData.tagIds || event?.tagIds);
        let preparedData =  getPreparedPriceForm(formData, cost, userCost, event)
        
        const newData = {
            ...preparedData,
            type: event?.type,
            priceSubscription:
                counter !== 0 && subscriptionCost !== 0
                    ? {
                        total: parseFloat((cost * SUBS_COEF)?.toFixed(2)) || 0,
                        forTeacher: parseFloat((subscriptionCost / (studentsLimit || 1))?.toFixed(2)) || 0,
                        forStudent: parseFloat((await getUserCost(
                            subscriptionCost,
                            studentsLimit,
                            false,
                            true))?.toFixed(2)) || 0,
                        provider: 'STRIPE',
                    }
                    : event?.priceSubscription
                        ? null
                        : undefined,
            drip: formData?.drip === 'NONE' ? null : formData?.drip,
            tagIds: convertedTagIds,
            ...(event?.id && { alias: null }),
            ...(!!formData?.galleriesInEvent && { galleriesInEvent: formData?.galleriesInEvent }),
            ...(workspace?.id && { workspaceId: workspace?.id }),
            relatedEvents: relatedEvents.map((ev, index) => ({
                relatedEventId: ev.id,
                order: index,
            })),
        };

        try {
            const editedEvent = (
                await editEvent({
                    variables: {
                        eventId: event.id,
                        eventData: newData,
                        isOwner: editOthersCourses
                    },
                })
            ).data?.event;
            exit && history.goBack()
        } catch (e) {
            console.warn(e);
        }

        setInProgress(false);
    };

    const handleRemove = async () => {
        try {
            await deleteEvent({
                variables: {
                    eventId: event.id,
                },
            });
            await trackDeleteEvent();
        } catch (e) {
            console.warn(e);
        }
        history.push('/');
    };
    const handleGetEvent = async () => {
        try {
            const eventData = await getEvent({
                variables: { id: Number(id || eventId) },
                fetchPolicy: "network-only",
            });
            setEvent({ ...eventData?.data?.event, tagIds: eventData?.data?.event?.tags?.map(tag => tag.id) });

            const sortedRelatedEvents = orderBy(eventData?.data?.event?.relatedEvents, ['order'], ['asc']);
            setRelatedEvents(sortedRelatedEvents.map(e => e.event) || []);
        } catch (e) {
            console.warn(e);
        }
    }

    const isLoading = useMemo(() => inProgress || loadingGetEvent, [inProgress, loadingGetEvent])

    useEffect(() => {
        if (id || eventId) {
            (async () => await handleGetEvent())();
        }
    }, [id, eventId, getEvent]);

    if (!event || loadingUser) {
        return <LoaderComponent type='overlay' />;
    }

    if (!user?.id && !loadingUser) {
        history.push('/');
        return null;
    }

    return (
        <Component
            {...other}
            onEditEvent={handleCreate}
            onRemove={handleRemove}
            inProgress={isLoading}
            eventState={event}
            user={user}
            isPaymentDisabled={isPaymentDisabled}
            setInProgress={setInProgress}
            setEvent={setEvent}
            onUpdateEvent={handleGetEvent}
            isOwner={editOthersCourses}
            taskProp={taskProp}
            questionPropId={questionPropId}
            tabProp ={tabProp}
            workspace={workspace}
            stripeData={stripeData}
            relatedEvents={relatedEvents}
            setRelatedEvents={setRelatedEvents}
        />)
};
