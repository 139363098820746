import React from 'react';
import { InteractiveMarkdown } from './InteractiveMarkdown';
import { SketchP5Component } from '../../graph/SketchP5Component';

export const VideoCustomPlayerContent = ({
    currentSlide,
    currentVisual,
    isDiagram,
    errors,
    setErrors,
    imageError,
    setImageError,
    currentSlideIndex,
    leftColumnClasses,
    blockClasses,
    mainIdeaNormalised,
    wmrk,
}) => {
    return (
        <div className={leftColumnClasses}>
            {wmrk && <img src={wmrk} alt="Logo" className="video-watermark" />}

            <div className='slides-left-panel'>
                <h2 className="slides-title">{currentSlide?.title}</h2>
                {
                    mainIdeaNormalised(currentSlide?.main_idea)?.map((item, index) => (
                        <p key={`slide_content_${index}`} >{item}</p>
                    ))
                }

                {(currentVisual === "diagram" && isDiagram && !errors) &&
                    <InteractiveMarkdown
                        content={currentSlide?.diagram?.replace('mermaid', '')?.replaceAll('```', '')}
                        setErrors={setErrors}
                        errors={errors}
                        classes='mermaid_video'
                    />
                }
            </div>

            {currentVisual === "illustration" ?
                <div className="slides-right-panel slides-right-panel_img"
                    style={{ backgroundImage: `url(${currentSlide?.ill})` }}>
                </div>
                :
                <div className={blockClasses} >
                    {(currentVisual === "image" || !currentVisual) &&
                        <SketchP5Component currentSlideIndex={currentSlideIndex} />
                    }

                    {currentVisual === "text" &&
                        <pre className="slides-right-panel__example">{currentSlide.example || currentSlide.code}</pre>
                    }

                    {(currentVisual === "image" && !!currentSlide?.images && currentSlide?.images?.[0]?.url && !imageError) &&
                        <img className='scrolling-image scrolling-image-down'
                            src={currentSlide?.images?.[0]?.url}
                            alt="Slideshow"
                            onError={() => setImageError(true)}
                        />
                    }
                    {(currentVisual === 'video' && !!currentSlide?.images && currentSlide?.images?.[0]?.url && !imageError) &&
                        <video key={`${currentSlideIndex}-${currentSlide?.images?.[0]?.url}`} className='scrolling-image scrolling-image-down' autoPlay>
                            <source src={currentSlide?.images?.[0]?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    }

                </div>
            }
        </div>


    );
};
