/* global YT */
import './scss/index.scss';
import { useIntl } from 'react-intl';
import React from 'react';
import { Image } from 'antd';
import { Svg } from '../Svg';

export const VideoSet = ({
    videoSet,
    handleChangeVideo,
    findVideosToCover,
    isAllow,
    user,
    ...other
}) => {

    return (
        <>
            {videoSet && (
                <>
                    <ul className='thumbnail-list'>
                        {videoSet.map((el, index) => {
                            return (
                                <li
                                    className='thumbnail-list__item'
                                    onClick={() => handleChangeVideo(index)}
                                    key={index}
                                >
                                    {el.type === 'YOUTUBE' &&
                                        <Svg name=':icon/youtube'
                                            className='thumbnail-list__item__icon'
                                        />
                                    }
                                    {el.type === 'VIDEO' &&
                                        <small className='thumbnail-list__item__text'>AI video</small>
                                    }
                                    {el.thumbnail &&
                                        <Image
                                            preview={false}
                                            src={el.thumbnail}
                                            alt={el.title}
                                        />
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </>
    );
};
