import { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { recordMonthlyCourses, fetchData, setDataByPath } from '../../../context/firebase/firebaseRequests';
import { useGetUser } from '../graphQL';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

export const useTrackCourses = () => {
    const { user, workspace } = useContext(FirebaseContext);
    const { getUser } = useGetUser();
    const { ownerId } = workspace || {};
    const [count, setCount] = useState(0);
    const [owner, setOwner] = useState(user);
    const year = new Date().getFullYear();
    const month = new Date().getMonth();

    const isOwner = useMemo(() => !ownerId || user?.id === ownerId, [user?.id, ownerId]);

    const { limit, type } = useMemo(() => {
        if (owner?.subscriptionUser?.type === 'PREMIUM') {
            return { limit: 10, type: 'month' };
        } else if (owner?.subscriptionUser?.type === 'TIER_1') {
            return { limit: 20, type: 'all' };
        } else if (owner?.subscriptionUser?.type === 'CREATOR') {
            return { limit: 30, type: 'month' };
        } else if (owner?.subscriptionUser?.type === 'PRO') {
            return { limit: 50, type: 'month' };
        } else if (owner?.subscriptionUser?.type === 'TIER_2') {
            return { limit: 50, type: 'all' };
        } else if (owner?.id === 56246) {
            return { limit: 200, type: 'all' };
        } else if (owner?.subscriptionUser?.type === 'TIER_3') {
            return { limit: 100, type: 'all' };
        } else if (owner?.subscriptionUser?.type === 'UNIVERSITY') {
            return { limit: 1000, type: 'all' };
        }
        return { limit: 1, type: 'all' }
    }, [owner]);

    const getDbData = useCallback(async () => {
        let c = 0;
        if (owner?.id) {
            if (type === 'month') {

                // take the owner?.subscriptionUser.endDate, convert into format like "2024-04-08", save into endDate variable
                const formattedEndDate = owner?.subscriptionUser.endDate.substring(0, 10);
                const dbPath = `/coursesCount/${owner?.id}/${formattedEndDate}`;
                const dbData = await fetchData(dbPath);
                c = dbData.val() || 0;
            }

            if (type === 'all') {
                const dbPath = `/coursesCount/${owner?.id}`;
                const dbData = await fetchData(dbPath);
                const data = dbData.val();
                const values = !!data ? Object.values(data) : [];
                values?.forEach((v) => c = c + v);
            }

            setCount(c);
        }
    }, [owner, month, type]);

    const isLimitReached = useMemo(() => {
        return !!count && !!limit && !(count < limit)
    }, [limit, count]);

    const trackCourses = useCallback(async () => {
        const formattedEndDate = owner?.subscriptionUser?.endDate?.substring(0, 10) || new Date().toISOString().substring(0, 10);
        const dbPath = `/coursesCount/${owner.id}/${formattedEndDate}`;
        const dbData = await fetchData(dbPath);
        const c = dbData.val() || 0;

        await recordMonthlyCourses(owner.id, formattedEndDate, c + 1);
        if (!isOwner) {
            const dbCrPath = `/coursesCount/${user.id}/${formattedEndDate}`;
            const dbCrData = await fetchData(dbCrPath);
            const cCr = dbCrData.val() || 0;
            await recordMonthlyCourses(user.id, formattedEndDate, cCr + 1);
        }
        await getDbData();
    }, [getDbData, owner, user, isOwner, month]);

    const trackDeleteEvent = useCallback(async () => {
        const dbRmPath = `/removedCoursesCount/${owner?.id}/${year}/${month}`;
        const dbRmData = await fetchData(dbRmPath);
        const rmData = dbRmData.val() || 0;
        await setDataByPath('removedCoursesCount', owner?.id, `${year}/${month}`, rmData + 1);

        if (!isOwner) {
            const dbRmCrPath = `/removedCoursesCount/${user?.id}/${year}/${month}`;
            const dbRmCrData = await fetchData(dbRmCrPath);
            const rmCrData = dbRmCrData.val() || 0;
            await setDataByPath('removedCoursesCount', user?.id, `${year}/${month}`, rmCrData + 1);
        }
    }, [owner, user, isOwner, year, month]);

    const checkOwner = useCallback(async () => {
        if (!isOwner) {
            const resp = await getUser(ownerId);
            await setOwner(resp);
        } else if (!owner?.id) {
            await setOwner(user);
        }
        await getDbData();
    }, [isOwner, ownerId, getUser, getDbData, setOwner, user, owner]);

    useEffect(() => {
        if (!!user?.id) {
            (async () => await checkOwner())();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    return { trackCourses, trackDeleteEvent, isLimitReached, limit, count, isOwner };
};
