import './scss/index.scss';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Form, Input, Select, Switch, Steps, InputNumber } from 'antd';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from "moment";
import { Tabs } from 'antd';
import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import { PublishingTabComponent } from './PublishingTab.component';

import {
    LoaderComponent,
    UploadImgComponent,
    TagSelectContainer,
    useGetTags,
    WysiwygComponent,
} from '../../common';
import { QuizGeneratorContainer } from '../../task'
import { TaskButtonDnD } from '../../task/TaskDnD/TaskButtonDnD';
import { TasksListTab } from '../../task/TasksListTab';
import { CloneEventContainer } from '../CloneEvent.container';
import { EditTaskLight } from '../../task';
import { StripeProductDataContainer } from '../../stripe';
import { MoveCoursesToWorkspaceContainer } from '../../workspace/MoveCoursesToWorkspace.container'
import { EventListItemComponent } from '../EventListItem.component/EventListItem.component'
import { DripFeedingForm } from './DripFeedingForm';
import { CertificateFormContainer } from '../../certificates';

const { Step } = Steps;
const { TextArea } = Input;
const { TabPane } = Tabs;

const TASKS_LIMIT = 21;

export const CreateEventComponent = (props) => {
    const {
        currencyCode = 'USD',
        onEditEvent = noop,
        inProgress,
        setInProgress,
        eventState,
        setEvent,
        user,
        isOwner,
        taskProp,
        questionPropId,
        tabProp,
        relatedEvents,
        setRelatedEvents,
        workspace,
        ...other
    } = props;

    const isAdmin = user?.role?.id === 1;
    const isNotAllowedAddTask = useMemo(() => (eventState?.tasks?.length || 0) >= TASKS_LIMIT, [eventState?.tasks?.length]);
    const equipment = useRef(eventState?.equipment);
    const description = useRef(eventState?.description || eventState?.message);
    const program = useRef(eventState?.program);

    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const { getFieldValue, setFieldsValue } = form;
    const tabs = [
        { id: 'overview', title: formatMessage({ id: 'create_event_tab_1' }) },
        { id: 'content', title: formatMessage({ id: 'create_event_tab_2' }) },
        { id: 'dripfeeding', title: formatMessage({ id: 'create_event_tab_4' }) },
        { id: 'certificates', title: formatMessage({ id: 'create_event_tab_5' }) },
        // { id: 'badges', title: formatMessage({ id: 'create_event_tab_6' }) },
        { id: 'payments', title: formatMessage({ id: 'create_event_tab_3' }) },
    ].filter(tab => !(eventState.type === "BUNDLE" && tab.id === 'dripfeeding'));

    let f = [];
    eventState?.galleriesInEvent?.forEach((gInE, i) => {
        if (gInE?.gallery?.type === 'PHOTO') {
            f.push({
                url: gInE?.gallery?.source,
                type: gInE?.gallery?.type,
                uid: `gInE_${i}`
            })
        }
    });

    const getTags = useGetTags();
    const [activeTab, setActiveTab] = useState(tabProp ? tabs[tabProp].id : tabs[0].id);
    const [activePaymentTab, setActivePaymentTab] = useState(!!eventState?.stripeProductId ? 1 : 0);
    const [clearStripeData, setClearStripeData] = useState(false);
    const [activeTask, setActiveTask] = useState();
    const [activeid, setActiveid] = useState();
    const [activeQuestionId, setActiveQuestionId] = useState();
    const [imagesList, setImagesList] = useState(f);
    const [stripeProductId, setStripeProductId] = useState(eventState?.stripeProductId);
    const [costLocal, setCostLocal] = useState(eventState?.price?.forStudent);
    const [userCostLocal, setUserCostLocal] = useState(eventState?.price?.forStudent);
    const [showTaskList, setShowTaskList] = useState(false);
    const [exit, setExit] = useState(true);

    const [timeTable, setTimeTable] = useState(eventState?.listTimeData?.map((timeData) => ({
        type: timeData.type,
        dateUnix: moment(timeData.date).valueOf()
    })));
    const [isChanged, setIsChanged] = useState();

    const { paymentTab_0, paymentTab_1 } = useMemo(() => (
        {
            paymentTab_0: classnames('form-payment-tab', {
                'form-payment-tab_hidden': activePaymentTab === 1
            }),
            paymentTab_1: classnames('form-payment-tab', {
                'form-payment-tab_hidden': activePaymentTab === 0
            }),
        }
    ), [activePaymentTab]);

    const onFinish = async (data) => {
        let clone = { ...data };
        if (activePaymentTab === 0 && stripeProductId) {
            await setStripeProductId('');
            await setFieldsValue({ stripeProductId: '' });
            await setClearStripeData(true);
            clone.stripeProductId = '';
        }

        let taskIds = eventState?.tasks?.map(s => s.id) || [];
        setInProgress(true);
        const convertedTagIds = await getTags(getFieldValue('tagIds') || eventState?.tagIds);

        const fields = {
            equipment: equipment.current || '',
            description: description.current || '',
            program: program.current || '',
            listTimeData: timeTable ? timeTable.map((t) => ({
                date: new Date(t.dateUnix),
                type: t.type
            })) : [],
            galleriesInEvent: imagesList?.map((e, i) => ({
                order: i,
                gallery: {
                    type: 'PHOTO',
                    source: e.url ?? e?.response?.url
                }
            })),
            cost: costLocal,
            userCost: userCostLocal,
            tagIds: convertedTagIds,
            taskIds: taskIds.map(t => Number(t))
        };

        await onEditEvent(Object.assign({}, clone, fields), exit);
        setInProgress(false);
        setExit(true);
    };

    const handleTab = (key) => {
        setActiveTab(key);
        setActiveTask(null);
        setExit(false);
        form.submit();
    };
    const handleTaskView = (task, stepId) => {
        if (!stepId) {
            setActiveTask(task)
            setActiveQuestionId(undefined);
            setActiveid(task?.id)

        } else {
            const findQuestion = task?.questions?.find((question) => question.id == stepId);
            setActiveTask(task);
            setActiveQuestionId(findQuestion?.id);
            setActiveid(findQuestion?.id)
        }
    }
    const onSendSelected = (data, events) => {
        setRelatedEvents(data)
    }

    const formClassNames = classnames('flex-left-column create-event-component__form', {
        'form-payment-tab_hidden': activeTab === 'certificates'
    });

    useEffect(() => {
        const firstTask = taskProp || (!!eventState?.tasks && eventState?.tasks[0])
        setActiveTask(firstTask)
        setActiveQuestionId(questionPropId);
        setActiveid(firstTask?.id);
    }, []);

    return (
        <div className='create-event-component'>
            {inProgress && <LoaderComponent type='overlay' />}

            <div className='flex-container article'>
                <div className='wrapper_action-vertical'>
                    <Steps current={tabs.findIndex(tab => tab.id === activeTab)} direction="vertical">
                        {tabs.map(tab => (
                            <Step key={tab.id} title={tab.title} onClick={() => handleTab(tab.id)} />
                        ))}
                    </Steps>
                </div>
                {isChanged && <div className="create-event-component__overlay" />}

                {activeTab === 'content' && eventState?.type !== "BUNDLE" &&
                    <div className='curriculum_dnd'>
                        <TaskButtonDnD
                            {...other}
                            tasks={eventState.tasks}
                            actionHidden={true}
                            showTaskView={handleTaskView}
                            activeId={activeid}
                            setEvent={setEvent}
                            onEditEvent={onEditEvent}
                            event={eventState}
                            setActiveTask={setActiveTask}
                            isNotAllowedAddTask={isNotAllowedAddTask}
                            workspace={workspace}
                        />
                        <QuizGeneratorContainer
                            event={eventState}
                            setEvent={setEvent}
                            inProgress={inProgress}
                            setInProgress={setInProgress}
                            type='text'
                            title={eventState?.title}
                            curriculum={eventState?.tasks?.map((task, i) => (`${i + 1 + task?.title?.substring(0, 25)}, `)).join(', ')}
                            tagIds={eventState.tags?.map(t => t.id)}
                            classes="btn-left block-el wrapper_content-section"
                            disabled={isNotAllowedAddTask}
                            workspace={workspace}
                            {...other}
                        />
                        <div className='wrapper_content-section'>
                            {isNotAllowedAddTask &&
                                <div className='text_secondary'>
                                    {formatMessage({ id: 'limit_warning' })}
                                </div>
                            }
                        </div>
                        <div className='wrapper_content-section'>
                            <hr />
                            <Form.Item>
                                <Button
                                    onClick={() => setShowTaskList(!showTaskList)}
                                    type="text"
                                    size="small"
                                    block='true'
                                    className='btn-left '
                                >
                                    {formatMessage({ id: 'add_explainer_short' })}
                                    {showTaskList ? '↑' : "↓"}
                                </Button>
                                {showTaskList &&
                                    <TasksListTab
                                        activeTab={formatMessage({ id: 'tab_my_tutorial' })}
                                        author={user}
                                        isMe={true}
                                        viewer={user}
                                        limit={100}
                                        type='simple'
                                        handleTaskSelect={(task) => {
                                            setEvent(p => ({ ...p, tasks: [...p.tasks, task] }));
                                            setShowTaskList(false);
                                        }}
                                    />
                                }
                            </Form.Item>

                        </div>
                    </div>
                }

                <Form
                    name='create-event'
                    layout='vertical'
                    form={form}
                    initialValues={
                        {
                            remember: true,
                            isDraft: !!eventState?.isDraft,
                            isHideAi: !!eventState?.isHideAi,
                            title: eventState?.title || '',
                            tagIds: eventState.tags?.map(t => `${t.id}`),
                            issue: eventState?.issue || '',
                            description: eventState?.description || '',
                            equipment: eventState?.equipment || '',
                            result: eventState?.result || '',
                            program: eventState?.program || '',
                            tasks: eventState?.tasks || null,
                            isAllowPromoCodes: !!eventState?.isAllowPromoCodes,
                            stripeProductId: eventState?.stripeProductId,
                            drip: eventState?.drip,
                            dripFrequency: eventState?.dripFrequency,
                            dripGranularity: eventState?.dripGranularity,
                            workspaceIds: eventState?.workspaceIds || []
                        }
                    }
                    className={formClassNames}
                    onFinish={onFinish}
                >
                    {activeTab === 'overview' && (
                        <div>
                            <Form.Item
                                name='title'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >
                                <Input size="large" placeholder={formatMessage({ id: 'create_event_title_label' })} />
                            </Form.Item>


                            <h3>{formatMessage({ id: 'form_workspace_cover' })}</h3>
                            <UploadImgComponent
                                savedImages={imagesList}
                                onSetImages={setImagesList}
                            />
                            {eventState?.type !== "BUNDLE" &&
                                <TagSelectContainer
                                    initialTagsProp={eventState?.tags}
                                    title={eventState?.title}
                                    form={form}
                                    label={formatMessage({ id: 'create_event_subject_label' })}
                                />
                            }
                            <Form.Item
                                name='issue'
                                label={formatMessage({ id: 'course_source_cutoff' })}
                                rules={[
                                    {
                                        required: false,
                                        // message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >
                                <TextArea autoSize={{
                                    minRows: 2,
                                    maxRows: 16,
                                }}
                                // disabled={eventState?.issue}
                                />
                            </Form.Item>

                            <Form.Item
                                name='result'
                                label={formatMessage({ id: 'short_description_label' })}
                                rules={[
                                    {
                                        required: false,
                                        // message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >
                                <TextArea
                                    autoSize={{
                                        minRows: 2,
                                        maxRows: 3,
                                    }}
                                    maxLength={140}
                                />
                            </Form.Item>
                            <Form.Item
                                name='program'
                                label={formatMessage({ id: 'learning_objectives_label' })}
                                rules={[
                                    {
                                        required: false,
                                        // message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >

                                <WysiwygComponent
                                    onChangeContent={(content) =>
                                        (program.current = content)
                                    }
                                    initialState={program.current || ''}
                                    placeholder={formatMessage({
                                        id: 'learning_objectives_label',
                                    })}
                                />
                            </Form.Item>
                            <Form.Item
                                className='wysiwyg-field'
                                name='description'
                                // label={formatMessage({ id: 'event_description_title' })}
                                label={formatMessage({ id: 'overview_title' })}
                                rules={[
                                    {
                                        required: false,
                                        // message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >
                                <WysiwygComponent
                                    onChangeContent={(content) =>
                                        (description.current = content)
                                    }
                                    initialState={description.current || ''}
                                    placeholder={formatMessage({
                                        id: 'overview_title',
                                    })}
                                />
                            </Form.Item>
                            <Form.Item
                                className='wysiwyg-field'
                                name='equipment'
                                // label={formatMessage({ id: 'event_description_title' })}
                                label={formatMessage({ id: 'prerequisites_and_equipment_label' })}
                                rules={[
                                    {
                                        required: false,
                                        // message: formatMessage({ id: 'create_event_title_message' }),
                                    },
                                ]}
                            >
                                <WysiwygComponent
                                    onChangeContent={(content) =>
                                        (equipment.current = content)
                                    }
                                    initialState={equipment.current || ''}
                                    placeholder={formatMessage({
                                        id: 'prerequisites_and_equipment_label',
                                    })}
                                />
                            </Form.Item>
                        </div>
                    )}

                    {activeTab === 'content' && (
                        eventState?.type === "BUNDLE" ?
                            <>
                                <div className='events-list_list '>
                                    {!!relatedEvents?.length &&
                                        relatedEvents.map((event, i) => (
                                            !!event ? (
                                                <div key={event?.id} className='event-with-checkbox' >
                                                    <EventListItemComponent event={event} />
                                                    <Button type="text" icon={<ArrowUpOutlined />} onClick={() => {
                                                        setRelatedEvents((prev) => {
                                                            if (i > 0) { // Ensure there's a previous item to swap with
                                                                const newEvents = [...prev];
                                                                const temp = newEvents[i];
                                                                newEvents[i] = newEvents[i - 1];
                                                                newEvents[i - 1] = temp;
                                                                return newEvents;
                                                            }
                                                            return prev;
                                                        });
                                                    }} />
                                                    <Button type="text" icon={<CloseOutlined />} onClick={() => {
                                                        setRelatedEvents(prev => prev.filter(e => e.id !== event.id));
                                                    }} />
                                                </div>
                                            ) : null
                                        ))}
                                </div>
                                <MoveCoursesToWorkspaceContainer
                                    user={user}
                                    onSendSelected={onSendSelected}
                                    filter={{ type: "ASYNC", workspaceId: workspace?.id, }}
                                    {...other}
                                />
                            </>
                            :
                            <>
                                <div className="create-event-component__z-index">
                                    {(activeTask || eventState?.tasks?.[0]) &&
                                        <EditTaskLight
                                            activeQuestionId={activeQuestionId}
                                            handleTaskView={handleTaskView}
                                            setActiveQuestionId={setActiveQuestionId}
                                            user={user}
                                            activeTask={activeTask || eventState?.tasks?.[0]}
                                            setActiveTask={setActiveTask}
                                            event={eventState}
                                            activeid={activeid}
                                            setEvent={setEvent}
                                            onSaveProgress={setInProgress}
                                            isChanged={isChanged}
                                            setIsChanged={setIsChanged}
                                            isOwner={isOwner}
                                            workspace={workspace}
                                            {...other}
                                        />
                                    }
                                </div>
                            </>

                    )}
                    {activeTab === 'dripfeeding' && (
                        <DripFeedingForm formatMessage={formatMessage} form={form} />
                    )}
                    {activeTab === 'payments' && (
                        <PublishingTabComponent
                            isAdmin={isAdmin}
                            eventState={eventState}
                            user={user}
                            currencyCode={currencyCode}
                            userCostLocal={userCostLocal}
                            costLocal={costLocal}
                            setActivePaymentTab={setActivePaymentTab}
                            activePaymentTab={activePaymentTab}
                            setStripeProductId={setStripeProductId}
                            stripeProductId={stripeProductId}
                            clearStripeData={clearStripeData}

                            getFieldValue={getFieldValue}
                            setFieldsValue={setFieldsValue}
                            setCostLocal={setCostLocal}
                            setUserCostLocal={setUserCostLocal}
                            {...other}
                        />
                    )}

                    {!isChanged &&
                        <div className='wrapper_next-step'>
                            <div></div>
                            <div className='next-step_btns'>
                                <Form.Item>
                                    <Button
                                        type='tertiary'
                                        disabled={inProgress}
                                        htmlType='submit'
                                    >
                                        {formatMessage({ id: 'create_event_save' })}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    }
                </Form>
                {activeTab === 'certificates' && (
                    <div className="wrapper_content">
                        <CertificateFormContainer
                            eventId={eventState?.id}
                            event={eventState}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
