import React, {useMemo, useState, useContext, useEffect} from 'react';
import {StripeSubscriptionComponent} from '../StripeSubsctiption.component';
import {StripeUserSubscriptionContainer} from '../StripeUserSubscription.container';
import {useConvertedPrice} from '../../common';
import {useCreateStripeSession} from '../hooks';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const StripeSubscriptionContainer = (props) => {
    const {event = {}, subscription,  stripeAccountId, workspaceStripeProduct, onLogin, ...other} = props;
    const {stripeProduct, userSubscriptions} = event;
    const {user, workspace} = useContext(FirebaseContext);
    const {getPrice} = useConvertedPrice();
    const {createStripeSession, loading, error} = useCreateStripeSession();
    const [currentPrice, setCurrentPrice] = useState();
    const {id: userId} = user;

    const userSubscription = useMemo(() => (
        subscription || userSubscriptions?.find((subs) => subs.student.id === userId)
    ), [userId, userSubscriptions, subscription])

    const prices = useMemo(() => {
        const priceSource = workspaceStripeProduct || stripeProduct;
        if (!priceSource) {
            return [];
        }
        const pricesData = [...priceSource?.[0]?.prices];
        pricesData.sort((a, b) => a.price - b.price);
        const pricesList = pricesData.map((p) => ({
            key: p.priceId,
            label: p.recurringPeriod ? `${p.recurringPeriod} subscription` : 'One time purchase',
            price: getPrice(p.price / 100, p.currency)
        }));
        setCurrentPrice(pricesList[0]);
        return pricesList;
    }, [getPrice, stripeProduct, workspaceStripeProduct]);

    const handleSubscribe = async ({priceId}) => {
        if (!userId) {
            const {ownerId} = workspace || {};
            const options = ownerId ? {afterLoginActions: {stripeSubscription: true}} : {};
            window.localStorage.setItem('stripeSubscriptionPriceId', currentPrice?.key)
            return onLogin(options);
        }

        try {
            const url = window.location.href;
            const {id: workspaceId} = workspace || {};

            const resp = await createStripeSession(
                {
                    variables: {
                        priceId: priceId || currentPrice?.key,
                        url,
                        ...(stripeAccountId && {stripeAccount: stripeAccountId}),
                        ...(workspaceId && {workspaceId})
                    }
                }
            );
            const checkoutUrl = resp?.data?.createStripeSession?.url;
            if (checkoutUrl) {
                window.location.href = checkoutUrl;
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const onSetCurrentPrice = (priceId) => {
        const price = prices?.find((p) => p.key === priceId);
        setCurrentPrice(price);
    };

    useEffect(() => {
        if (stripeAccountId) {
            (async () => {
                const priceId = window.localStorage.getItem('stripeSubscriptionPriceId');
                if (priceId) {
                    onSetCurrentPrice(priceId);
                    window.localStorage.removeItem('stripeSubscription');
                    window.localStorage.removeItem('stripeSubscriptionPriceId');
                    if (priceId) {
                        await handleSubscribe({priceId});
                    }
                }
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeAccountId]);

    return (
        <>
            {!userSubscription && (
                <StripeSubscriptionComponent {...other}
                                             prices={prices}
                                             loading={loading}
                                             error={error}
                                             currentPrice={currentPrice}
                                             setCurrentPrice={setCurrentPrice}
                                             onSubscribe={handleSubscribe}/>
            )}
            {userSubscription && (
                <StripeUserSubscriptionContainer {...other}
                                                 userSubscription={userSubscription}
                                                 stripeAccountId={stripeAccountId}/>
            )}
        </>
    )
}