import "./scss/index.scss"
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { isArray } from 'lodash';
import { AudioGenerator } from './AudioGenerator'
import { UploadImgComponent } from '../../UploadImg.component'
import { DeleteOutlined } from '@ant-design/icons';
import { VideoCustomPlayer } from '../VideoCustomPlayer';
import { LoaderComponent } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

const { TextArea } = Input;
const REQUIRED_FIELDS = ['title'];

export const VideoEditorComponent = (props) => {
    const {
        cover,
        currentSlideIndex,
        setCurrentSlideIndex,
        mermaidCode,
        handleFinish,
        setIsEditMode,
        inProgress,
        setInProgress,
        chunks,
        setChunks,
        task,
        ...other
    } = props;
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [errors, setErrors] = useState({});
    const [isUpdateTimestamps, setIsUpdateTimestamps] = useState(false);
    const { setFieldsValue } = form;
    const initialValue = useMemo(() => ({ timestamps: chunks }), [chunks?.length]);

    useEffect(() => {
        form.setFieldsValue({ timestamps: chunks });
    }, [chunks, form]);

    const handleDuplicate = useCallback(async (index) => {
        await setIsUpdateTimestamps(true);

        const currentForm = form.getFieldValue('timestamps');
        const newChunks = [...currentForm];
        const cloned = { ...newChunks[index], id: undefined, timestamp: index + 1 };
        newChunks.splice(index + 1, 0, cloned);

        for (let i = index + 2; i < newChunks.length; i++) {
            newChunks[i].timestamp = i;
        }


        form.setFieldsValue({ timestamps: newChunks });
        setChunks(newChunks);
        setCurrentSlideIndex(index + 1);
        await setIsUpdateTimestamps(false);

    }, [form, setChunks, setCurrentSlideIndex]);

    const convertImages = (images) => {
        if (images && !isArray(images)) {
            return [images];
        }

        return images?.map(img => ({
            thumbUrl: img.url,
            name: img.description || img.name || '',
            status: 'done',
            type: 'PHOTO'
        }));
    };
    const handleFinishTest = (data) => {
        let errorsField = null;
        REQUIRED_FIELDS?.forEach((name) => {
            data?.timestamps?.forEach((item, index) => {
                if (!item?.[name]) {
                    errorsField = errorsField || {};
                    errorsField[index] = {};
                    errorsField[index][name] = true;
                }
            })
        });

        if (errorsField) {
            setErrors(errorsField)
        } else {
            handleFinish(data);
        }
    };
    const moveUpTimeStamp = async (index) => {
        if (index > 0) {
            await setIsUpdateTimestamps(true);
            let currentForm = [...form.getFieldValue('timestamps')]
            const itemToMove = currentForm[index];
            currentForm.splice(index, 1);
            currentForm.splice(index - 1, 0, itemToMove);
            setFieldsValue(currentForm);
            setChunks(currentForm);
            form.setFieldsValue({ timestamps: currentForm });
            await setCurrentSlideIndex(index - 1);
            await setIsUpdateTimestamps(false);
        }
    }

    const onChangeField = useCallback((index, prop, val) => {
        const formData = form.getFieldValue('timestamps');
        const cloneData = [...formData];
        let targetItem = cloneData[index];

        if (!targetItem) {
            targetItem = {};
            cloneData[index] = targetItem;
        }
        targetItem[prop] = val;

        form.setFieldsValue({ timestamps: cloneData });
        if (REQUIRED_FIELDS.includes(prop) && errors?.[index]?.[prop]) {
            setErrors((prev) => {
                const clone = { ...prev };
                delete clone[index][prop];
                return clone;
            });
        }
        setChunks(cloneData);
    }, [form, errors]);

    const getDefaultValue = useCallback((index, prop) => (
        initialValue?.timestamps?.[index]?.[prop]
    ), [initialValue]);

    // const handleSetMedia = useCallback((type, data, index) => {
    //     const currentForm = form.getFieldValue('timestamps');
    //     const newChunks = [...currentForm];

    //     if (data?.length) {
    //         const { response } = data[0] || {};
    //         if (response) {
    //             newChunks[index][type] = type === 'images' ? [response] : response.url;
    //         }
    //     } else {
    //         newChunks[index][type] = type === 'images' ? data : null;
    //     }

    //     form.setFieldsValue({ timestamps: newChunks });
    //     setChunks(newChunks);
    // }, [form, setChunks]);


    const handleSetImages = (data, i) => {
        let currentForm = [...form.getFieldsValue()?.timestamps]

        if (data?.length) {
            const { response } = data[0] || {};
            if (response) {
                currentForm[i].images = [response];
            }
        } else {
            currentForm[i].images = data;
        }
        setFieldsValue(currentForm);
        setChunks(currentForm);
    }
    const handleSetIll = (data, i) => {
        let currentForm = [...form.getFieldsValue()?.timestamps]

        if (data?.length) {
            const { response } = data[0] || {};
            if (response) {
                currentForm[i].ill = response.url;
            }
        } else {
            currentForm[i].ill = null;
        }
        setFieldsValue(currentForm);
        setChunks(currentForm);
    }
    const handleSetAudio = (data, i) => {
        let currentForm = [...form.getFieldsValue()?.timestamps]
        if (data) {
            currentForm[i].audio = data;
        }

        setFieldsValue(currentForm);
        setChunks(currentForm);
    }

    // const handleSetImages = useCallback((data, index) => handleSetMedia('images', data, index), [handleSetMedia]);
    // const handleSetIll = useCallback((data, index) => handleSetMedia('ill', data, index), [handleSetMedia]);
    // const handleSetAudio = useCallback((data, index) => handleSetMedia('audio', data, index), [handleSetMedia]);

    const getIll = (i) => {
        const st = form.getFieldValue('timestamps');
        const targetImages = st && st[i] && st[i].ill
        if (targetImages) {
            return [{ url: targetImages }];
        } else {
            return [];
        }
    }
    const getImages = (i) => {
        const st = form.getFieldValue('timestamps');
        const targetImages = st && st[i] && st[i].images
        return targetImages;
    }
    const getError = useCallback((index, prop) => (
        errors?.[index]?.[prop]
    ), [errors]);

    const getClassNames = useCallback((index, prop) => (
        classnames('field-row', {
            'ant-input-status-error': getError(index, prop)
        })
    ), [errors]);

    const getActiveItemClassNames = (index) => classnames('video-editor-component__item', {
        'video-editor-component__item_active': index === currentSlideIndex
    });

    const modalComponent = <Modal
        visible={true}
        onCancel={() => setIsEditMode(false)}
        className='video-editor'
        footer={null}
        header={null}
        // closable={false} 
        style={{ minWidth: '100%' }}
    >
        {inProgress && <LoaderComponent type='overlay' />}
        <div className='edit-video-form_article'>
            <div className="create-form_left ">
                <Form
                    initialValues={{ timestamps: chunks }}
                    form={form}
                    onFinish={handleFinishTest}
                    className="edit-video-form__content"
                >
                    {!isUpdateTimestamps && (
                        <Form.List
                            name='timestamps'
                            rules={[{ required: false, },]}
                        >
                            {(timestamps, { add, remove }) => (
                                <div className='timestamps-container'>
                                    {timestamps.map((field, index) => (
                                        <Form.Item
                                            {...field}
                                            key={field.key}
                                            className={getActiveItemClassNames(index)}
                                            shouldUpdate={(prevValues, curValues) => prevValues.timestamps !== curValues.timestamps}
                                            onClick={() => {
                                                if (currentSlideIndex !== index) {
                                                    setCurrentSlideIndex(index);
                                                }
                                            }}
                                        >
                                            <div className='scene-header'>
                                                <Button
                                                    type="text"
                                                    size="small"
                                                    className="btn-left btn_up"
                                                    onClick={() => {
                                                        moveUpTimeStamp(index)
                                                    }}
                                                >↑</Button>

                                                <h3>{formatMessage({ id: 'scene_title' })}{index + 1}</h3>
                                                <div>
                                                    <Button
                                                        type="text"
                                                        size="small"
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                        className='margin_right'
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>

                                                    <Button
                                                        type="text"
                                                        size="small"
                                                        onClick={() => {
                                                            handleDuplicate(index); 
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faClone} />
                                                    </Button>
                                                </div>
                                            </div>
                                            <small className="text_secondary">{formatMessage({ id: 'info_example' })}</small>
                                            <div className={getClassNames(index, 'title')}>
                                                <Input
                                                    autoComplete='off'
                                                    placeholder='1-3 words title'
                                                    defaultValue={getDefaultValue(field.key, 'title')}
                                                    onChange={(e) => {
                                                        onChangeField(index, 'title', e.target.value);
                                                    }}
                                                    maxLength={80}
                                                />

                                                {getError(index, 'title') && (
                                                    <div className="error">
                                                        {formatMessage({ id: 'form_field_is_required' })}
                                                    </div>
                                                )}
                                            </div>
                                            <TextArea
                                                autoComplete='off'
                                                placeholder='Example for landscape'
                                                defaultValue={getDefaultValue(field.key, 'example')}
                                                onChange={(e) => {
                                                    onChangeField(index, 'example', e.target.value);
                                                }}
                                                autoSize={{ minRows: 1, maxRows: 7 }}
                                            />

                                            <Input
                                                autoComplete='off'
                                                placeholder='Main ideas'
                                                defaultValue={getDefaultValue(field.key, 'main_idea')}
                                                onChange={(e) => {
                                                    onChangeField(index, 'main_idea', e.target.value);
                                                }}
                                                maxLength={200}
                                            />
                                            <div className="wrapper_content-section">
                                                <small className="text_secondary">{formatMessage({ id: 'visual_example' })}</small>
                                                <UploadImgComponent
                                                    savedImages={convertImages(getIll(index, 'ill'))}
                                                    onSetImages={handleSetIll}
                                                    limitImages={1}
                                                    index={index}
                                                />

                                                <Input
                                                    autoComplete='off'
                                                    placeholder='Mermaid Diagram Syntax'
                                                    defaultValue={getDefaultValue(field.key, 'diagram')}
                                                    onChange={(e) => {
                                                        onChangeField(index, 'diagram', e.target.value);
                                                    }}
                                                    maxLength={1000}
                                                />

                                                <UploadImgComponent
                                                    savedImages={convertImages(getImages(index, 'images'))}
                                                    onSetImages={handleSetImages}
                                                    limitImages={1}
                                                    index={index}
                                                />
                                            </div>

                                            <small className="text_secondary">{formatMessage({ id: 'narration_example' })}</small>
                                            <AudioGenerator
                                                getDefaultValue={getDefaultValue}
                                                onChangeField={onChangeField}
                                                index={index}
                                                field={field}
                                                setError={setErrors}
                                                formatMessage={formatMessage}
                                                chunk={chunks[index]}
                                                chunks={chunks}
                                                setChunks={setChunks}
                                                handleSetAudio={handleSetAudio}
                                                entityId={task?.id}
                                                {...other}
                                            />
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            onClick={() => {add()}}
                                            type='secondary'
                                            block
                                        >
                                            {formatMessage({
                                                id: 'form_add_timestamp',
                                            })}
                                        </Button>
                                    </Form.Item>
                                </div>
                            )}
                        </Form.List>
                    )}
                </Form>
            </div>
            <div className='edit-video-form__right '>
                <div className="edit-video-form__video">
                    <VideoCustomPlayer
                        currentSlideIndexProp={currentSlideIndex}
                        setCurrentSlideIndexProp={setCurrentSlideIndex}
                        chunks={chunks}
                        setInProgress={setInProgress}
                        mermaidCode={mermaidCode}
                        id={task.id}
                        task={task}
                        {...other}
                    />
                </div>
            </div>
        </div>
        <div className='wrapper_next-step'>
            <div></div>
            <div className='next-step_btns'>
                <Form.Item>
                    <Button type='primary'
                        onClick={() => form.submit()}
                    >{formatMessage({ id: 'save_button' })}</Button>
                </Form.Item>
            </div>
        </div>

    </Modal>

    return (
        <>
            {createPortal(modalComponent, document.body)}
        </>
    );
}