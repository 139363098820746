import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Form, Input, Switch } from 'antd';
import { VideoFormItem } from './VideoFormItem';
import { WysiwygComponent } from '../../common';

export const EditTaskDescription = ({
    user,
    handleSetCoverImage,
    handleRemoveImage,
    coverImage,
    formTask,
    form,
    coverType,
    onChangeContent,
    formInitialValue,
    ...other
}) => {
    const { formatMessage } = useIntl();
    return (
        <div >
            <Form.Item
                name='title'
                rules={[
                    {
                        required: true,
                        message: formatMessage({ id: 'form_title_task_error_message', }),
                    },
                ]}
            >
                <Input
                    autoComplete='off'
                    placeholder={formatMessage({ id: 'form_title_task', })}
                    size="large"
                />
            </Form.Item>
            <Form.Item
                name='isAutogenerated'
                label={formatMessage({
                    id: 'form_is_autogenerate',
                })}
                className='edit-task-component__is-free'
                valuePropName='checked'
            >
                {/* <InfoMessagesComponent type='isAuto' /> */}
                <Switch defaultChecked={formInitialValue?.isAutogenerated}
                    onChange={(flag) => {
                        form.setFieldsValue({ isAutogenerated: flag });
                        onChangeContent();
                    }} />
            </Form.Item>
            <VideoFormItem
                formTask={formTask}
                coverType={coverType}
                nameVideo='video'
                user={user}
                form={form}
                {...other}
            />

            {/* <div className={getTabContentClassNames('cover')}>
            <div className='edit-task-component__cover'>
                <Form.Item name='coverImage'>
                    <UploadImgComponent
                        onSetImages={handleSetCoverImage}
                        onRemove={handleRemoveImage}
                        limitImages='1'
                        savedImages={coverImage}
                    />
                </Form.Item>
            </div>
        </div> */}

            <Form.Item
                name='description'
                className='wysiwyg-field'
            >
                <WysiwygComponent
                    onChangeContent={(content) => {
                        form.setFieldsValue({ description: content });
                        onChangeContent();
                    }}
                    initialState={formTask?.description || ''}
                    placeholder={formatMessage({
                        id: 'form_description_task_placeholder',
                    })}
                />
            </Form.Item>
        </div>
    );
};
