import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { getTitle } from '../../common';

export const useGetContentQuiz = () => {
    const { getChatResponse } = useAiChatResponse();

    const getContentQuizAi = useCallback(
        async (question, task, goal) => {
            let allConcepts = task?.questions?.reduce((acc, curr, i) => {
                let string = curr?.title?.substring(0, 30) || curr?.question?.substring(0, 30)?.replace('##', ' ')?.trim()
                return acc + `\n${i + 1}. ` + string + ', '
            }, '')

            const settings = {
                args: {
                    goal: goal,
                    step: question?.title || question?.question?.substring(0, 150),
                    steps: allConcepts
                    // level: level 
                },
                type: 'contentQuiz',
            };

            const response = await getChatResponse({
                variables: {
                    input: settings,
                },
            });
            const responseJSON = response?.data && JSON.parse(response?.data?.requestChatApi);
            if (responseJSON?.choices?.[0]) {
                const quizObject = JSON.parse(responseJSON?.choices?.[0]?.message?.content);
                const options = quizObject?.options?.map((option, i) => ({
                    order: i,
                    text: option?.text,
                    isCorrect: JSON.parse(option?.isCorrect),
                }));

                return {
                    type: 'SINGLE_SELECT',
                    question:  `## ${getTitle(question?.title)}\n\n${quizObject.guide}\n\n${quizObject.question}`,
                    options: options,
                    explanation: quizObject?.explanation,
                };
            }
        },
        [getChatResponse]
    );

    return { getContentQuizAi };
};

