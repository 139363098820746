import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button } from 'antd';
import { SketchP5Component } from '../../graph/SketchP5Component';
import { getTitle } from '../../common/utils';
import { VoiceSelector } from './VideoEditor.component/VoiceSelector'
import classnames from 'classnames';

export const VideoCustomPlaceholder = ({ inProgress, task, handleGenerateVideo, userIsAllowGenerateContent, error, ...other }) => {
    const { formatMessage } = useIntl();
    const containerClass = classnames({
        'videoContainerStyle video_preview': true,
        'fullscreen': other.type === 'fullscreen'
    });
    return (
        <div className={containerClass}>
            <div className='sliderContainerStyle'>
                <div className=" slides_empty">
                    <SketchP5Component sketchIndex={2} />
                    <small className='slides_empty_message'>
                        {formatMessage({ id: 'video_desc' })}
                    </small>

                    <h2 className='slides_empty_message'>{inProgress ? inProgress : getTitle(task.title)}</h2>
                    <VoiceSelector {...other} />

                    {!inProgress && <Button
                        className="wrapper_content-section"
                        onClick={() => handleGenerateVideo(null)}
                        disabled={!userIsAllowGenerateContent}
                        type="tertiary">{formatMessage({ id: 'video_btn' })}</Button>}

                    {error && <small className='slides_empty_message'>{error}<Link to={'/payments'}> {formatMessage({ id: 'payment_button' })}</Link></small>}
                </div>
            </div>
        </div>
    );
}