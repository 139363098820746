import '../Components/payments/scss/index.scss';

import React, { useRef, useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useIntl } from 'react-intl';

import { FooterContainer } from "../Components/footer";
import { usePremiumSubscriptionCancel } from "../Components/subscription";
import { PaymentPlansComponent, PaymentPlansAppsumoComponent } from "../Components/payments";
import { useIsAllow, useTrackCredits, useTrackCourses } from "../Components/common";
import { FirebaseContext } from '../context/firebase/firebaseContext';
import { AiTokensContainer } from '../Components/statistics';
import { AiCoursesComponent } from '../Components/statistics/AiCourses.component';

const REDIRECT_URL = "/tutorials/your-suscription-is-canceled-3284?step=179179";

export const Payments = () => {
  const pageRef = useRef();
  const { onCancelSubscription } = usePremiumSubscriptionCancel();
  const history = useHistory();

  const useIsAllowd = useIsAllow();
  const isAllow = useIsAllowd("START");
  const { user, updateUser } = useContext(FirebaseContext);

  const { type, endDate, isCanceled } = user?.subscriptionUser || {};
  const isExpired = endDate && new Date(endDate) < new Date();
  const isCreator = (type === 'CREATOR') && !isExpired;
  const isPremium = (type === 'PREMIUM') && !isExpired;
  const isAPPSUMO = (type?.includes('TIER')) && !isExpired;
  const isPro = (type === 'PRO') && !isExpired;
  const isUniversity = (type === 'UNIVERSITY') && !isExpired;
  const subscriptionType =  isPremium ? 2 : isCreator ? 3 : isPro ? 4 : undefined;
  const { limit, count } = useTrackCourses();
  const { formatMessage } = useIntl();
  const { currentCredits, lastPurchase, currentCourses } = useTrackCredits();
  let myCredits = isUniversity ? 5000 : currentCredits;

  const handleUnSubscribe = async () => {
    try {
      const resp = await onCancelSubscription();
      await updateUser();
      history.push(REDIRECT_URL);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <div ref={pageRef} className="page">
      {!isUniversity && user?.id &&
        <>
          <h3>{limit}{formatMessage({ id: 'courses_taken_and_created' })}{count} </h3>
          <p>{formatMessage({ id: 'courses_count_info_text' })}</p>
          <hr />
        </>
      }
      {myCredits &&
        <AiTokensContainer
          user={user}
          myCredits={myCredits}
          isUniversity={isUniversity}
          lastPurchase={lastPurchase}
        />
      }
      {currentCourses &&
        <AiCoursesComponent
          myCourses={currentCourses}
          user={user}
        />
      }

      {isAPPSUMO ?
        <PaymentPlansAppsumoComponent
          user={user}
        />
        :
        <PaymentPlansComponent
          user={user}
          handleUnSubscribe={handleUnSubscribe}
          isPremium={isPremium}
          isCreator={isCreator}
          subscriptionType={subscriptionType}
          isAllow={isAllow}
          isExpired={isExpired}
          isCanceled={isCanceled}
          isPro={isPro}
          isUniversity={isUniversity}
        />
      }

      <div>
        <hr />
        <Link to='/credits/' className='link-btn-secondary ant-btn ant-btn-secondary  ant-btn-sm'>
          Purchase AI credits to generate more content
        </Link>
      </div>
      {/* <div className="wrapper_outeNavr-section page-content">
        <h2>
          {formatMessage({
            id: "premium_why",
          })}
        </h2>
        <p className="wrapper_inner-section">
          {formatMessage({
            id: "premium_why_des",
          })}
        </p>

        <img
          src="https://cdn.unschooler.me/1661504113162825.png"
          alt=""
          className="wrapper_outer-section"
        />
        <p className="wrapper_inner-section">
          {formatMessage({
            id: "premium_why_call",
          })}
        </p>
      </div> */}

      <FooterContainer />
    </div >
  );
}
