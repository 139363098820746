import React from 'react';

export const CertificateLogo = ({ workspace }) => {
    return (
        <div className='image-container'>
            <img className="center-image" src={workspace?.favicon || workspace?.logo || 'https://cdn.unschooler.me/17041184637830c8.png'} alt="Workspace Logo" />
            <img className="background-image" src="https://cdn.unschooler.me/web_app/achievement/cert/stamp3.svg" alt="Achievement Badge" />
        </div>
    );
};

