import React, { useState } from 'react';
import { MoveCoursesToWorkspaceComponent } from '../MoveCoursesToWorkspace.component/'
import { useGetEvents } from '../../events/hooks/useGetEvents';
import { GetEventsSimple } from '../../events/graphQL/queries/GetEventsSimple';
const LIMIT = 100;

export const MoveCoursesToWorkspaceContainer = (props) => {
    const { workspace, user, onSendSelected, afterFilter, filter, ...other } = props;
    const { getEvents, loading: eventsLoading, user: userEvents } = useGetEvents(GetEventsSimple);
    const [isShowMore, setIsShowMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [events, setEvents] = useState([])

    const toggleEventSelection = (eventId) => {
        setSelectedEvents(prevSelectedEvents => prevSelectedEvents.includes(eventId)
            ? prevSelectedEvents.filter(id => id !== eventId)
            : [...prevSelectedEvents, eventId]);
    };
    const getEventList = async () => {
        const {workspaceId} = filter;
        const filterData = {
            ...filter,
            ...(!workspaceId && {creatorId: user.id})
        }

        const { data } = await getEvents({
            variables: {
                filter: {
                    ...filterData,
                },
                limit: LIMIT,
                offset
            }
        });

        if (data?.events) {
            let filtered = data.events
            if (afterFilter === "NotFromWorkspace") {
                filtered = data.events.filter(event => !event?.workspaceIds?.includes(workspace?.id));
            }
      
            setOffset((prev) => prev + LIMIT);
            setIsShowMore(filtered?.length === LIMIT);
            setEvents((prev) => [...prev, ...filtered]);
        }
    }

    const handleSendSelected = async () => {
        let eventsArray = []
        selectedEvents.forEach(id => {
            const eventToAdd = events.find(event => event.id === id);
            eventsArray.push(eventToAdd)
        })
        onSendSelected(eventsArray, selectedEvents);
        setSelectedEvents([]);
        setEvents([]);
        setOffset(0);
    };

    return (
        <MoveCoursesToWorkspaceComponent
            user={user}
            getEventList={getEventList}
            handleAddAll={handleSendSelected}
            events={events}
            setEvents={setEvents}
            loading={eventsLoading}
            selectedEvents={selectedEvents}
            toggleEventSelection={toggleEventSelection}
            fetchMoreEvents={getEventList}
            {...other}
        />
    )
};

