import './scss/index.scss';
import React, { useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Radio } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { orderBy } from 'lodash';

import { ReactMarkdownComponent } from '../ReactMarkdown.component/ReactMarkdown.component';
import { useGetEvent } from '../../event/hooks/useGetEvent';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { VideoGeneratorButton } from '../../common/VideoIframe';
import { ObjectivesComponent } from '../../event/Objectives.component/Objectives.component';
import { FooterContainer } from '../../footer';
import { getReplaceIframe } from './getReplaceIframe';

export const CourseToPrint = (props) => {
    const { event: sourceEvent, onReset } = props;
    const printRef = useRef();
    const { workspace } = useContext(FirebaseContext);
    const { event: eventData, getEvent } = useGetEvent();
    const event = eventData || sourceEvent;
    const { formatMessage } = useIntl();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: onReset,
        removeAfterPrint: true,
    });

    useEffect(() => {
        const workspaceId = workspace?.id;
        const id = sourceEvent?.id;
        (async () => {
            await getEvent({
                variables: {
                    id,
                    ...(workspaceId && { workspaceId })
                }
            });
            handlePrint();
        })();
    }, [workspace, sourceEvent, getEvent]);

    const isVisual = event?.galleriesInEvent?.length > 0 && event.galleriesInEvent?.find(gallery => gallery?.gallery?.type === "PHOTO")?.gallery?.source

    return (
        <article className="page_pdf pagebreak"
            ref={printRef}>
            <section className="cover-page">
                <p> {formatMessage({ id: "course_title" })}</p>
                <h1 className='supertitle'>{event.title}</h1>
                <a href={`https://${window.location.host}/online-courses/${event?.id}`} target="_blank" rel="noopener noreferrer">
                    {`https://${window.location.host}/online-courses/${event?.id}`}
                </a>
                {isVisual && (
                    <div className="wrapper_section">
                        <div className="wrapper_image_card" style={{
                            backgroundImage: `url(${isVisual})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '50%'
                        }}>
                        </div>
                    </div>
                )}
                {event.program && (
                    <div className="event-description  wrapper_outer-section">
                        <h2 className="event-component__row__title">
                            {formatMessage({ id: "learning_objectives_label" })}
                        </h2>
                        <ObjectivesComponent event={event} />
                    </div>
                )}
                {event.description && (
                    <div className="event-description wrapper_outer-section">
                        <div className="Answer__info">
                            <h2>{formatMessage({ id: 'overview_title' })}</h2>
                            <ReactMarkdownComponent children={event.description} />
                        </div>
                    </div>

                )}
                {event.equipment && (
                    <div className="event-description  wrapper_outer-section">
                        <h2 className="event-component__row__title">
                            {formatMessage({ id: "prerequisites_and_equipment_label" })}
                        </h2>
                        <ReactMarkdownComponent children={event.equipment} />
                    </div>
                )}
                {/* <img src={isVisual} alt="Cover"  /> */}
            </section>
            <section className="table-of-contents">

                <h2>{formatMessage({ id: "form_workspace_content" })}</h2>
                <ul>
                    {event.tasks.map((task, i) => (
                        <li key={task?.id}>
                            <a href={`https://${window.location.host}/tutorials/${task.alias}-${task.id}`} target="_blank" rel="noopener noreferrer">
                                {task.title?.substring(0, 40)}
                            </a>
                            <ul>
                                {task.questions.map((question, j) => (
                                    <li key={question?.id}>
                                        <a href={`https://${window.location.host}/tutorials/${task.alias}-${task.id}?step=${question?.id}`} target="_blank" rel="noopener noreferrer">
                                            {question.title?.substring(0, 40).replace('## ', '')}
                                        </a>

                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>

            </section >
            {
                event.tasks.map((task, i) =>
                    <section key={task?.id}>
                        <hr />
                        <p> {formatMessage({ id: "drip_granularity_task" })} {i + 1}</p>
                        <h2>{task.title}</h2>
                        <a href={`https://${window.location.host}/tutorials/${task.alias}-${task.id}`} target="_blank" rel="noopener noreferrer">
                            {`https://${window.location.host}/tutorials/${task.alias}-${task.id}`}
                        </a>
                        {task?.cover?.type === "YOUTUBE" &&
                            (
                                <div className="wrapper_inner-section">
                                    <img src={`https://i.ytimg.com/vi/${task?.cover?.source?.slice(-11)}/mqdefault.jpg`} alt="" />
                                    <div className="wrapper_content-section">
                                        <a href={task?.cover?.source}>{task?.cover?.source}</a>
                                    </div>
                                </div>
                            )
                        }
                        {(task?.cover?.type === "VIMEO" || task?.cover?.type === "VIDEO") &&
                            (
                                <div className="wrapper_inner-section">
                                    <a href={task?.cover?.source}>{task?.cover?.source}</a>
                                </div>
                            )
                        }
                        {(task?.cover?.type === "VIDEO" && !task?.cover?.source?.length) &&
                            (
                                <>
                                    <VideoGeneratorButton
                                        task={task}
                                    />
                                    <div className="wrapper_content-section">
                                        <a href={`/embed/video-preview/${task?.id}`}>AI Video Preview</a>
                                    </div>
                                </>
                            )
                        }

                        <section className="Answer__article">
                            <div
                                className="Answer__info wysiwyg-content wrapper_inner-section"
                                tabIndex={0}
                            >
                                <ReactMarkdownComponent children={task.description} />
                            </div>
                        </section>
                        {
                            task?.questions?.map((question, index) =>
                                <div key={index} className='Answer__info answer-results__form'>
                                    <>
                                        <p>{`${formatMessage({ id: 'drip_granularity_question' })} ${index + 1}`}</p>
                                        <a href={`https://${window.location.host}/tutorials/${task.alias}-${task.id}?step=${question?.id}`} target="_blank" rel="noopener noreferrer">
                                            {`https://${window.location.host}/tutorials/${task.alias}-${task.id}?step=${question?.id}`}
                                        </a>
                                        {!question?.question && (
                                            <ReactMarkdownComponent children={question.title} />
                                        )}

                                        <div
                                            className="wysiwyg-content wrapper_inner-section"
                                            tabIndex={0}
                                        >
                                            <ReactMarkdownComponent children={getReplaceIframe(question.question, task)} />
                                        </div>
                                        {question?.type === "TEXT" && question?.answer && (
                                            <div className="result-content qustion-actions">
                                                <ReactMarkdownComponent children={question?.answer} />
                                            </div>
                                        )}
                                        <div>
                                            {question?.type === "SINGLE_SELECT" && (
                                                <Radio.Group
                                                    name="radiogroup"
                                                    optionType="button"
                                                    className="radio_quesion"
                                                >
                                                    {orderBy(question?.options, "order")?.map(
                                                        (option, i) => (
                                                            <span key={option?.id + i}>
                                                                <Radio

                                                                    value={option.id}

                                                                    className="radio_question__option"
                                                                    style={
                                                                        option.isCorrect
                                                                            ? {
                                                                                color: "#4CC05E",
                                                                            }
                                                                            : {
                                                                                color: "#E7522E",
                                                                            }
                                                                    }
                                                                >
                                                                    ✦ {option?.text}
                                                                </Radio>
                                                            </span>
                                                        )
                                                    )}
                                                </Radio.Group>
                                            )}
                                            {question?.type === "MULTI_SELECT" && (
                                                <div className="qustion-actions">
                                                    <div className="multi-select">
                                                        <Checkbox.Group
                                                            name="optionIds"
                                                        >
                                                            {question?.options?.map((option, i) => (
                                                                <Checkbox
                                                                    key={`option-${option.id}-${i}`}
                                                                    name="t"
                                                                    value={option.id}
                                                                    className="radio_question__option"
                                                                    style={
                                                                        option.isCorrect
                                                                            ? {
                                                                                color: "#4CC05E",
                                                                            }
                                                                            : {
                                                                                color: "#E7522E",
                                                                            }
                                                                    }
                                                                >
                                                                    {option.text}
                                                                </Checkbox>
                                                            ))}
                                                        </Checkbox.Group>
                                                    </div>
                                                    <Button
                                                        type="secondary"
                                                    >
                                                        Send
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="task-form__answer wysiwyg-content">
                                                <ReactMarkdownComponent children={question.explanation} />
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )
                        }
                    </section>
                )
            }
            < FooterContainer
                workspace={workspace}
            />
        </article >
    )
};
