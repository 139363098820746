import React, {useState} from 'react';
import {Button, Checkbox} from 'antd';

import {LoaderComponent} from '../../../common';
import {UserContainer} from '../../User';

export const WorkspacesComponent = (props) => {
    const {
        isLoading,
        allWorkspaces,
        hostWorkspaces,
        notCompletedWorkspaces,
        completedWorkspaces,
        onCheck,
        onGetAllWorkspaces,
        onGetHostWorkspaces,
        onGetNotCompletedWorkspaces,
        onGetCompletedWorkspaces,
        onCheckDomainName
    } = props;

    return (
        <div className="page">
            <h1>Воркспейсы</h1>
            <hr/>
            <div>
                <h2>Все воркспейсы: {allWorkspaces?.length}</h2>
                {!!allWorkspaces?.length && (
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td>Workspace ID</td>
                            <td>Owner ID</td>
                            <td>Domain/Host</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allWorkspaces.map((item, index) => (
                                <tr key={`all_${item?.id}`}>
                                    <td>{index + 1}</td>
                                    <td>{item?.id}</td>
                                    <td>{item?.ownerId}</td>
                                    <td>{item?.customDomain || item?.host}</td>
                                </tr>))
                        }
                        </tbody>
                    </table>
                )}
                <Button type="secondary"
                        disabled={isLoading}
                        onClick={onGetAllWorkspaces}>
                    Get all workspaces
                </Button>
            </div>
            <hr/>
            <div>
                <h2>Без кастомных доменов: {hostWorkspaces?.length}</h2>
                {!!hostWorkspaces?.length && (
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td>Workspace ID</td>
                            <td>Owner ID</td>
                            <td>Host</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            hostWorkspaces?.map((item, index) => (
                                <tr key={`without-domain_${item?.id}`}>
                                    <td>{index + 1}</td>
                                    <td>{item?.id}</td>
                                    <td>{item?.ownerId}</td>
                                    <td>{item?.host}</td>
                                </tr>))
                        }
                        </tbody>
                    </table>
                )}
                <Button type="secondary"
                        onClick={onGetHostWorkspaces}>
                    Get workspaces without custom domain
                </Button>
            </div>
            <hr/>
            <div>
                <h2>С доменом и статусом false: {notCompletedWorkspaces?.length}</h2>
                {!!notCompletedWorkspaces?.length && (
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td>Workspace ID</td>
                            <td>Owner ID</td>
                            <td>Custom Domain</td>
                            <td>Правильность имени</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            notCompletedWorkspaces?.map((item, index) => (
                                <tr key={`without-domain_${item?.id}`}>
                                    <td>{index + 1}</td>
                                    <td>{item?.id}</td>
                                    <td>{item?.ownerId}</td>
                                    <td><a href={`https://${item?.customDomain}`} target="_blank" rel="noreferrer">{item?.customDomain}</a></td>
                                    <td>{onCheckDomainName(item?.customDomain) ? '' : 'Не правильный хост'}</td>
                                </tr>))
                        }
                        </tbody>
                    </table>
                )}
                <Button type="secondary"
                        onClick={onGetNotCompletedWorkspaces}>
                    Get not completed workspaces
                </Button>
            </div>
            <hr/>
            <div>
                <h2>С доменом и статусом true: {completedWorkspaces?.length}</h2>
                {!!completedWorkspaces?.length && (
                    <table>
                        <thead>
                        <tr>
                            <td></td>
                            <td>Workspace ID</td>
                            <td>Owner ID</td>
                            <td>Custom Domain</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            completedWorkspaces?.map((item, index) => (
                                <tr key={`without-domain_${item?.id}`}>
                                    <td>{index + 1}</td>
                                    <td>{item?.id}</td>
                                    <td>{item?.ownerId}</td>
                                    <td><a href={`https://${item?.customDomain}`} target="_blank" rel="noreferrer">{item?.customDomain}</a></td>
                                </tr>))
                        }
                        </tbody>
                    </table>
                )}
                <Button type="secondary"
                        onClick={onGetCompletedWorkspaces}>
                    Get completed workspaces
                </Button>
            </div>
            <hr/>
            <Button type="primary"
                    disabled={!notCompletedWorkspaces?.length ||isLoading}
                    onClick={onCheck}>
                <span>
                    {isLoading && (<LoaderComponent type="inline"/>)}
                    Проверить не подвержденные домены
                </span>

            </Button>
            <hr/>
            <p>
                Каждый воркспейс с правильным именем будет вызван и, если с ним все ок, для проверяемого воркспейса будет проставлен status: true.
            </p>
            <hr/>
            <UserContainer/>
        </div>
    )
}