import React, { useRef, useMemo, useEffect } from 'react';
import Player from '@vimeo/player';

export const VimeoPlayer = (props) => {
    const { videoSrc } = props;
    const playerRef = useRef(null);
    const videoId = useMemo(() => {
        const list = videoSrc.split('/');
        return list.find((item) => Number.isInteger(parseInt(item)));
    }, [videoSrc]);
    let player;

    useEffect(() => {
        if (playerRef?.current) {
            player = new Player(playerRef?.current, {
                id: videoId,
            });
        }
    }, [playerRef?.current, videoId]);

    
    return (
        <div className='videoset'>
            <div ref={playerRef} className="playerContainerStyle" />
        </div>
    )
}
