import './scss/index.scss';
import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useTrackClick, Svg } from '../../common';
import { cardStyle, getTitle, colorStyle } from '../../common/utils'
import { useLocalePrefix } from '../../lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AIFeedbackContainer } from '../../ai/AIFeedback.container/AIFeedback.container';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


export const NextStepComponent = ({
    task,
    findQuestion,
    setStepId,
    setIndexOfQuestion,
    indexOfQuestion,
    event,
    currentResult,
    user,
    taskResult,
    isEmbed = '',
    handleCheck,
    isInteractive,
    setShowSuccessScreen,
    showSuccessScreen,
    workspace,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const location = useLocation();
    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();

    const currentTaskIndex = taskResult?.findIndex((el) => el.id === task.id);
    const nextLessonId = !!taskResult?.length ? taskResult[currentTaskIndex + 1]?.id : null;
    const nextLessonAlias = !!taskResult?.length ? taskResult[currentTaskIndex + 1]?.alias : null;
    const lastQuestion = indexOfQuestion === task?.questions?.length - 1;
    const hideNextButton = lastQuestion && !nextLessonId && (showSuccessScreen && !event?.certificate);
    const previousStepId = task?.questions?.[indexOfQuestion - 1]?.id;
    const previousTask = !!taskResult?.length ? taskResult[currentTaskIndex - 1] : null;
    const firstTask = (indexOfQuestion < 0 && !previousTask);

    const handleNextStep = async () => {
        if (!findQuestion) {
            trackClick(
                `click-nextStep`,
                'nextStep',
                `https://unschooler.me/${location.pathname}}`
            );

            // if we are not in the question, we are in the main descriptioon 
            const nextStepId = task?.questions?.[0]?.id;
            if (nextStepId) {
                // we find the first question and go to it
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('step', nextStepId.toString());
                history.push({ search: searchParams.toString() });

                setStepId(task?.questions?.[0]?.id);
                setIndexOfQuestion(0)
            } else {
                if (currentTaskIndex === taskResult?.length - 1) {
                    history.push(`${localePrefix}/${isEmbed}tutorials/${taskResult[0].alias}-${taskResult[0].id}`);
                } else {
                    //if this is the last question in the task, go to the next task
                    history.push(
                        `${localePrefix}/${isEmbed}tutorials/${nextLessonAlias}-${nextLessonId}`
                    );
                }
            }
        } else {
            //if we are in the question
            if (currentTaskIndex < 0 || currentTaskIndex === undefined) {
                //this is not a course
                if (lastQuestion) {
                    history.push(
                        `${localePrefix}/${isEmbed}tutorials/${task.alias}-${task.id}`
                    );
                }
                else {
                    //if this is not the last question, go to the next question
                    const nextStepId = task.questions[indexOfQuestion + 1].id;
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set('step', nextStepId.toString());
                    history.push({ search: searchParams.toString() });
                    setIndexOfQuestion(indexOfQuestion + 1);
                    setStepId(nextStepId);
                }
            } else {
                //if this is a course
                if (lastQuestion) {
                    //if this is the last question in the task, and this is the last task, go to the first task
                    if (currentTaskIndex === taskResult?.length - 1) {
                        //open success page here

                        if (showSuccessScreen) {
                            if (event?.certificate) {
                                history.push(`${localePrefix}/${isEmbed}certificate/${event?.certificate?.id}`);
                            } else {
                                history.push(`${localePrefix}/${isEmbed}tutorials/${taskResult[0].alias}-${taskResult[0].id}`);
                            }
                        } else {
                            setShowSuccessScreen(true)
                        }

                    } else {
                        //if this is the last question in the task, go to the next task
                        history.push(
                            `${localePrefix}/${isEmbed}tutorials/${nextLessonAlias}-${nextLessonId}`
                        );
                    }
                } else {
                    //if this is not the last question, go to the next question
                    const nextStepId = task.questions[indexOfQuestion + 1].id;
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set('step', nextStepId.toString());
                    history.push({ search: searchParams.toString() });
                    setIndexOfQuestion(indexOfQuestion + 1);
                    setStepId(nextStepId);
                }

            }
        }
        if (event?.isSubscribe) {
            handleCheck()
        }
    }
    const handlePreviousStep = () => {
        if (indexOfQuestion > 0) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('step', previousStepId.toString());
            history.push({ search: searchParams.toString() });
            setIndexOfQuestion(indexOfQuestion - 1);
            setStepId(previousStepId);
        } else if (indexOfQuestion < 0 && currentTaskIndex > 0) {
            // if this is task 
            const previousTaskLastQuestionId = previousTask?.questions[previousTask.questions.length - 1]?.id;
            if (previousTaskLastQuestionId) {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('step', previousTaskLastQuestionId.toString());
                history.push(`${localePrefix}/${isEmbed}tutorials/${previousTask.alias}-${previousTask.id}?${searchParams.toString()}`);
                setIndexOfQuestion(previousTask.questions.length - 1);
                setStepId(previousTaskLastQuestionId);
            } else {
                history.push(`${localePrefix}/${isEmbed}tutorials/${previousTask.alias}-${previousTask.id}`);
            }
        } else {
            // If this is the first question redirect to task itself
            const currentUrlParams = new URLSearchParams(location.search);
            currentUrlParams.delete('step'); // Remove only the 'step' parameter
            history.push(`${localePrefix}/${isEmbed}tutorials/${task?.alias}-${task?.id}?${currentUrlParams.toString()}`);
        };

        if (showSuccessScreen) {
            setShowSuccessScreen(false)
        }
    }

    const blockNextStep =
        (event?.drip === 'ANSWER'
            && findQuestion
            && !currentResult)
        || (event?.isSubscribe && isInteractive)
        ;

        

    return (
        <div className={`wrapper_next-step ${!workspace ? 'wrapper_next-step_continue' : ''}`}>
            <div className="transparent-background" style={cardStyle(currentResult)}/>

            <div >
                {/* <AIFeedbackContainer
                        question={findQuestion}
                        task={task}
                        header={findQuestion?.title || task?.title}
                        {...other}
                    /> */}
                {!firstTask &&
                    <Button className="btn-left" type='secondary' onClick={handlePreviousStep} >
                        <span>{formatMessage({ id: 'back' })}</span>
                    </Button>
                }
            </div>
            <div className='next-step_btns'>
                {currentResult && (
                    <>
                        {currentResult === 'CORRECT' ? (
                            <div >
                                {/* <span className='result-icon_emoji'>{formatMessage({ id: 'correct' })}</span> */}
                                <FontAwesomeIcon icon={faCheckCircle} style={{ color: colorStyle(currentResult)?.color, fontSize: 20, }} />
                            </div>
                        ) : currentResult === 'INCORRECT' ? (
                            <div >
                                {/* <span className='result-icon_emoji'>{formatMessage({ id: 'wrong' })}</span> */}
                                <FontAwesomeIcon icon={faTimesCircle} style={{ color: colorStyle(currentResult)?.color, fontSize: 20 }} />
                            </div>
                        ) : null}
                    </>
                )}



                {!hideNextButton &&
                    <Button className="btn-left btn-continue " type='primary' onClick={handleNextStep}
                        disabled={blockNextStep}>
                        <span className=''>{formatMessage({ id: 'continue' })}</span>
                        <Svg className="star_btn" name=':icon/star_1' fill={30} />
                        <Svg className="star_btn" name=':icon/star_1' fill={30} />
                        <Svg className="star_btn" name=':icon/star_1' fill={30} />
                    </Button>
                }
            </div>
        </div>
    );
};
