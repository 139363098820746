import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {Button} from 'antd';
import {useSendEmail, useHtmlEmail} from'../../resend';
import {openNotification} from '../../notification/Notification.component';
import {useResetPassword} from '../graphQL';
import {FirebaseContext} from '../../../context/firebase/firebaseContext';

export const ResetPassComponent = ({form, onCancelLogin, onSetIsValidEmail}) => {
    const {formatMessage} = useIntl();
    const {workspace} = useContext(FirebaseContext);
    const {resetPassword, loading: resetPasswordLoading} = useResetPassword();
    const {sendEmail} = useSendEmail();
    const {getHtmlResetPasswordEmail} = useHtmlEmail();

    const showNotification = ({error, email}) => {
        if (error) {
            openNotification('topLeft', {
                title: `${error}`,
                icon: '✉️',
                duration: 10,
            });
        } else {
            openNotification('topLeft', {
                title: `${formatMessage({id: 'email_sent_title'})}${email}`,
                icon: '✉️',
                duration: 10,
            });
        }
    }

    const handleResetPass = async () => {
        const {email} = await form.validateFields(['email']);
        const rx = /\S+@\S+\.\S+/;
        const isValid = rx.test(email);

        if (isValid) {
            try {
                const resp = await resetPassword(email);
                const {resetToken, error} = resp?.data?.resetPassword;
                showNotification({email, error});

                if (resetToken) {
                    const {host, customDomain, title = 'Unschooler'} = workspace || {};
                    const domain = host ? customDomain || `${host}.unschooler.me` : 'unschooler.me';
                    const resetPasswordUrl = `https://${domain}/reset-password?resetToken=${resetToken}`
                    const content = getHtmlResetPasswordEmail({resetPasswordUrl, email, appName: title});

                    const subject = `${formatMessage({id: 'reset_button_text'})}${title}`;
                    await sendEmail(email, content, subject);
                    onCancelLogin(false);
                }
            } catch (error) {
                showNotification({error});
            }
        } else {
            await onSetIsValidEmail(false);
            await form.validateFields(['email'])
        }
    }

    return (
        <Button type="text"
                className='reset-pass'
                size='small'
                disabled={resetPasswordLoading}
                onClick={handleResetPass}>
            {formatMessage({id: 'reset_password_button'})}
        </Button>
    )
}

