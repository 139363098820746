import './scss/index.scss';
import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';

import { TagSelectContainer, Tab } from '../../common';
import { MoveCoursesToWorkspaceContainer } from '../MoveCoursesToWorkspace.container';
import { EventListItemComponent } from '../../event/EventListItem.component';
import {useCreateEventsList} from '../hooks';

export const AddEventSectionContainer = (props) => {
    const { user, setEditmode, workspaceProp, getWorkspaceData, previousSection, onUpdateWorkspace, ...other } = props;
    const {id: workspaceId} = workspaceProp || {};
    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const tabs = [
        formatMessage({ id: 'courses_mode' }),
        formatMessage({ id: 'courses_tags' }),
    ]

    const [showForm, setShowForm] = useState(false);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [events, setEvents] = useState(false);
    const {createEventsList} = useCreateEventsList();

    const handleAddSectiontoWorkspace = async (data) => {
        const { title, tagIds } = data;
        const input = {
            workspaceId,
            title,
            ...(tagIds && {tags: tagIds?.map((id) => parseInt(id))}),
            ...(events && { courses: events?.map((event) => event.id) })
        }

        try {
            await createEventsList({
                variables: {
                    input
                }
            });
            onUpdateWorkspace();
        } catch (err) {
            console.warn(err.message);
        }

        setShowForm(false);
    }

    const onSendSelected = (selectedEvents) => {
        setEvents(selectedEvents)
    }

    return (
        <>
            <div className='add-event-section'>
                <div className="add-event-section__line-wrapper" aria-hidden="true">
                    <hr className='add-event-section__line' />
                </div>
                <div className="add-event-section__buttons">
                    <Button size='small' onClick={() => setShowForm((prev) => !prev)}>
                        <span >
                            {formatMessage({ id: 'wrksp_add_section' })}

                        </span>
                    </Button>

                </div>
            </div>

            {showForm &&
                <div className='workspace-wrapper'>
                    <div className='profile-block profile-wrapper profile-workspace-wrapper'>
                        <Form onFinish={handleAddSectiontoWorkspace}
                            form={form}
                            layout="vertical"
                            className="ai-component__form"
                            name='create-workspace'
                        >
                            <Form.Item
                                name={"title"}
                                rules={[{
                                    required: true,
                                    message: formatMessage({ id: 'wrksp_add_section_plchldr' })
                                }]}
                                className="title-field"
                            >
                                <Input placeholder={formatMessage({ id: 'wrksp_add_section_title' })} />
                            </Form.Item>
                            <div className='wrapper_content-section'>
                                <Tab
                                    items={tabs}
                                    handleClick={setActiveTab}
                                    activeTab={activeTab}
                                    type="small"
                                />

                                {activeTab === tabs[1] &&
                                    <>
                                        <TagSelectContainer
                                            form={form}
                                        />
                                    </>
                                }
                                {activeTab === tabs[0] &&
                                    <>
                                        <div className='events-list_list '>
                                            {!!events?.length &&
                                                events.map((event, i) => (
                                                    !!event ? (
                                                        <div key={event?.id} className='event-with-checkbox' >
                                                            <EventListItemComponent event={event} />
                                                            <Button type="text" icon={<ArrowUpOutlined />} onClick={() => {
                                                                setEvents((prev) => {
                                                                    const index = prev.findIndex(e => e.id === event.id);
                                                                    if (index > 0) { [prev[index], prev[index - 1]] = [prev[index - 1], prev[index]]; }
                                                                    return [...prev];
                                                                })

                                                            }} />
                                                            {/* <Button type="text" icon={<ArrowDownOutlined />} onClick={onDown} /> */}
                                                            <Button type="text" icon={<CloseOutlined />} onClick={() => {
                                                                setEvents(prev => prev.filter(e => e.id !== event.id));
                                                            }} />
                                                        </div>
                                                    ) : null
                                                ))}
                                        </div>
                                        <MoveCoursesToWorkspaceContainer
                                            workspace={workspaceProp}
                                            filter={{
                                                workspaceId: workspaceProp?.id,
                                            }}
                                            user={user}
                                            onUpdateWorkspace={onUpdateWorkspace}
                                            onSendSelected={onSendSelected}
                                            {...other}
                                        />
                                    </>
                                }
                            </div>

                            <Button
                                type={'primary'}
                                htmlType='submit'
                                className="wrapper_inner-section"
                                block="true"
                            >
                                {formatMessage({ id: 'form_button_save' })}
                            </Button>
                        </Form>
                    </div>
                </div>
            }
        </>


    );
};
