import './scss/index.scss';
import { useIntl } from 'react-intl';
import React from 'react';
import { Button } from 'antd';
import { LoaderComponent, LoginContainer, useTrackClick } from '../../common';

export const SubscriptionComponent = (props) => {
    const {
        loading,
        onSubscribe,
        type = 'primary',
        size = 'medium',
        title,
        block,
        showLogin = false,
        onSetShowLogin,
        onLoginUser
    } = props;
    const trackClick = useTrackClick();
    const { formatMessage } = useIntl();
    const buttonLabel = title ? title : formatMessage({ id: 'premium_moneyback' });

    return (
        <div className='subscription-component wrapper_inner-section'>
            {loading && <LoaderComponent type='overlay' />}
            <Button
                type={type}
                size={size}
                className='btn_light'
                block={block}
                onClick={() => {
                    onSubscribe();
                    trackClick(
                        `click_premium_${title}`,
                        title,
                        `https://unschooler.me/profile`
                    );
                }}
            >
                <span>{buttonLabel}</span>
            </Button>
            <LoginContainer showLogin={showLogin}
                setCancelLogin={onSetShowLogin}
                onLoginUser={onLoginUser}
                isSighUp={true}
            />
        </div>
    );
};
