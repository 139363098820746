import React from 'react';
import { useIntl } from 'react-intl';

import { ProfileLink, Svg, useHostNameUrl } from '../../common';

export const EventActionsComponent = (props) => {
    const {
        event,
        handleShowMustGoOn,
        call,
        user,
        workspace,
        ...other
    } = props;

    return (
        <div className='wrapper_action-horizontal wrapper_inner-section Answer__actions_horizontal '>
            <div className='Answer__actions_top'>

                <ProfileLink
                    user={event.creator}
                    isLink={false}
                />
            </div>
        </div>
    );
};
