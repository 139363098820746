import React, {useEffect, useRef, Suspense} from 'react';
import mermaid from 'mermaid';
import './scss/mermaid.scss';
import {noop} from 'lodash';
import classnames from 'classnames';

export const InteractiveMarkdown = ({content, setErrors = noop, errors, classes = 'mermaid', id = 'mermaid-box'}) => {
    const mermaidRef = useRef(null);
    useEffect(() => {
        if (!content) return;
        const timeStampStart = Date.now();

        mermaid.initialize({
            startOnLoad: true,
            theme: 'base',
            darkMode: false,
            logLevel: "4",
            themeVariables: {
                fontFamily: '"Inter", sans-serif',
                primaryColor: '#fff4dd',
                primaryBorderColor: '#fff4dd',
                lineColor: '#c5c5c5',
            }
        });

        mermaid.parseError = function (err) {
            let strangeErrorICantFix = "Cannot read properties of null (reading 'getBoundingClientRect')"
            if (err.message !== strangeErrorICantFix) {
                setErrors(err.message);
            }
        };

        try {
            mermaid.contentLoaded();
        } catch (error) {
            setErrors(error.message);
        }
        return () => {
            // Fix for rerender mermaid content
            const timeStampEnd = Date.now();
            if (timeStampEnd - timeStampStart > 100) {
                mermaidRef?.current?.removeAttribute('data-processed')
            }
        };
    }, [content]);

    const mermaidClass = classnames({
        'mermaid': true,
        'mermaid-default': classes === 'mermaid',
        'mermaid-inline': classes === 'mermaid_inline',
        'mermaid-video': classes === 'mermaid_video'
    });

    return !errors && (
        <Suspense fallback={<div>Loading diagrams...</div>}>
            {!errors && content && (
                <div
                    id={id}
                    className={mermaidClass}
                    ref={mermaidRef}
                    dangerouslySetInnerHTML={{__html: content}}>
                </div>
            )}
        </Suspense>
    );
}
