
import './scss/index.scss';
import React, { useState } from 'react';
import { Modal, Input, } from 'antd';
import { useIntl } from 'react-intl';
import { UploadVideoComponent, LoaderComponent } from '../../common';
import { useConvertAudioToText } from '../hook';
import { noop } from 'lodash';
const { TextArea } = Input;

export const AddMp4 = ({
    onAddSource = noop,
    index,
    setInProgress,
    inProgress,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(true);
    const [urlValue, setUrlValue] = useState();
    const [urlName, setUrlName] = useState();
    const [urlTranscript, setUrlTranscript] = useState();
    const { getText } = useConvertAudioToText();

    const onChange = async (videoFile) => {
        setInProgress(true);
        let req = await getText(videoFile[0].response.audio);
        if (req?.requestConvertApi) {
            const apiResponse = JSON.parse(req?.requestConvertApi);
            const segments = apiResponse.segments;
            const subtitleText = segments.map((segment, index) => {
                
                const formatTime = (time) => {
                    const date = new Date(time * 1000);
                    const hours = date.getUTCHours().toString().padStart(2, '0');
                    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
                    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
                    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
                    return `${hours}:${minutes}:${seconds},${milliseconds}`;
                };
                const start = formatTime(segment.start);
                const end = formatTime(segment.end);
                return `${start} --> ${end}\n${segment.text}\n`;
            }).join('\n');

            setUrlValue(subtitleText);
            setUrlName(videoFile[0].response.url);
            setUrlTranscript(apiResponse.text)
            setInProgress(false);
        } else {
            setInProgress(false);
        }
    }

    let object = { key: "video", type: 'VIDEO', source: urlName || '', title: `mp4 video`, raw: urlTranscript || urlValue || '', icon: "video" }
    const onSave = () => {
        onAddSource(object, index);
        setShowModal(false)
    }

    return (
        <>
            <Modal
                visible={showModal}
                title={formatMessage({ id: 'tab_ai_mp4' })}
                onCancel={() => onSave()}
                confirmLoading={inProgress}
                onOk={() => onSave()}
            >
                <UploadVideoComponent {...other} onSetVideo={onChange} setInProgress={setInProgress} />
                {inProgress &&
                    <LoaderComponent type="inline" />

                }
                {urlValue && (
                    <TextArea
                        // ref={inputRef}
                        defaultValue={urlValue}
                        placeholder={formatMessage({ id: "form_text_data_placeholder" })}
                        className="ai-bot-component__field_counter "
                        maxLength={30000}
                        showCount
                        style={{ minHeight: 170 }}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                )}

            </Modal>
        </>
    );
};
