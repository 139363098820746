import React from 'react';
import { LoaderComponent } from '../../common';
import { Link } from 'react-router-dom';

export const AiTokensComponent = (props) => {
    const { isLoading,
        totalCredits,
        myCredits,
        isUniversity,
        lastPurchase } = props;

    if (isLoading) {
        return <LoaderComponent type="inline" />
    }

    return (
        <div className="ai-tokens-component">
            <h3>
                <span className='margin_right'>
                    Current credits: {myCredits} / Credits spent: {isUniversity ? Math.round(totalCredits) : (lastPurchase ? lastPurchase - myCredits : 'N/A')}
                </span>
                <Link to='/credits/' className='link-btn-secondary ant-btn ant-btn-secondary  ant-btn-sm'>
                    Purchase more AI credits
                </Link>
            </h3>

        </div >
    )
}