import "./scss/index.scss";
import React, {useEffect, useRef} from "react";
import { Button, Checkbox, Radio, } from "antd";
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import { ReactMarkdownComponent } from "../ReactMarkdown.component/ReactMarkdown.component";
import { VideoGeneratorButton } from '../../common/VideoIframe';
import { FooterContainer } from '../../footer';
import { getReplaceIframe } from './getReplaceIframe';

export const LessonToPrint = (props) => {
    const { task, event, workspace, isAllowCreator, onReset } = props;
    const { formatMessage } = useIntl();
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: onReset,
        removeAfterPrint: true,
    });

    useEffect(() => {
        handlePrint();
    }, []);

    return (
        <article className="page_pdf pagebreak"
            ref={printRef}>
            <p> {event?.title}</p>
            <h1 className='supertitle'>{task.title}</h1>
            {task?.cover?.type === "YOUTUBE" &&
                (
                    <div className="wrapper_inner-section">
                        <img src={`https://i.ytimg.com/vi/${task?.cover?.source?.slice(-11)}/mqdefault.jpg`} alt="" />
                        <div className="wrapper_content-section">
                            <a href={task?.cover?.source}>{task?.cover?.source}</a>
                        </div>
                    </div>
                )
            }
            {(task?.cover?.type === "VIMEO" || task?.cover?.type === "VIDEO") &&
                (
                    <div className="wrapper_inner-section">
                        <a href={task?.cover?.source}>{task?.cover?.source}</a>
                    </div>
                )
            }
            {(task?.cover?.type === "VIDEO" && !task?.cover?.source?.length) &&
                (
                    <>
                        <VideoGeneratorButton
                            task={task}
                        />
                        <div className="wrapper_content-section">
                            <a href={`${window.location.host}/embed/video-preview/${task?.id}`}>AI Video Preview</a>
                        </div>
                    </>
                )
            }

            <section className="Answer__article">
                <div
                    className="Answer__info wysiwyg-content wrapper_inner-section"
                    tabIndex={0}
                >
                    <ReactMarkdownComponent children={task.description} />
                </div>
            </section>
            {
                task?.questions?.map((question, index) =>
                    <div key={index} className='Answer__info answer-results__form'>
                        <p>{`${formatMessage({ id: 'drip_granularity_question' })} ${index + 1}`}</p>
                        <>
                            {!question?.question && (
                                <ReactMarkdownComponent children={question.title} />
                            )}

                            <div
                                className="wysiwyg-content wrapper_inner-section"
                                tabIndex={0}
                            >
                                <ReactMarkdownComponent children={getReplaceIframe(question.question)} />
                            </div>
                            {question?.type === "TEXT" && question?.answer && (
                                <div className="result-content qustion-actions">
                                    <ReactMarkdownComponent children={question?.answer} />
                                </div>
                            )}
                            <div>
                                {question?.type === "SINGLE_SELECT" && (
                                    <Radio.Group
                                        name="radiogroup"
                                        optionType="button"
                                        className="radio_quesion"
                                    >
                                        {orderBy(question?.options, "order")?.map(
                                            (option, i) => {
                                                return (
                                                    <span key={option?.id + i}>
                                                        <Radio
                                                            value={option.id}
                                                            className="radio_question__option"
                                                            style={
                                                                option.isCorrect
                                                                    ? {
                                                                        color: "#4CC05E",
                                                                    }
                                                                    : {
                                                                        color: "#E7522E",
                                                                    }
                                                            }
                                                        >
                                                            ✦ {option?.text}
                                                        </Radio>
                                                    </span>
                                                )
                                            }
                                        )}
                                    </Radio.Group>
                                )}
                                {question?.type === "MULTI_SELECT" && (
                                    <div className="qustion-actions">
                                        <div className="multi-select">
                                            <Checkbox.Group
                                                name="optionIds"
                                            >
                                                {question?.options?.map((option, i) => {
                                                    return (
                                                        <Checkbox
                                                            key={`option-${option.id}-${i}`}
                                                            name="t"
                                                            value={option.id}
                                                            className="radio_question__option"
                                                            style={
                                                                option.isCorrect
                                                                    ? {
                                                                        color: "#4CC05E",
                                                                    }
                                                                    : {
                                                                        color: "#E7522E",
                                                                    }
                                                            }
                                                        >
                                                            {option.text}
                                                        </Checkbox>
                                                    )
                                                }

                                                )}
                                            </Checkbox.Group>
                                        </div>
                                        <Button
                                            type="secondary"
                                        >
                                            Send
                                        </Button>
                                    </div>
                                )}
                                <div className="task-form__answer wysiwyg-content">
                                    <ReactMarkdownComponent children={question.explanation} />
                                </div>
                            </div>
                        </>
                    </div>
                )
            }
            {!isAllowCreator && <FooterContainer workspace={workspace} />}

        </article>
    )
};
