import React, { useState } from 'react';
import { Form, Button } from 'antd';
import { useIntl } from 'react-intl';

import { AICourseObjectivesComponent } from '../index';
import { useTrackClick } from '../../common';
import { EventCurriculumItemComponent } from '../../event/EventCurriculumItem.component';
import { useVimeoVideos } from '../../vimeo';
import { useLocalePrefix } from '../../lang';

import { useGetTagsForTasks, useGetVideoSet, useGetCurrculumChannelSteps, useReducedCaptions } from '../hook';
import { noop } from 'lodash';

export const AITutorialCourseContainer = ({
    spec,
    insightsInProfile,
    user,
    title,
    initialState,
    eventState,
    sayMeEvent = noop,
    workspace,
    ...other
}) => {
    const trackClick = useTrackClick();
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();
    const MAX = localePrefix ? 30000 : 50000;
    const { getTagsForTasks } = useGetTagsForTasks();

    // eslint-disable-next-line no-unused-vars
    const { getVimeoVideosList } = useVimeoVideos();
    const { getCurriculumChannelSteps } = useGetCurrculumChannelSteps();
    const { getVideoSet } = useGetVideoSet();
    const getReducedCaptions = useReducedCaptions();

    const [event, setEvent] = useState(eventState);
    const [tags, setTags] = useState();
    const [eventLoading, setEventLoading] = useState();
    const [value, setValue] = useState(initialState);
    const [error, setError] = useState();
    const [sourceList, setSourceList] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [videoSet, setVideoSet] = useState(initialState);
    const { useForm } = Form;
    const [form] = useForm();

    // encapsulated functions
    const findTags = async (tasks) => {
        let tasksNew = [...tasks];
        let tasksString = tasksNew.map((task) => {
            return { title: task.title, tagTitle: "", tagId: "" };
        });
        try {
            const taskWithTags = await getTagsForTasks(JSON.stringify(tasksString) + "\nAnswer only with the same JSON but fill in tagTitle and tagId only from id in the file Tags.json. Don't add anything except of values from Tags.json");
            let allTags = []
            if (taskWithTags) {
                tasksNew.forEach((task) => {
                    let tagId = taskWithTags.find((taskWithTag) => taskWithTag.title === task.title).tagId;
                    let tagsArray = []
                    if (tagId && typeof tagId === 'number') {
                        tagsArray = [{
                            id: taskWithTags.find((taskWithTag) => taskWithTag.title === task.title).tagId,
                            title: taskWithTags.find((taskWithTag) => taskWithTag.title === task.title).tagTitle
                        }]
                        allTags.push(...tagsArray)
                    }
                    task.tags = tagsArray || [];
                });
                setTags(allTags);
            };
            return { tags: allTags, tasks: tasksNew };
        } catch (error) {
            console.error(error);
            // Handle the error here
        }
    }

    // other functions
    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        // onChangeContent(val);
    };

    // actions
    const handleGetCurriculum = async (data) => {
        setEventLoading(true);
        trackClick(`click-ai-tutorial`, 'titorial', `https://unschooler.me/`);
        const { ask } = data;

        let allVideos = [];
        const promises = [];
        const summarisedSource = []

        //add videos adn summary
        sourceList?.forEach((el) => {
            if (el.key === 'youtube' || el.key === 'vimeo' || el.key === 'video') {
                const videoPromise = new Promise(async (resolve, reject) => {
                    try {
                        if (!el.summary) {
                            const shortenedSummary = await getReducedCaptions(el.raw, title, MAX)
                            allVideos.push({ ...el, summary: shortenedSummary });
                            resolve({ ...el, summary: shortenedSummary });
                        } else {
                            resolve({ ...el });
                        }
                    } catch (error) {
                        el.error = error;
                        reject(error)
                    }
                });
                promises.push(videoPromise);
            } else if (el.type === 'AUDIO') {
                const textPromise = new Promise(async (resolve, reject) => {
                    try {
                        if (!el.summary) {
                            const shortenedSummary = await getReducedCaptions(el.raw, title, MAX)
                            resolve({ ...el, summary: shortenedSummary });
                        } else {
                            resolve({ ...el });
                        }
                    } catch (error) {
                        el.error = error;
                        reject(error)
                    }

                });
                promises.push(textPromise);
            }
            else if (el.type === 'TEXT') {
                const textPromise = new Promise(async (resolve, reject) => {
                    try {
                        if (!el.summary) {
                            const shortenedSummary = await getReducedCaptions(el.raw, title, MAX)
                            resolve({ ...el, summary: shortenedSummary });
                        } else {
                            resolve({ ...el });
                        }
                    } catch (error) {
                        el.error = error;
                        reject(error)
                    }
                });
                promises.push(textPromise);

            } else if (el.key === 'yt_channel') {
                const channelVideoPromise = new Promise(async (resolve, reject) => {
                    try {
                        const channelVideos = await getVideoSet(ask.trim(), '', el.source,)
                        allVideos.push(...channelVideos?.slice(0, 15));
                        resolve({ ...el });
                    } catch (error) {
                        el.error = error;
                        reject(error)
                    }
                });
                promises.push(channelVideoPromise);
            } else if (el.key === 'vimeo_folder') {
                const channelVideoPromise = new Promise(async (resolve, reject) => {
                    try {
                        const vimeoVideos = await getVimeoVideosList(ask.trim(), el.source);
                        allVideos.push(...vimeoVideos?.slice(0, 15));
                        resolve({ ...el });
                    } catch (error) {
                        el.error = error;
                        reject(error);
                    }
                });
                promises.push(channelVideoPromise);
            }
            else if (el.key === 'yt_public') {
                const channelVideoPromise = new Promise(async (resolve, reject) => {
                    try {
                        resolve({ ...el });
                    } catch (error) {
                        reject(error);
                    }
                });
                promises.push(channelVideoPromise);
            }
        });

        const finalPromise = Promise.allSettled(promises);
        finalPromise.then(async (results) => {
            results?.forEach(res =>
                summarisedSource.push(res.value || {})
            )
            // setVideoSet(allVideos);
            setSourceList(summarisedSource)
            const titlesArray = [];
            summarisedSource?.forEach((source) => {
                if (source && source?.type !== 'GIF' && source?.type !== 'PHOTO') {
                    titlesArray.push({
                        title: source.title,
                        description: source.summary?.substring(0, 2000),
                        id: source.source,
                    })
                }
            })
            allVideos?.forEach((video) => {
                if (video.source) {
                    titlesArray.push({
                        title: video.title,
                        description: video.summary?.substring(0, 100),
                        id: video.videoId || video.source,
                    })
                }
            })
            let curriculum = {};
            try {
                 curriculum = await getCurriculumChannelSteps(JSON.stringify(titlesArray), ask.trim(), allVideos, summarisedSource);
                if (curriculum) {
                    sayMeEvent(curriculum)
                    setEvent(curriculum);
                }
            } catch (error) {
                setError('Learning plan was not generated, check the format of source or reload the page.')
                setEventLoading(false);
            }
            if (user?.id && curriculum?.tasks) {
                let fdsf = await findTags(curriculum?.tasks)
                if (fdsf?.tags) {
                    let tasks = fdsf?.tasks;
                    sayMeEvent({ ...curriculum, tasks: tasks, tags: [...fdsf?.tags] });
                }
                setEventLoading(false);
            }
        })
    };

    return (
        <>
            <div className='wrapper_inner-section'>
                <AICourseObjectivesComponent
                    user={user}
                    form={form}
                    handleChange={handleChange}
                    handleGetCurriculum={handleGetCurriculum}
                    setSourceList={setSourceList}
                    value={value}
                    placeholder={formatMessage({ id: 'ai_course_des' })}
                    event={event}
                    sourceList={sourceList}
                    workspace={workspace}
                    {...other}
                />
                <p className='error wrapper_content-section'>{error}</p>
            </div>
            {(event?.issue || eventLoading) && (
                <div>
                    <EventCurriculumItemComponent
                        event={event}
                        subject={event?.subject}
                        user={user}
                        setEventLoading={setEventLoading}
                        eventLoading={eventLoading}
                        tags={tags}
                        setEvent={setEvent}
                        workspace={workspace}
                        {...other}
                    />
                </div>
            )}
        </>
    );
};
