import translate from '../lang/translate';

export const API_URL = 'https://back.unschooler.me/ai/chat-stream?apiType';
// export const API_URL = 'http://localhost:4000/ai/chat-stream?apiType';
const AVATAR_FALL_BACK = "https://cdn.unschooler.me/web_app/tech_pictures/avatar.png";

export const PRICING_MODELS = {
    elevenLabs: 0.00045,
    tts: 0.0000225,
    dollarsToCredits: 16.6
}
export const TYPES_VOICE_ELEVENLABS = [
    {
        "label": "👶 Rachel ",
        "value": "21m00Tcm4TlvDq8ikWAM",
        "model": "eleven",

    },
    {
        "label": "👨 Drew ",
        "value": "29vD33N1CtxCmqQRPOHJ",
        "model": "eleven",

    },
    {
        "label": "👨 Clyde ",
        "value": "2EiwWnXFnvU5JabPnv8n",
        "model": "eleven",

    },
    {
        "label": "👨 Paul ",
        "value": "5Q0t7uMcjvnagumLfvZi",
        "model": "eleven",
    },
    {
        "label": "👶 Domi ",
        "value": "AZnzlk1XvdvUeBnXmlld",
        "model": "eleven",

    },
    {
        "label": "👶 Dave ",
        "value": "CYw3kZ02Hs0563khs1Fj",
        "model": "eleven",

    },
    {
        "label": "👴 Fin 🇮🇪",
        "value": "D38z5RcWu1voky8WS1ja",
        "model": "eleven",

    },
    {
        "label": "👶 Sarah ",
        "value": "EXAVITQu4vr4xnSDxMaL",
        "model": "eleven",

    },
    {
        "label": "👶 Antoni ",
        "value": "ErXwobaYiN019PkySvjV",
        "model": "eleven",

    },
    {
        "label": "👶 Thomas ",
        "value": "GBv7mTt0atIp3Br8iCZE",
        "model": "eleven",

    },
    {
        "label": "👨 Charlie 🇦🇺",
        "value": "IKne3meq5aSn9XLyUdCD",
        "model": "eleven",

    },
    {
        "label": "👨 George 🇬🇧",
        "value": "JBFqnCBsd6RMkjVDRZzb",
        "model": "eleven",

    },
    {
        "label": "👶 Emily ",
        "value": "LcfcDJNUP1GQjkzn1xUU",
        "model": "eleven",

    },
    {
        "label": "👶 Elli ",
        "value": "MF3mGyEYCl7XYWbV9V6O",
        "model": "eleven",

    },
    {
        "label": "👨 Callum ",
        "value": "N2lVS1w4EtoT3dr4eOWO",
        "model": "eleven",

    },
    {
        "label": "👨 Patrick ",
        "value": "ODq5zmih8GrVes37Dizd",
        "model": "eleven",

    },
    {
        "label": "👶 Harry ",
        "value": "SOYHLrjzK2X1ezoPC6cr",
        "model": "eleven",

    },
    {
        "label": "👶 Liam ",
        "value": "TX3LPaxmHKxFdv7VOQHJ",
        "model": "eleven",

    },
    {
        "label": "👶 Dorothy 🇬🇧",
        "value": "ThT5KcBeYPX3keUQqHPh",
        "model": "eleven",
    },
    {
        "label": "👶 Josh ",
        "value": "TxGEqnHWrfWFTfGW9XjX",
        "model": "eleven",

    },
    {
        "label": "👨 Arnold ",
        "value": "VR6AewLTigWG4xSOukaG",
        "model": "eleven",

    },
    {
        "label": "👶 Charlotte ",
        "value": "XB0fDUnXU5powFXDhCwa",
        "model": "eleven",

    },
    {
        "label": "👩 Alice 🇬🇧",
        "value": "Xb7hH8MSUJpSbSDYk0k2",
        "model": "eleven",

    },
    {
        "label": "👩 Matilda ",
        "value": "XrExE9yKIg1WjnnlVkGX",
        "model": "eleven",

    },
    {
        "label": "👴 James 🇦🇺",
        "value": "ZQe5CZNOzWyzPSCn5a3c",
        "model": "eleven",

    },
    {
        "label": "👨 Joseph 🇬🇧",
        "value": "Zlb1dXrM653N07WRdFW3",
        "model": "eleven",

    },
    {
        "label": "👶 Jeremy ",
        "value": "bVMeCyTHy58xNoL34h3p",
        "model": "eleven",

    },
    {
        "label": "👴 Michael ",
        "value": "flq6f7yk4E4fJM5XTYuZ",
        "model": "eleven",

    },
    {
        "label": "👶 Ethan ",
        "value": "g5CIjZEefAph4nQFvHAz",
        "model": "eleven",

    },
    {
        "label": "👨 Chris ",
        "value": "iP95p4xoKVk53GoZ742B",
        "model": "eleven",

    },
    {
        "label": "👶 Gigi ",
        "value": "jBpfuIE2acCO8z3wKNLl",
        "model": "eleven",

    },
    {
        "label": "👶 Freya ",
        "value": "jsCqWAovK2LkecY7zXl4",
        "model": "eleven",
    },
    {
        "label": "👨 Brian ",
        "value": "nPczCjzI2devNBz1zQrb",
        "model": "eleven",
    },
    {
        "label": "👶 Grace ",
        "value": "oWAxZDx7w5VEj9dCyTzz",
        "model": "eleven",
    },
    {
        "label": "👨 Daniel 🇬🇧",
        "value": "onwK4e9ZLuTAKqWW03F9",
        "model": "eleven",

    },
    {
        "label": "👩 Lily 🇬🇧",
        "value": "pFZP5JQG7iQjIQuC4Bku",
        "model": "eleven",

    },
    {
        "label": "👩 Serena ",
        "value": "pMsXgVXv3BLzUgSXRplE",
        "model": "eleven",

    },
    {
        "label": "👨 Adam ",
        "value": "pNInz6obpgDQGcFmaJgB",
        "model": "eleven",
    },
    {
        "label": "👶 Nicole ",
        "value": "piTKgcLEGmPE4e6mEKli",
        "model": "eleven",

    },
    {
        "label": "👴 Bill ",
        "value": "pqHfZKP75CvOlQylNhV4",
        "model": "eleven",

    },
    {
        "label": "👴 Jessie ",
        "value": "t0jbNlBVZ17f02VDIeMI",
        "model": "eleven",

    },
    {
        "label": "👶 Sam ",
        "value": "yoZ06aMxZJJ28mfd3POQ",
        "model": "eleven",

    },
    {
        "label": "👩 Glinda ",
        "value": "z9fAnlkpzviPz146aGWa",
        "model": "eleven",

    },
    {
        "label": "👶 Giovanni ",
        "value": "zcAOhNBS3c14rBihAFp1",
        "model": "eleven",

    },
    {
        "label": "👶 Mimi ",
        "value": "zrHiDhphv9ZnVXBqCLjz",
        "model": "eleven",

    }

];
export const TYPES_VOICE = [
    {
        label: '👩🏾 Alla',
        value: 'alloy',
        example: 'https://cdn.unschooler.me/1_31_2024_c2869.mp3',
    },
    {
        label: '👨🏼‍💼 Evan',
        value: 'echo',
        example: 'https://cdn.unschooler.me/1_31_2024_bf8f8.mp3',
    },
    {
        label: '👨🏻 Fred',
        value: 'fable',
        example: 'https://cdn.unschooler.me/1_31_2024_f8f8c.mp3',
    },
    {
        label: '👨🏾‍⚕️ Ollie',
        value: 'onyx',
        example: 'https://cdn.unschooler.me/1_31_2024_8f8c2.mp3',
    },
    {
        label: '🧕🏽 Nova',
        value: 'nova',
        example: 'https://cdn.unschooler.me/1_31_2024_f8c28.mp3',
    },
    {
        label: '👩🏾‍🎤 Shim',
        value: 'shimmer',
        example: 'https://cdn.unschooler.me/1_31_2024_8c286.mp3',
    },
];
export const ROLES = [
    { permission: 'Owner', label: 'Owner' },
    { permission: 'Admin', label: 'Admin' },
    { permission: 'Creator', label: 'Creator' },
    { permission: 'Instructor', label: 'Instructor' },
    { permission: 'User', label: 'Student' }
  ];

export const ITEMS_SOURCE = [
    // {
    //     key: 'yt_public',
    //     id: 'yt_public',
    //     label: 'YouTube public videos (for free courses)',
    //     sourceObject: { key: "yt_public", type: 'GIF', source: 'https://www.youtube.com/', title: "YouTube public videos (for free courses)", icon: "video" }
    // },
    // {
    //     key: 'yt_channel',
    //     id: 'yt_channel',
    //     label: 'My YouTube channel',
    //     sourceObject: ({
    //         key: "yt_channel",
    //         type: 'GIF',
    //         source: '',
    //         title: "My channel", icon: "video"
    //     })
    // },
    {
        key: 'vimeo_folder',
        id: 'vimeo_folder',
        label: 'My Vimeo Folder',
        sourceObject: ({
            key: "vimeo_folder",
            type: 'GIF',
            source: '',
            title: "My Vimeo folder", icon: "video"
        })
    },
    {
        key: 'pdf',
        id: 'pdf',
        label: 'PDF document',
        sourceObject: ({
            key: "pdf",
            type: 'TEXT',
            source: '',
            title: "PDF document",
            icon: "text"
        })
    },
    {
        key: 'url',
        id: 'url',
        label: 'Url of Article',
        sourceObject: ({
            key: "url",
            type: 'TEXT',
            source: '',
            title: "Article",
            icon: "text"
        })
    },
    {
        key: 'text',
        id: 'text',
        label: 'Text',
        sourceObject: ({
            key: "text",
            type: 'TEXT',
            source: '',
            title: "Notes",
            icon: "text"
        })
    },
    // {
    //     key: 'youtube',
    //     id: 'youtube',
    //     label: 'My YouTube video',
    //     sourceObject: ({
    //         key: "youtube",
    //         type: 'YOUTUBE',
    //         source: '',
    //         title: "My YouTube video",
    //         icon: "video"
    //     })
    // },
    {
        key: 'vimeo',
        id: 'vimeo',
        label: 'My Vimeo video',
        sourceObject: ({
            key: "vimeo",
            type: 'VIMEO',
            source: '',
            title: "My Vimeo video",
            icon: "video"
        })
    },
    {
        key: 'mp3',
        id: 'mp3',
        label: 'Audio mp3',
        sourceObject: ({
            key: "mp3",
            type: 'AUDIO',
            source: '',
            title: "Audio mp3",
            icon: "mp3"
        })
    },
    {
        key: 'video',
        id: 'video',
        label: 'mp4 video',
        sourceObject: ({
            key: "video",
            type: 'VIDEO',
            source: '',
            title: "mp4 video",
            icon: "video"
        })
    },
];
export const ITEMS_SOURCE_TASK = [
    {
        key: 'yt_public',
        id: 'yt_public',
        label: 'YouTube public videos (for free courses)',
        sourceObject: { key: "yt_public", type: 'GIF', source: 'https://www.youtube.com/', title: "YouTube public videos (for free courses)", icon: "video" }
    },
    {
        key: 'pdf',
        id: 'pdf',
        label: 'PDF document',
        sourceObject: ({
            key: "pdf",
            type: 'TEXT',
            source: '',
            title: "PDF document",
            icon: "text"
        })
    },
    {
        key: 'url',
        id: 'url',
        label: 'Url of Article',
        sourceObject: ({
            key: "url",
            type: 'TEXT',
            source: '',
            title: "Article",
            icon: "text"
        })
    },

    {
        key: 'video',
        id: 'video',
        label: 'mp4 video',
        sourceObject: ({
            key: "video",
            type: 'VIDEO',
            source: '',
            title: "mp4 video",
            icon: "video"
        })
    },
];

export const GRANULARITY_LABELS = [
    { label: 'Lesson', value: 'TASK' },
    { label: 'Step', value: 'QUESTION' },
];

export const LANGS = [
    {
        "id": 2,
        "code": "en",
        "langUid": "English",
        "title": "English"
    },
    {
        "id": 1,
        "code": "ru",
        "langUid": "Russian",
        "title": "Русский"
    },
    {
        "id": 3,
        "code": "hi",
        "langUid": "Hindi",
        "title": "हिंदी"
    },
    {
        "id": 4,
        "code": "de",
        "langUid": "German",
        "title": "Deutsch"
    },
    {
        "id": 5,
        "code": "zh",
        "langUid": "Chinese",
        "title": "中国人"
    },
    {
        "id": 6,
        "code": "pt",
        "langUid": "Portuguese",
        "title": "Português"
    },
    {
        "id": 7,
        "code": "pl",
        "langUid": "Polish",
        "title": "Polski"
    },
    {
        "id": 8,
        "code": "es",
        "langUid": "Spanish",
        "title": "Español"
    },
    {
        "id": 9,
        "code": "fr",
        "langUid": "French",
        "title": "Français"
    }
];
export const DISCORD_SERVER = '761847160131551233';
export const DISCORD_CHANNELS = {
    203: '954037990827626557', //nft
    208: '835778485615198220', // startup
    231: '983728114049904750', // 50
    209: '960920906572652544', //PH
    214: '981902744023625748', //Roblox
    215: '981900001217216532', //CY4
};

export const FEATURESLIST = [
    {
        divider: "Course & Videos creation",
        title: 'AI credits for content creation',
        link: 'https://unschooler.me/tutorials/additional-pricing-326956',
        description: '',
        options: [
            '—',
            '—',
            '—',
            '5000 AI credits for all content (images, audio, video, text)',
        ]
    },
    {
        title: 'Number of AI-courses / month',
        description: '',
        options: [
            '1',
            '30',
            '50',
            'unlimited',
        ]
    },
    {
        title: 'AI videos in lessons',
        description: '',
        options: [
            '✔️',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'AI videos mp4 download',
        description: '',
        options: [
            '✔️ unschooler logo',
            '✔️ branding removal',
            '✔️ own branding',
            '✔️',
        ]
    },

    {
        title: 'Tests & Quizzes',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238903',
        options: [
            '✔️',
            '✔️',
            '✔️',
            '✔️',
        ]
    },

    {
        title: 'Career matching',
        description: '',
        // link: 'https://unschooler.me',
        options: [
            '—',
            '✔️',
            '✔️ ',
            '✔️',
        ]
    },
    {
        title: 'Download course as pdf',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238906',
        options: [
            '—',
            '✔️',
            '✔️ whitelabeled',
            '✔️',
        ]
    },
    {
        title: 'Autotranslation 28 lang',
        description: '',
        // link: 'https://unschooler.me',
        options: [
            '✔️',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'Courses from own videos and pdf, docx, txt files',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238905',
        options: [
            '—',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'Video uploading (mp4)',
        description: '',
        // link: 'https://unschooler.me',
        options: [
            '—',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'Subtitles generator',
        description: '',
        // link: 'https://unschooler.me',
        options: [
            '—',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        divider: 'Courses features',
        title: 'Students\' progress',
        description: '',
        link: 'https://unschooler.me/tutorials/student-progress-tra-274062',
        options: [
            '✔️',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'Students',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238897',
        options: [
            'unlimited',
            'unlimited',
            'unlimited',
            'unlimited,',
        ]
    },
    {
        title: 'Live room in a course',
        description: '',
        link: 'https://unschooler.me/tutorials/selling-knowledge-s-274061?step=238901',
        options: [
            '✔️',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'Sell courses with Stripe payments',
        description: '',
        link: 'https://unschooler.me/tutorials/selling-knowledge-s-274061',
        options: [
            '—',
            '✔️ 4% comission',
            '✔️ 2% comission',
            '✔️ 0% comission',
        ]
    },
    {
        title: 'Embedding to own platform',
        description: '',
        link: 'https://unschooler.me/tutorials/course-embedding-276492',
        options: [
            '—',
            '✔️',
            '✔️',
            '✔️',
        ]
    },

    {
        title: 'Removal Unschooler branding',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238897',
        options: [
            '—',
            '✔️',
            '✔️',
            '✔️',
        ]
    },
    {
        divider: 'School Workspace',
        title: 'Custom branded workspace',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238759',
        options: [
            '—',
            '—',
            '10',
            'unlimited',
        ]
    },
    {
        title: 'Students',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238897',
        options: [
            '—',
            'unlimited',
            'unlimited',
            'unlimited,',
        ]
    },
    {
        title: 'Creators',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238756',
        options: [
            '—',
            '1',
            'unlimited',
            'unlimited',
        ]
    },
    {
        title: 'Custom domain',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238897',
        options: [
            '—',
            '—',
            '10',
            '10 are included, then $10/per additional domain',
        ]
    },
    {
        title: 'Owner',
        description: '',
        link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238756',
        options: [
            '—',
            '—',
            '1',
            'transfer ownership',
        ]
    },
    {
        title: 'Workspace embedding',
        description: '',
        link: 'https://unschooler.me/tutorials/course-embedding-276492',
        options: [
            '—',
            '—',
            '✔️',
            '✔️',
        ]
    },
    {
        title: 'API',
        description: '',
        link: 'https://unschooler.me',
        options: [
            '—',
            '—',
            '—',
            '✔️',
        ]
    },
    {
        title: 'Custom development for additional price',
        description: '',
        link: 'https://unschooler.me',
        options: [
            '—',
            '—',
            '—',
            '✔️',
        ]
    },
    {
        title: 'SSO on demand',
        description: '',
        link: 'https://unschooler.me',
        options: [
            '—',
            '—',
            '—',
            '✔️',
        ]
    },

]

export const FEATURESAPPSUMOPLAN = {
    TIER_1: [
        {
            title: '20 courses',
            description: 'We count all generated courses due to AI costs, including deleted. ',
        },
        {
            title: '7% payments commission, connect in profile →',
            description: '',
            link: 'https://unschooler.me/profile',
        },
    ],

    TIER_2: [
        {
            title: '50 courses',
            description: 'We count all generated courses due to AI costs, including deleted. ',
        },
        {
            title: '5% payments commission, connect in profile →',
            description: '',
        },
        {
            title: 'White label embedded courses',
            description: '',
            link: 'https://unschooler.me/tutorials/course-embedding-276492',
        },
    ],

    TIER_3: [
        {
            title: '100 courses',
            description: 'We count all generated courses due to AI costs, including deleted.',
        },
        {
            title: '3% payments commission, connect in profile →',
            description: '',
            link: 'https://unschooler.me/profile',

        },
        {
            title: 'White label embedded courses',
            description: '',
            link: 'https://unschooler.me/tutorials/course-embedding-276492',

        },
        {
            title: 'Branded workspace for unlimited students',
            description: '',
            link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238759',

        },
        {
            title: 'White label',
            description: '',
            link: 'https://unschooler.me/tutorials/workspace-setup-set-274063?step=238897',

        },
        {
            title: 'API',
            description: '',
            link: 'https://unschooler.me/tutorials/unschooler-api-223393',

        },
    ]


}
export const FEATURESAPPSUMO = [
    {
        title: 'Download course as pdf',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238906',

    },

    {
        title: 'Lessons',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238902',

    },

    {
        title: 'AI Courses',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501',

    },
    {
        title: 'Tests & Quizzes',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238903',

    },
    {
        title: 'Career matching',
        description: '',
        // link: 'https://unschooler.me',

    },
    {
        title: 'Autotranslation 10 lang',
        description: '',
        // link: 'https://unschooler.me',

    },
    {
        title: 'Courses from own videos and pdf, docx, txt files',
        description: '',
        link: 'https://unschooler.me/tutorials/course-creation-276501?step=238905',

    },
    {
        title: 'Video uploading (mp4)',
        description: '',
        // link: 'https://unschooler.me',

    },
    {
        title: 'Subtitles generator',
        description: '',
        // link: 'https://unschooler.me',

    },
    {
        title: 'Students\' progress',
        description: '',
        link: 'https://unschooler.me/tutorials/student-progress-tra-274062',

    },
    {
        title: 'Sell courses with Stripe payments',
        description: '',
        link: 'https://unschooler.me/tutorials/selling-knowledge-s-274061',

    },
    {
        title: 'Embedding course to own platform',
        description: '',
        link: 'https://unschooler.me/tutorials/course-embedding-276492',

    },
]
export const FEATURECREDITS = [
    {
        title: 'ElevenLabs voices and Cloned voices',
        description: '',
        // link: 'https://unschooler.me/tutorials/course-creation-276501?step=238903',

    },
    {
        title: 'Voice-over for text in lessons',
        description: '',
        // link: 'https://unschooler.me',

    },
    {
        title: ' Soon: AI Videos in lessons',
        description: '',
        soon: true,
        // link: 'https://unschooler.me/tutorials/course-creation-276501?step=238906',

    },

    {
        title: ' Soon: Unlimited lessons in modules',
        description: '',
        soon: true,
        // link: 'https://unschooler.me/tutorials/course-creation-276501?step=238902',

    },

    {
        title: ' Soon: Unlimited modules in courses',
        description: '',
        soon: true,
        // link: 'https://unschooler.me/tutorials/course-creation-276501',

    },

]

export const COVERS = [
    'https://cdn.midjourney.com/d0bf3bbd-1f94-4b7b-b439-2f7645d7a1b7/grid_0.png',
    'https://cdn.midjourney.com/3410804e-5a80-4377-9261-25e719ae7d7c/grid_0.png',
    'https://cdn.midjourney.com/eecd079f-30b4-44cf-a24f-4b8cbca21daf/grid_0.png',
    'https://cdn.midjourney.com/3d65c3c5-f14e-4a63-877d-06c8941738aa/grid_0.png',
    'https://cdn.midjourney.com/204b8d7a-f5f2-4093-a7ed-64d85b264d88/grid_0.png',
    'https://cdn.midjourney.com/b8aeb9fd-71a1-4cb8-8ed1-727ee698c530/grid_0.png',
    'https://cdn.midjourney.com/216091b2-b551-415c-8f5f-1c14dc5b8612/grid_0.png',
    'https://cdn.midjourney.com/02e07c40-7de4-4fbe-9780-d27ea8cfbf70/grid_0.png',
    'https://cdn.midjourney.com/69f3c74f-d411-4940-b248-f6a34049fa1b/grid_0.png',
    'https://cdn.midjourney.com/db23c04d-0c0d-45cc-a30e-d301baca97e5/grid_0.png',
    'https://cdn.midjourney.com/ca3674a3-766f-4b31-b2b5-f2f4cffcf7b8/grid_0.png',
    'https://cdn.midjourney.com/e4c26edc-3bc0-404e-9ab7-b1798a93f903/grid_0.png',
    'https://cdn.midjourney.com/5174c533-2d35-4afb-9cf0-d09b83dd6aa6/grid_0.png',
    'https://cdn.midjourney.com/f163f30b-1e44-4bf8-9221-e5b99f567d6f/grid_0.png',
    'https://cdn.midjourney.com/ca89421a-c89f-4201-80b8-47146cde7653/grid_0.png',
    'https://cdn.midjourney.com/fe3850a7-1bea-4600-bf37-6fe99bcec7bf/grid_0.png',
    'https://cdn.midjourney.com/b2a27cc2-c82d-4ba6-a5cd-210526dcd84f/grid_0.png',
];

export const COURSE_EXAMPLE = {
    title: 'A learning plan for UX/UI designer',
    subjects: [52],
    tasks: [
        {
            title: ' UX Basics: Learn the fundamentals of user experience design, including user research, user flows, wireframing, prototyping, and usability testing.',
            isAvailable: false,
            id: 1,
            tags: [],
        },
        {
            title: ' UI Design: Understand the principles of visual design, typography, color theory, and layout to create intuitive and aesthetically pleasing user interfaces.',
            isAvailable: false,
            id: 2,
            tags: [],
        },
        {
            title: ' Interaction Design: Develop an understanding of how users interact with digital products, including motion design, micro-interactions, and user feedback.',
            isAvailable: false,
            id: 3,
            tags: [],
        },
        {
            title: ' Accessibility: Learn how to design digital products that are accessible to all users, regardless of physical or cognitive abilities.',
            isAvailable: false,
            id: 4,
            tags: [],
        },
        {
            title: ' Design Systems: Create a cohesive design language and style guide to ensure consistency across all digital products.',
            isAvailable: false,
            id: 5,
            tags: [],
        },
        {
            title: ' User Testing: Utilize user testing to validate design decisions and identify areas for improvement.',
            isAvailable: false,
            id: 6,
            tags: [],
        },
        {
            title: ' Design Tools: Become proficient in the use of design tools such as Figma, Sketch, and Adobe XD.',
            isAvailable: false,
            id: 7,
            tags: [],
        },
        {
            title: ' Project Management: Develop an understanding of project management principles and best practices for successful product delivery.',
            isAvailable: false,
            id: 8,
            tags: [],
        },
    ],
};
export const RANDOM_NUMBERS = [412, 184, 333, 44, 64, 243, 543, 12, 43, 234];
export const BASE_JITSI_URL = 'https://meet.jit.si/';
// export const PROFESSIONS = {
//     science: [
//         'Physicist',
//         'Biologist',
//         'Astrophysicist',
//         'Veterinarian',
//         'Gardener',
//         'Agronomist',
//         'Ecologist',
//     ],
//     people: [
//         'Teacher',
//         'Psychologist',
//         'Manager',
//         'Physician',
//         'Paramedics',
//         'HR',
//     ],
//     tech: ['Engineer', 'Programmer', 'System administrator', 'Mechanic'],
//     info: ['Writer', 'Copywriter', 'Translator', 'Financier', 'Analyst'],
//     art: ['Artist', 'Actor', 'Singer', 'Renovator', 'Art critic'],
//     business: [
//         'Entrepreneur',
//         'Marketer',
//         'Business Analyst',
//         'Chief Executive Officer',
//         'Chief Marketing Officer',
//         'VP',
//         'Executive',
//     ],
// };

export const SPEC_SUBJECTSID = [
    {
        color: '#4CC05E',
        title: 'science',
        person: 'ciolkovsky',
        profession: 'Scientist',
        subjectIds: [2, 6, 11, 23, 37, 62, 67, 73, 74],
        icon: ':icon/nature',
        text: 'People who are focused on cognitive activity love to observe, analyse, explore, and solve problems. This applies to all professions related to science and nature.',
    },
    {
        color: '#E4437A',
        title: 'people',
        person: 'montessori',
        profession: 'Blogger',
        subjectIds: [
            12, 13, 15, 25, 28, 29, 30, 31, 33, 36, 49, 55, 59, 63, 66, 71, 76,
        ],
        icon: ':icon/people',
        text: 'People with this passion are focused on relationship-building. They notice subtle vulnerabilities. They are sensitive, prone to altruism, do not like systematic activities, love teamwork, and have strong communication skills. This applies to all professions related to education, services, training, and  managing communication.',
    },
    {
        color: '#00AEE2',
        title: 'tech',
        person: 'engelbart',
        profession: 'Engineer',
        subjectIds: [
            1, 3, 4, 5, 10, 16, 17, 19, 20, 21, 24, 42, 47, 48, 51, 57, 60, 70,
        ],
        icon: ':icon/tech',
        text: 'People with this passion are action-oriented, decisive, mechanically capable, and love technology. This applies to all professions related to the creation, maintenance, and operation of equipment and software.',
    },
    {
        color: '#FD6247',
        title: 'info',
        person: 'vulf',
        profession: 'Writer',
        subjectIds: [7, 9, 14, 22, 26, 29, 30, 39, 40, 54, 64, 69, 70, 72],
        icon: ':icon/info',
        text: 'People with this passion are focused on the systematisation and reproduction of information, data manipulation, and non-verbal communication. This applies to all professions related to the creation and use of sign systems (digital, alphabetic, musical).',
    },
    {
        title: 'art',
        person: 'vinci',
        profession: 'Artist',
        subjectIds: [8, 27, 31, 32, 34, 38, 46, 50, 56, 58, 61, 65],
        icon: ':icon/art',
        color: '#F6C000',
        text: 'People with this passion are focused on self-expression, creativity, originality, are prone to non-conformity, have rich intuition and imagination. This applies to all professions related to creation, reproduction, and the study of artistic work.',
    },
    {
        title: 'business',
        person: 'edison',
        profession: 'Entrepreneur',
        subjectIds: [41, 18, 33, 44, 64, 75],
        icon: ':icon/business',
        color: '#a379f2',
        text: 'Entrepreneurs are curious, energetic, action-orientated, and proactive people. They are ambitious highly-social influencers, creating and implementing their ideas, managing resources, and continuously searching for solutions. They are highly observant yet impatient, and can see the big picture which enables them to lead other people.',
    },
];

export const LANDING_EVENTS_SPEC = {
    science: [292, 311, 320, 321],
    people: [282, 310, 213, 319, 312, 222, 199],
    tech: [214, 317, 318, 221, 215, 212],
    info: [218, 313, 314, 322],
    art: [200, 219, 198, 192, 216, 211, 217, 220],
    business: [208, 203, 209, 194, 222, 315],
};

export const LANDING_EVENTS_CAREER = {
    science: [],
    people: [324],
    tech: [328, 335],
    info: [326],
    art: [327],
    business: [339],
};

export const LANDING_EVENTS_LIST = [31942, 31656, 30917];

export const SCREENS = [
    {
        url: 'https://cdn.unschooler.me/landing/school1.png',
        alt: ''
    },
    {
        url: 'https://cdn.unschooler.me/landing/school2.png',
        alt: ''
    },
    {
        url: 'https://cdn.unschooler.me/landing/school3.png',
        alt: ''
    },
]

export const HOW_IT_WORKS = [
    {
        description:
            'Add your topic, text, link, video or channel to get started. Our AI will automatically make a plan and sort through all your content.',
        // src: 'https://cdn.unschooler.me/landing/features-educators/youtube.png',
        src: 'https://cdn.unschooler.me/landing/how_step-0.png',
        color: '#fd6247',
        shear: 2,
    },
    {
        description:
            "Specify learning outcomes and assessments to get personalised uniques curriculum and content.",
        src: 'https://cdn.unschooler.me/landing/course-interface.png',

        color: '#14a4d6',
        shear: 1,
    },
    {
        description:
            'Students can talk to your course, ask your videos, generate bookmarks and get feedback from AI.',
        src: 'https://cdn.unschooler.me/landing/video-talking.png',
        color: '#1ebab3',
        shear: -1,
    },
    {
        description:
            'Generate interative Quizzes. Test students skills. Collect student results and track progress.',
        src: 'https://storage.googleapis.com/cdn.unschooler.me/landing/test.png',
        color: '#4CC05E',
        shear: 0,
    },

    {
        description:
            'Reaveal insights about students skills and interests. Match them with careers.',
        src: 'https://cdn.unschooler.me/landing/skills%20with%20a%20career%20matching.png',
        color: '#fd6247',
        shear: 2,
    },
];

export const SOCIAL_TYPES = {
    discord: {
        title: 'Discord',
        icon: ':icon/discord',
        color: '#9860FF',
    },
    twitter: {
        title: 'Twitter',
        icon: ':icon/twitter',
        color: '#FF6736',
    },
    linkedin: {
        title: 'Linkedin',
        icon: ':icon/linkedin',
        color: '#FF6736',
    },
    instagram: {
        title: 'Instagram',
        icon: ':icon/instagram',
        color: '#9860FF',
    },
    youtube: {
        title: 'YouTube',
        icon: ':icon/youtube',
        color: '#a479f2',
    },
    web: {
        title: 'Web',
        icon: ':icon/web',
        color: '#9860FF',
    },
    email: {
        title: 'Email',
        email: ':icon/email',
        color: '#9860FF',
    },
};

export const SOCIAL_BOX = [
    {
        title: 'Discord',
        icon: ':icon/discord',
        color: '#FB72FC',
        name: 'unschooler',
        href: 'https://discord.gg/NpdGyzRErq',
    },
    {
        title: 'Twitter',
        icon: ':icon/twitter',
        color: '#FF6736',
        name: 'unschooler_me',
        href: 'https://twitter.com/unschooler_me',
    },
    {
        title: 'Instagram',
        icon: ':icon/instagram',
        color: '#9860FF',
        name: 'unschooler_en',
        href: 'https://www.instagram.com/unschooler_en/',
    },
];

export const LOGOS = [
    {
        // title: 'Speed',
        src: 'https://cdn.unschooler.me/landing/asu.png',
        // color: '#FB72FC',
    },
    // {
    //     // title: 'Personalisation',
    //     src: 'https://cdn.unschooler.me/landing/bath.png',
    //     // color: '#FF6736',
    // },

    {
        // title: 'Collaboration',
        src: 'https://cdn.unschooler.me/landing/superior.png',
        // color: '#9860FF',

    },
];
export const FEATURES_DESCRIPTION = [
    {
        title: 'Speed',
        src: ':icon/courses-promo',
        color: '#FB72FC',
        description:
            'Course for minutes: just add your YouTube channel or copy-paste learning objectives and AI will create the entire course for your students',
    },
    {
        title: 'Personalisation',
        src: ':icon/personalisation',
        color: '#FF6736',
        description:
            'Students can literally talk to your courses: ask unique questions, request additional information and get feedback from AI. ',
    },
    {
        title: 'Time',
        src: ':icon/time',
        color: '#9860FF',
        description:
            'We save months and cut off boring rouitine. You can focus on your students and their progress.',
    },
    {
        title: 'Collaboration',
        src: ':icon/collaboration',
        color: '#9860FF',
        description:
            'Get shared space with all materials for your collegues and students. ',
    },
    {
        title: 'Data privacy',
        src: ':icon/how-promo',
        color: '#FB72FC',
        description: "Our AI doesn't train on your data. We don't sell it or use it to show ads. Delete your data anytime.",
    },
    {
        title: 'Career matching',
        src: ':icon/career-matching',
        color: '#FF6736',
        description:
            'Soft career guide: we show insights for students based on what they know and how well they do in tests to help find the career of their dream.',
    },

];

export const MENTORS = [
    {
        // nameMentor: 'Workspace example',
        account: 'TheMatthewA',
        src: 'https://cdn.unschooler.me/landing/workspace.png',
        color: '#F4F738',
        titleMentor: 'Own course library, access public or private, embedding, onw course/test generator',
    },

];

export const ABOUT = [
    {
        nameMentor: 'Lena',
        src: 'https://cdn.unschooler.me/landing/about_lena.jpg',
        social: {
            linkedin:
                'https://www.linkedin.com/in/lena-dorogenskaya-6a21a4a8/ ',
            ph: 'https://www.producthunt.com/@lena_dorogenskaya ',
            twitter: 'https://twitter.com/l_dorogenskaya',
        },
    },
    {
        nameMentor: 'Semen',
        src: 'https://cdn.unschooler.me/about_semen.jpg',
        social: {
            linkedin: 'https://www.linkedin.com/in/semyon-golovin-7a218022a/ ',
        },
    },
    {
        nameMentor: 'Michael',
        src: 'https://cdn.unschooler.me/landing/about_michael.jpg',
        social: {
            linkedin:
                'https://www.linkedin.com/in/%D0%BC%D0%B8%D1%85%D0%B0%D0%B8%D0%BB-%D1%87%D0%B2%D0%B0%D1%80%D0%BA%D0%BE%D0%B2-44a98a77/ ',
        },
    },
    // {
    //     nameMentor: 'Yana',
    //     src: 'https://cdn.unschooler.me/landing/about_yana.jpg',

    //     social: {
    //         linkedin: 'https:/www.linkedin.com/in/yana-golubeva/',
    //         ph: 'https://www.producthunt.com/@yagolub',
    //         twitter: 'https://twitter.com/yanagolubevaa',
    //         instagram: 'https://www.instagram.com/ya.golubeva/',
    //     },
    // },
    // {
    //     nameMentor: 'Maria',
    //     src: 'https://cdn.unschooler.me/about_maria.jpg',
    //     social: {
    //         linkedin:
    //             'https://www.linkedin.com/in/maria-petukhovskaya-6638051a9/',
    //         instagram: 'https://m.facebook.com/100001822173015/',
    //     },
    // },
    {
        nameMentor: 'Alex',
        src: 'https://cdn.unschooler.me/landing/about_alex1.jpg',
        social: {
            linkedin: 'https://www.linkedin.com/in/alex-dovgalenok-86051b167/',
        },
    },
    // {
    //     nameMentor: 'Sergei',
    //     src: 'https://cdn.unschooler.me/landing/about_sergei.jpg',
    //     social: {
    //         linkedin: 'https://www.linkedin.com/in/sergey-krasakovich/',
    //         ph: 'https://www.producthunt.com/@serg_krasakovich ',
    //     },
    // },

    // {
    //     nameMentor: 'Alex',
    //     src: 'https://cdn.unschooler.me/about_alex2.jpg',
    //     social: {
    //         linkedin: 'https://www.linkedin.com/in/alexander-dovksha/ ',
    //     },
    // },
];

export const COVER_PICTURES = {
    COMMUNITIES:
        'https://cdn.unschooler.me/web_app/sharing_pictures/unschooler_default-cover.jpg',
    MAIN: 'https://cdn.unschooler.me/web_app/sharing_pictures/unschooler_default-cover.jpg',
    SKILLS: 'https://cdn.unschooler.me/web_app/sharing_pictures/cover_skills.jpeg',
    TUTORIALS: 'https://cdn.unschooler.me/web_app/sharing_pictures/tutorials.png',
    CERT: 'https://cdn.unschooler.me/web_app/sharing_pictures/tutorials.png',
};

export const PRISE = 3000;
export const DATE = '15 декабря 2020';

export const renderRole = (role) => {
    switch (role) {
        case 0:
            return translate('role_0'); // admin
        case 1:
            return translate('role_1'); // teacher
        case 2:
            return translate('role_2'); // can refund
        case 3:
            return translate('role_3'); // regular user can conduct calls
        case 4:
            return translate('role_4'); //regular user
        default:
            return translate('role_default'); // guest
    }
};


export const COINS = {
    CLICKER: 1, //deprecated
    SCROLL: 1, //deprecated
    RETENTION: 1,
    QUESTION: 1,
    INVEST: 5, //minus, it's a like
    LOGIN: 2000,
    DISCORD: 10,
    CHALLENGE: 20,
    TASK: 100,
    CLASS: 100, //minus, free enrollment
};

export const TEST_PARTICIPANTS = [
    {
        participantEmail: 'sashapramen@gmail.com',
        participantId: 'iL7hSYgO6aNjWqmaTe65yRYi3zL2',
        participantImg:
            'https://storage.googleapis.com/cdn.unschooler.me/male1085857755279.jpeg',
        participantName: 'Jerry D Carpenter',
    },
    {
        participantEmail: 'petukhouskaya.design@gmail.com',
        participantId: '6CGLV4oIdERjzgiS6z1vg2ckc8G2',
        participantImg:
            'https://storage.googleapis.com/cdn.unschooler.me/male20171086077309754.jpeg',
        participantName: 'William L Lin',
    },

    {
        participantEmail: 'keluargadff@gmail.com',
        participantId: 'peak3RipEQcpoLOgEF7yKXY4LNq1',
        participantImg:
            'https://storage.googleapis.com/cdn.unschooler.me/male20161083986856262.jpeg',
        participantName: 'James L Franco',
    },
];

export const PATH_COINS = 'coins/';
export const STORAGE_KEY_COINS = 'coins';
export const COINS_EXCHANGE = 10;

export const typeform = ` (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()`;
export const PLACEHOLDER_EMPTY_TAG = {
    icon: '⚡️',
    text: translate('theme_tutorial'),
    btnText: translate('btn_tutorial'),
};

export const PLACEHOLDER_MY = {
    icon: '🐿',
    text: translate('placeholder_my'),
    // btnText: 'Добавить объяснение',
};

export const PLACEHOLDER_FAVORITES = {
    icon: '❤️',
    text: translate('placeholder_favorites'),
};

export const PLACEHOLDER_EVENTS = {
    icon: '👩‍🚀',
    text: translate('placeholder_events'),
    btnText: 'Create online-class',
};

export const PLACEHOLDER_USER_PROFILE = {
    icon: '🕵️',
    text: translate('placeholder_user_profile'),
};

export const PLACEHOLDER_GROUP_EMPTY = {
    icon: '🌚',
    text: translate('placeholder_group_empty'),
    // btnText: "Мне интересно…",
};
export const defaultAchievements = ['role_4', 'role_3', 'role_2', 'role_1'];

export const mediaResolutions = {
    XL: 1440,
    LG: 1280,
    MD: 1001,
    SM: 640,
    DEFAULT: 640,
};

export const DEFAULT_EVENT_PICTURE = 'https://i.imgur.com/aoaZXvW.png';

export const CALENDLY_URL =
    'https://calendly.com/unschooler/30min?month=2021-12';
