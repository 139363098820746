import './scss/index.scss'
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Avatar } from 'antd';
import { useLocalePrefix } from '../../lang';
import { useTrackClick, useIsAllow, isDevelopment } from '../../common';

const HOST = isDevelopment() ? 'localhost:3000' : 'unschooler.me';
const PROTOCOL = isDevelopment() ? 'http' : 'https';

export const WorkspaceCardComponent = (props) => {
    const {
        workspace,
        link,
        ...other
    } = props;

    const trackClick = useTrackClick();
    const [localePrefix] = useLocalePrefix();
    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd('PRO');
    const {id, host, customDomain, cover, title, logo, members} = workspace || {};

    const LinkClick = !isAllow ? `${localePrefix}/payments` : `${PROTOCOL}://${host}.${HOST}${localePrefix}/`;
    return (
        <a
            href={LinkClick}
            className='event-card-component'
            onClick={(e) => {
                trackClick(
                    'click-workspace',
                    id,
                    ``
                )
                const target = e.target;

                if (customDomain && target.classList.contains('link-to-custom-domain')) {
                    e.preventDefault();
                    window.location.href = `https://${customDomain}`
                }
            }}
        >
            <div className='event-card-component__top'>
                <div className='event-card-component__header'>
                </div>
                <div className='wrapper_image_card wrapper_cover_card' >
                    <LazyLoadImage
                        className='picture_absolute'
                        src={cover}
                        alt={title}
                    />
                </div>
                <div className='workspace_info'>
                    <Avatar
                        className='counterList__liker__avatar workspace_info__avatar '
                        size={60}
                        src={logo}
                    />
                    <h2 className='event-card-component__title'>
                        {title}
                    </h2>
                    <div className='event-card-component__footer'>
                        <div className="like-link link-to-custom-domain">
                            {customDomain}
                        </div>
                        <div>
                            <p className='workspace_label'>Members: {members?.length || 0}</p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
};
