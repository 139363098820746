import '../scss/index.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Svg } from '../../common';
import { useLocalePrefix } from '../../lang';

export const FooterComponent = () => {
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();

    return (
        <footer className='footer-component '>
            <hr />
            <div className='flex-container '>
                <div className=' flex-container flex-left-column'>
                    <div className='footer__first-column'>
                        <Link
                            to={`${localePrefix}/`}
                            rel='noreferrer'
                            className='block-el wrapper_inner-section '
                        >
                            <Svg
                                name=':icon/logo-full_star'
                                viewBox='0 0 159 30'
                                width='131px'
                                height='24px'
                                className='logo'
                            />
                        </Link>

                        <div className='flex-container flex-container_between '>
                            <div className=' margin_right'>
                                <p className=' footer__link hide-on-print'>
                                    {' '}
                                    © Unschooler OÜ
                                </p>
                                <p className=' footer__link  hide-on-print'>
                                    Ahtri 12, 10151,{' '}
                                </p>
                                <p className=' footer__link  hide-on-print'>
                                    Tallinn, Estonia
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='footer__second-column'>
                        <div className='socials'>
                            <a
                                className='socials__link spec_btn btn-link_light'
                                href='https://discord.gg/NpdGyzRErq'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Svg
                                    name=':icon/discord'
                                    className='menu-icon'
                                />
                            </a>

                            <a
                                className='socials__link spec_btn btn-link_light'
                                href='https://www.youtube.com/channel/UCSk_Z7bD3QoscHfDFHXccoQ'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Svg
                                    name=':icon/youtube'
                                    className='menu-icon '
                                />
                            </a>
                        </div>

                        <div className=''>
                            <a
                                className='icon_with-icon footer__link'
                                href='mailto:lena@unschooler.me'
                                target='_blank'
                                rel='noreferrer'
                            >
                                info@unschooler.me
                            </a>
                        </div>
                        <Link
                            to={`${localePrefix}/online-courses/unschooler-guides-30917`}
                            rel='noreferrer'
                            className='block-el  wrapper_inner-section footer__link'
                        >
                            {formatMessage({ id: 'guides' })}
                        </Link>
                    </div>
                    <div className='footer__third-column'>

                        <Link
                            to={`${localePrefix}/terms/`}
                            rel='noreferrer'
                            className='block-el  wrapper_inner-section footer__link'
                        >
                            {formatMessage({ id: 'terms' })}
                        </Link>
                        <Link
                            to={`${localePrefix}/terms-mentor/`}
                            rel='noreferrer'
                            className='block-el wrapper_inner-section footer__link'
                        >
                            {formatMessage({ id: 'terms_mentors' })}
                        </Link>

                        <Link
                            className='block-el wrapper_inner-section footer__link'
                            to={`${localePrefix}/policy/`}
                        >
                            {formatMessage({ id: 'policy' })}
                        </Link>
                        <Link
                            className='block-el wrapper_inner-section footer__link'
                            to={`${localePrefix}/affiliate/`}
                        >
                            {formatMessage({ id: 'affiliate' })}
                        </Link>

                        {/* <Link
                                to={`${localePrefix}/terms-mentor/`}
                                rel='noreferrer'
                                className='block-el  wrapper_inner-section footer__link'
                            >
                                Terms for mentors
                            </Link> */}
                        <Link
                            to={`${localePrefix}/tutorials/344`}
                            rel='noreferrer'
                            className='block-el  wrapper_inner-section footer__link'
                        >
                            {formatMessage({ id: 'policy_content' })}
                        </Link>

                        {/* <span>
                            <img
                                src={
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/OpenAI_Logo.svg/800px-OpenAI_Logo.svg.png?20210328085757"
                                }
                                className="api-button-component__image margin_right"
                                alt="Developed with Open AI"
                                style={{ maxHeight: 20, marginRight: 16 }}
                            />
                            <img
                                src={
                                    "https://cdn.unschooler.me/web_app/tech_pictures/11.png"
                                }
                                className="api-button-component__image margin_right"
                                alt="Developed with Open AI"
                                style={{ maxHeight: 16, verticalAlign: "text-top" }}
                            />
                        </span> */}


                    </div>

                </div>
                <div className='flex-right-column'>
                    <Link
                        to={`${localePrefix}/educators/`}
                        rel='noreferrer'
                        className='block-el  wrapper_inner-section footer__link'
                    >
                        {formatMessage({ id: 'footer_educators' })}
                    </Link>
                    <Link
                        to={`${localePrefix}/skills/`}
                        rel='noreferrer'
                        className='block-el  wrapper_inner-section footer__link'
                    >
                        {formatMessage({ id: 'footer_skill_map' })}
                    </Link>

                    <Link
                        className='block-el wrapper_inner-section footer__link'
                        to={`${localePrefix}/careers/`}
                    >
                        {formatMessage({ id: 'footer_career_explorer' })}
                    </Link>
                    <Link
                        className='block-el wrapper_inner-section footer__link'
                        to={`${localePrefix}/online-courses/`}
                    >
                        {formatMessage({ id: 'footer_courses' })}
                    </Link>

                    <Link
                        to={`${localePrefix}/payments/`}
                        rel='noreferrer'
                        className='block-el  wrapper_inner-section footer__link'
                    >
                        {formatMessage({ id: 'footer_pricing' })}
                    </Link>
                </div>
            </div>
        </footer>
    );
};
