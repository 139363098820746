import './scss/index.scss';

import React from 'react';
import { useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { EventsListComponent } from '../../events/EventsList.component';

export const SearchComponent = (props) => {
    const { onSearch, onCancel, loading = false, isSearch = false, list } = props;
    const { formatMessage } = useIntl();

    return (
        <div className="search-component">
            <div className="search-component__form">
                <Form onFinish={onSearch}
                    onBlur={(val) => {
                        if (!val.target.value) {
                            onCancel();
                        }
                    }}>
                    <Form.Item name="search">
                        <Input
                            placeholder={formatMessage({ id: 'search_placeholder' })}
                            disabled={loading}
                        />
                    </Form.Item>
                </Form>
            </div>

            {isSearch && (
                <h2>
                    {`${formatMessage({ id: 'search_result_title' })} "${isSearch}"`}
                </h2>
            )}
            {isSearch && !list?.length && (
                <>
                    <h3>
                        {formatMessage({ id: 'search_result_not_found' })}
                    </h3>
                    <hr />
                </>
            )}
            {!!list?.length && (
                <EventsListComponent events={list} />
            )}
        </div>
    )
}