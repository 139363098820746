import React, { useEffect, useState } from 'react';

import { EventsListComponent } from '../../events';
import useGetEventSimple from '../../event/hooks/useGetEventSimple';
import useGetEventStudents from '../../event/hooks/useGetEventStudents';
import { EventsListActionsContainer } from '../EventsListActions.container';
import { noop } from 'lodash';

export const SimpleEventsListContainer = (props) => {
    const {
        as: Component = EventsListComponent,
        eventsList,
        index,
        workspaceId,
        eventsLists,
        typeProp,
        workspace,
        setAllEvents = noop,
        createCourses,
        ...other
    } = props;

    const { getEventSimple, loading } = useGetEventSimple();
    const { getEventStudents, loading: loadingRes } = useGetEventStudents();
    const [events, setEvents] = useState();
    const [type, setType] = useState(typeProp);

    const actionsComponent = createCourses ? () => (
        <EventsListActionsContainer
            index={index}
            workspaceId={workspaceId}
            eventsLists={eventsLists}
            type={type}
            setType={setType}
            {...other}
        />) : null;

    useEffect(() => {
        (async () => {
            if (eventsList) {
                const events = [];

                for (const eventId of eventsList) {
                    if (!!createCourses) {
                        const dbEvent = (
                            await getEventStudents({
                                variables: {
                                    id: eventId,
                                },
                            })
                        ).data?.event;
                        events.push(dbEvent);

                    } else {
                        const dbEvent = (
                            await getEventSimple({
                                variables: {
                                    id: eventId,
                                },
                            })
                        ).data?.event;
                        events.push(dbEvent);

                    }
                }
                setAllEvents((prev) => [...prev, ...events]);
                if (!!events.length) {
                    setEvents(events);
                }
            }
        })();
    }, [eventsList, getEventSimple]);

    // if (!events) {
    //     return null;
    // }

    return (
        <Component {...other}
            events={events}
            className='events-list-component'
            asActions={actionsComponent}
            type={type}
            isLoading={loadingRes || loading}
            isAllow={createCourses}
        />
    );
};
