import React from 'react';
import { TaskProgressComponent } from './'

export const TaskProgressContainer = ({ event, task, result, formatMessage }) => {
    // const backgroundColor = event?.subjects?.[0]?.color + 30 || task?.tags?.[0]?.subject?.color + 30 || '';
    // const progressColor = event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color || '';
    // const backgroundColor = event?.subjects?.[0]?.color + 30 || task?.tags?.[0]?.subject?.color + 30 || '';
    // const progressColor = event?.subjects?.[0]?.color || task?.tags?.[0]?.subject?.color || '';
    const progressPercentage = result?.answers?.length ? (result.answers.length / task?.questions?.length * 100) : 0;
    const completedCount = result?.answers?.length || 0;
    const allCount = task?.questions?.length || 0;

    return (
        <TaskProgressComponent
            // backgroundColor={backgroundColor}
            // progressColor={progressColor}
            progressPercentage={progressPercentage}
            formatMessage={formatMessage}
            completedCount={completedCount}
            allCount={allCount}
        />
    );
};
