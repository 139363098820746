import './scss/index.scss';
import '../../common/VideoIframe/scss/mermaid.scss';

import React, {useMemo} from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMermaidPlugin from 'remark-mermaid-plugin';

export const ReactMarkdownComponent = (props) => {
    const { children, onMouseUp, className, style, linkTarget = "_blank", } = props;

    const content = useMemo(() => {
        if (!children) {
            return children;
        }
        const mermaidRegex = /mermaid\s([\s\S]*?)\smermaid end/g;
        return children?.replace(mermaidRegex, (match, p1) => {
            const diagramCode = p1?.trim().replace(/\\/g, "");
            if (diagramCode) {
                return '\n```mermaid\n' + diagramCode + '\n```\n';
            }
            return match;
        });
    }, [children]);

    return (
        <ReactMarkdown
            children={content}
            linkTarget={linkTarget}
            escapeHtml={false}
            remarkPlugins={[[remarkMermaidPlugin, { theme: 'forest' }], remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            onMouseUp={onMouseUp}
            className={className}
            style={style}
        />
    );
};
