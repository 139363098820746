import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Tabs } from 'antd';
import { useLocalePrefix } from '../../lang';
import { UploadImgComponent, useIsAllow } from '../../common';
import { TitleComponent } from "../../common/ProfileTitle.component/Title.component";
import { StripeConnectedAccountContainer } from '../../stripe';
import { EditCustomVoiceContainer } from '../../voices';

const { TabPane } = Tabs;

export const EditUserComponent = (props) => {
    const {
        user,
        onEdit,
        isWhiteLabeled,
        isCreator,
    } = props;

    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();
    const { useForm } = Form;
    const [form] = useForm();
    const { setFieldsValue } = form;
    const userIsAllowd = useIsAllow();
    const isAllowCreator = userIsAllowd("PREMIUM");

    const { name, picture } = user || {};
    const [imagesList, setImagesList] = useState([{ url: picture }]);

    const handleSetImageList = (data) => {
        if (data) {
            const { response } = data?.[0] || {};
            if (response) {
                setFieldsValue({ picture: response.url });
            }
        } else {
            setFieldsValue({ picture: '' });
        }
        setImagesList(data);
    };

    const handleRemove = () => {
        setImagesList(null);
        setFieldsValue({ picture: '' });
    };

    useEffect(() => {
        setImagesList([{ url: picture }]);
    }, [picture]);

    return (
        <div className="edit-user-component">
            <TitleComponent title={formatMessage({ id: 'edit_user_label' })} back={true} />
            <Link
                className='inline-el link-btn'
                to={`${localePrefix}/logout`}>
                {formatMessage({ id: 'nav_exit' })}
            </Link>
            <Tabs defaultActiveKey="1">
                <TabPane tab={formatMessage({ id: 'Change_title' })} key="1">
                    <Form name='edit-user'
                        initialValues={{ name, picture }}
                        layout='vertical'
                        form={form}
                        onFinish={onEdit}>
                        <Form.Item name="picture"
                            label={formatMessage({ id: 'user_picture_label' })}
                            rules={[{ required: true }]}>
                            <UploadImgComponent savedImages={imagesList}
                                limitImages={1}
                                onSetImages={handleSetImageList}
                                onRemove={handleRemove} />
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'user_name_label' })}
                            name='name'
                            rules={[{ required: true }]}
                            width={400}>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type='tertiary' htmlType='submit'>
                                {formatMessage({ id: 'form_button_save' })}
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='profile-block__data'>
                        {!isWhiteLabeled &&
                            <p>{formatMessage({ id: 'delete_account_info' })}</p>
                        }
                    </div>
                </TabPane>
                {(isCreator || !isWhiteLabeled) &&
                    <TabPane tab={formatMessage({ id: 'stripe_connect_label' })} key="2">
                        {isAllowCreator ? (
                            <StripeConnectedAccountContainer />
                        ) : (
                            <Link className='link-btn_primary' to={'/payments'}>
                                <span> Creator +, {formatMessage({ id: 'payment_button' })}
                                </span>
                            </Link>
                        )}
                    </TabPane>
                }
                {(isCreator || !isWhiteLabeled) &&
                    <TabPane tab={formatMessage({ id: 'custom_voice_title' })} key="3">
                        <EditCustomVoiceContainer />

                        <Link className='link-btn_primary' to={'/tutorials/additional-pricing-326956'}>
                            <span> Purchase AI-credits to clone voice</span>
                        </Link>
                    </TabPane>
                }
              
            </Tabs>

        </div>
    );
};