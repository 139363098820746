import "./scss/index.scss";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Form, Input, Select, Switch, Radio } from 'antd';
import { cloneDeep } from "lodash";
import { DeleteFilled } from '@ant-design/icons';

import { WysiwygComponent, getType } from '../../common';
import { QuestionExplanationComponent } from '../QuestionExplanation.component';
import { QuestionOption } from './QuestionOption';
const { TextArea } = Input;


export const EditTaskQuestion = ({
    user,
    getTabContentClassNames,
    onRemoveQuestion,
    questionsErrors,
    setSortMode,
    formTask,
    activeTask,
    form,
    task,
    onChange,
    onChangeContent,
    activeQuestion,
    remove,
    ...other
}) => {
    const { formatMessage } = useIntl();
    const TYPES_QUESTION = [
        {
            label: formatMessage({ id: 'no_interaction' }),
            value: 'DEFAULT',
        },
        {
            label: formatMessage({ id: 'multi_select' }),
            value: 'MULTI_SELECT',
        },
        {
            label: formatMessage({ id: 'single_select' }),
            value: 'SINGLE_SELECT',
        },
        {
            label: formatMessage({ id: 'ai_assignment' }),
            value: 'TEXT',
        },
    ];
    const getInitialValue = (name, index, value) => formTask?.[name]?.[index] ? {} : { initialValue: value };
    const getContent = (key, field) => formTask?.questions?.[key]?.[field] || '';
    let index = formTask?.questions?.findIndex((q) => q?.id === activeQuestion?.id);
    let coverType = 'YOUTUBE';

    return (
        <Form.List name='questions'>
            {(questions, { add, remove }) => {
                return (
                    <div className='questions'>
                        {questions.map((question, i) => (
                            i === index &&
                            <div key={question.key} className='question'>

                                <div className='question__bottom'>
                                    {/* <VideoFormItem
                                        formTask={formTask}
                                        coverType={coverType}
                                        nameVideo={[question.name, 'video',]}
                                        {...other}
                                    /> */}

                                    <div className='question__top'>
                                        <Form.Item
                                            name={[question.name, 'title',]}
                                            label={formatMessage({ id: 'form_step_prompt' })}
                                            className='edit-task-component__question-type'
                                        >
                                            <TextArea
                                                autoComplete='off'
                                                placeholder={formatMessage({ id: 'form_title_prompt' })}
                                            />
                                        </Form.Item>
                                    </div>



                                    <Form.Item
                                        name={[question.name, 'question',]}
                                        // label={formatMessage({id: 'form_question_label',})}
                                        rules={[
                                            {
                                                required: false,
                                                message: formatMessage({ id: 'form_field_is_required', }),
                                            },
                                        ]}
                                    >
                                        <WysiwygComponent
                                            onChangeContent={(content) => {
                                                const questions = [...form.getFieldValue('questions')];
                                                let questionNew = cloneDeep(questions[i]);
                                                if (questions[i]) { questionNew.question = content; }
                                                questions[i] = questionNew;
                                                form.setFieldsValue({ questions: questions });
                                                onChangeContent();
                                            }}
                                            initialState={getContent(question.key, 'question')}
                                            placeholder={formatMessage({ id: 'form_question_label', })}
                                        />
                                        <hr />
                                    </Form.Item>

                                    <Form.Item
                                        name={[question.name, 'type',]}
                                        {...getInitialValue('questions', question.name, 'SINGLE_SELECT')}
                                        className='edit-task-component__question-type'
                                    >
                                        <Radio.Group
                                            buttonStyle="solid"
                                            className='radio_title'
                                            onChange={onChange}
                                        >
                                            {TYPES_QUESTION.map(type => (
                                                <Radio.Button type="tertiary" value={type.value} key={type.value} >
                                                    <span className="feature">
                                                        {getType(type?.value)}
                                                        {type.label}
                                                    </span>

                                                </Radio.Button>

                                            ))}
                                        </Radio.Group>
                                    </Form.Item>

                                    {/* <AddNewContentContainer
                                        {...other}
                                        task={task}
                                        question={question}
                                        user={user}
                                        notAutoGenerate={true}
                                        // inProgressNextStep,
                                        // setInProgressNextStep,
                                    /> */}

                                    {formTask?.questions && formTask.questions[i] && formTask.questions[i].type === 'TEXT' && (
                                        <Form.Item
                                            name={[question.name, 'answer',]}
                                        // label={formatMessage({ id: 'form_text_challenge' })}
                                        >
                                            <WysiwygComponent
                                                onChangeContent={(content) => {
                                                    const questions = [...form.getFieldValue('questions')];
                                                    let questionNew = cloneDeep(questions[i]);
                                                    if (questions[i]) { questionNew.answer = content; }
                                                    questions[i] = questionNew;
                                                    form.setFieldsValue({ questions: questions });
                                                    onChangeContent();
                                                }}
                                                initialState={getContent(question.key, 'answer')}
                                                placeholder={formatMessage(
                                                    { id: 'form_text_question_answer_placeholder', }
                                                )}
                                            />

                                        </Form.Item>
                                    )}
                                    {formTask?.questions && formTask.questions[i] &&
                                        (formTask.questions[i].type === 'SINGLE_SELECT' || formTask.questions[i].type === 'MULTI_SELECT') && (
                                            <>
                                                <Form.List
                                                    name={[question.name, 'options',]}
                                                >
                                                    {(options, { add, remove, }) => (
                                                        <div className='options'>
                                                            {options.map(
                                                                (option, i) => (
                                                                    <QuestionOption
                                                                        option={option}
                                                                        remove={remove}
                                                                        key={`option_${i}`}
                                                                        onChangeContent={onChangeContent}
                                                                    />
                                                                )
                                                            )}

                                                            {!!questionsErrors?.[i] && (
                                                                <div className='ant-form-item-explain'>
                                                                    <div
                                                                        className='ant-form-item-explain-error'
                                                                        role='alert'
                                                                    >
                                                                        {questionsErrors?.[i]}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <Button
                                                                onClick={() => {
                                                                    add({
                                                                        isCorrect: 'checked',
                                                                        text: 'Next',
                                                                    })
                                                                    onChangeContent();
                                                                }}
                                                                type="text"
                                                                size="small"
                                                                className="link-btn_light"
                                                            >
                                                                + {formatMessage({ id: 'form_add_new_answer', })}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                                <QuestionExplanationComponent
                                                    Form={Form}
                                                    form={form}
                                                    question={question}
                                                    task={activeTask}
                                                    onChangeExplanation={form.setFieldsValue}
                                                />
                                            </>
                                        )}
                                    <hr />
                                </div>

                                <div className='question__header'>
                                    <Form.Item >
                                        <Button
                                            className='question__remove btn-left link-btn_light'
                                            icon={<DeleteFilled />}
                                            size='small'
                                            type='text'
                                            onClick={() => {
                                                remove(
                                                    question.name
                                                );
                                                onRemoveQuestion();
                                            }}
                                        >
                                            {formatMessage({ id: 'form_remove_question' })}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        )
                        )}
                        {/* 
                        <hr />
                        <Form.Item>
                            <Button
                                onClick={() => add()}
                                block
                                type="tertiary"
                            >
                                <span>
                                    <PlusOutlined />
                                    {formatMessage({ id: 'form_add_question',})}
                                </span>
                            </Button>
                        </Form.Item> */}

                        {/* {formTask?.questions?.length > 1 && (
                            <div className='edit-task-sort-button'>
                                <Button
                                    type='link'
                                    onClick={() => setSortMode(true)}
                                >
                                    {formatMessage({ id: 'form_text_step_order' })}
                                </Button>
                            </div>
                        )} */}
                    </div>
                )
            }
            }
        </Form.List>
    );
};
